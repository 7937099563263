import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  Output,
  Renderer2,
  SimpleChanges
} from '@angular/core';
import { fromEvent, Subscription } from 'rxjs';

import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import * as _ from 'lodash';

// web worker
import { InlineWorker } from 'src/app/web-workers/inline-worker';

const seeMoreElement = '<span style="font-weight: 500; cursor: pointer"> See more</span>';

interface WorkerFunctionArguments {
  multilineTextWithEllipsisContainerHeight: number;
  textContainerHeight: number;
  textContent: string;
  addDots?: boolean;
  showSeeMore?: boolean;
  alwaysShowSeeMore?: boolean;
  originalTextEndIndex?: number;
  seeMoreElement?: string;
}

interface WorkerFunctionResult {
  textContent: string,
  overflow: boolean,
  originalTextEndIndex: number
}

@Component({
  selector: 'app-multiline-text-with-ellipsis',
  templateUrl: './multiline-text-with-ellipsis.component.html',
  styleUrls: ['./multiline-text-with-ellipsis.component.scss'],
})
export class MultilineTextWithEllipsisComponent implements AfterViewInit, OnChanges, OnDestroy {

  @Input() text: string;
  @Input() showSeeMore = false;
  @Input() alwaysShowSeeMore = false;
  @Input() hideTextWhileWorking: boolean;
  @Input() customShowSeeMore: boolean;
  @Input() showCopyIcon = false;

  @Output() seeMoreClicked: EventEmitter<void> = new EventEmitter();

  hideText: boolean;
  originalTextEndIndex: number;

  private multilineTextWithEllipsisContainer: HTMLElement;
  private multilineTextContainer: HTMLElement;
  private textContainerEl: HTMLElement;
  private resizeSubscription: Subscription;
  private textResizeObserver: any;
  private inlineWorker: any;

  constructor(
    private el: ElementRef,
    private zone: NgZone,
    private renderer: Renderer2
  ) { }

  ngAfterViewInit(): void {

    this.multilineTextWithEllipsisContainer = this.el.nativeElement.querySelector('.multiline-text-with-ellipsis-container');
    this.multilineTextContainer = this.el.nativeElement.querySelector('.multiline-text-container');
    this.textContainerEl = this.el.nativeElement.querySelector('.text');

    this.setResizeObservable();
    this.setTextContainerElementResizeObserver();
  }

  ngOnDestroy(): void {
    this.terminateAndClearWorker();
    this.terminateResizeObserver();
    this.resizeSubscription?.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.text && changes?.text?.previousValue !== changes?.text?.currentValue && !changes?.text?.isFirstChange()) {
      this.resetText(this.text);
    }

    if (this.alwaysShowSeeMore &&
      changes?.text &&
      changes?.text?.previousValue !== changes?.text?.currentValue &&
      changes?.text?.currentValue?.length) {
      this.originalTextEndIndex = changes?.text?.currentValue.length;
      if (this.textContainerEl) {
        this.resetText(changes?.text?.currentValue + '...' + seeMoreElement);
      } else {
        this.text = changes?.text?.currentValue + '...' + seeMoreElement;
      }
    }
  }

  resetText(text: string): void {
    this.terminateAndClearWorker();
    this.setTextContainerElementResizeObserver();
    this.textContainerEl.textContent = text;
  }

  setResizeObservable(): void {
    this.resizeSubscription = fromEvent(window, 'resize')
      .pipe(
        debounceTime(300),
        distinctUntilChanged()
      )
      .subscribe(() => {
        this.resetText(this.text);
      });
  }

  setTextContainerElementResizeObserver(): void {

    if (!this.textResizeObserver) {
      this.textResizeObserver = new ResizeObserver(() => {
        this.zone.run(() => {
          if (this.textContainerEl?.clientHeight > 0) {
            this.truncateText();
          }
        });
      });
    }

    this.textResizeObserver?.observe(this.textContainerEl);
  }

  truncateText(): void {

    if (this.hideTextWhileWorking) {
      this.hideText = true;
    }

    this.terminateAndClearWorker();

    this.inlineWorker = new InlineWorker(() => {
      // START OF WORKER THREAD CODE

      const truncateTextIfOverflow = (multilineTextContainerHeight: number,
        simpleTextContainerHeight: number, text: string, addDots: boolean, showSeeMore: boolean,
        alwaysShowSeeMore: boolean, originalTextEndIndex: number, seeMoreEl: string) => {

        let overflow: boolean;

        if (
          multilineTextContainerHeight > 0 &&
          simpleTextContainerHeight > 0 &&
          simpleTextContainerHeight > multilineTextContainerHeight) {

          if (alwaysShowSeeMore && originalTextEndIndex > 2) {

            text = text?.slice(0, originalTextEndIndex - 3) + text?.slice(originalTextEndIndex);
            originalTextEndIndex = originalTextEndIndex -= 3;
            overflow = true;
          } else if (alwaysShowSeeMore && originalTextEndIndex <= 2) {
            overflow = false;
          } else {
            text = text?.substring(0, text?.length - 3);
            overflow = true;
          }

        } else if (text && text?.length && addDots) {

          if (!alwaysShowSeeMore) {
            const cuttingCharsLength = showSeeMore ? 15 : 3;
            text = text?.substring(0, text?.length - cuttingCharsLength);
            text += '...';
            if (showSeeMore) {
              text += seeMoreEl;
            }
          }

          overflow = false;
        } else {
          overflow = false;
        }

        const result: WorkerFunctionResult = {
          textContent: text,
          overflow: overflow,
          originalTextEndIndex: originalTextEndIndex
        };

        // this is from DedicatedWorkerGlobalScope ( because of that we have postMessage and onmessage methods )
        // and it can't see methods of this class
        /* eslint-disable */
        // @ts-ignore
        this.postMessage(result);
        /* eslint-enable */
      };

      /* eslint-disable */
      // @ts-ignore
      this.onmessage = (evt: any) => {

        truncateTextIfOverflow(
          evt.data.multilineTextWithEllipsisContainerHeight,
          evt.data.textContainerHeight,
          evt.data.textContent,
          evt.data.addDots,
          evt.data.showSeeMore,
          evt.data.alwaysShowSeeMore,
          evt.data.originalTextEndIndex,
          evt.data.seeMoreElement
        );
      };
      /* eslint-enable */
      // END OF WORKER THREAD CODE
    });

    let multilineTextWithEllipsisContainerHeight = this._getElementClientHeight(this.multilineTextWithEllipsisContainer);
    let textContainerHeight = this._getElementClientHeight(this.textContainerEl);
    const textContent = this.textContainerEl?.textContent;

    const workerFunctionArguments: WorkerFunctionArguments = {
      multilineTextWithEllipsisContainerHeight: multilineTextWithEllipsisContainerHeight,
      textContainerHeight: textContainerHeight,
      textContent: textContent,
      addDots: false,
      showSeeMore: this.showSeeMore,
      alwaysShowSeeMore: this.alwaysShowSeeMore,
      originalTextEndIndex: this.originalTextEndIndex,
      seeMoreElement: seeMoreElement
    };

    this.inlineWorker.postMessage(workerFunctionArguments);

    this.inlineWorker.onmessage().subscribe((data: any) => {

      if (data?.data?.overflow) {

        this.textResizeObserver?.disconnect();

        this.textContainerEl.innerHTML = data?.data.textContent;

        multilineTextWithEllipsisContainerHeight = this._getElementClientHeight(this.multilineTextWithEllipsisContainer);
        textContainerHeight = this._getElementClientHeight(this.textContainerEl);

        this.inlineWorker.postMessage({
          multilineTextWithEllipsisContainerHeight: multilineTextWithEllipsisContainerHeight,
          textContainerHeight: textContainerHeight,
          textContent: data?.data.textContent,
          addDots: true,
          showSeeMore: this.showSeeMore,
          alwaysShowSeeMore: this.alwaysShowSeeMore,
          originalTextEndIndex: data?.data?.originalTextEndIndex,
          seeMoreElement: seeMoreElement
        });
      } else {
        this.terminateAndClearWorker();

        if (this.alwaysShowSeeMore || this.showSeeMore) { // prevents see more span to be deleted
          this.terminateResizeObserver();

          setTimeout(() => {
            this.setResizeObservable();
          }, 500);
        }

        this.textContainerEl.innerHTML = data?.data.textContent;
        this.hideText = false;
      }
    });

    this.inlineWorker.onerror().subscribe((data) => {
      console.log(data);
      this.hideText = false;
    });
  }

  _getElementClientHeight(element: HTMLElement): number {
    return element?.clientHeight ? element?.clientHeight : 0;
  }

  displayWholeText(): void {

    if (!this.showSeeMore) {
      return;
    }

    if (this.customShowSeeMore) {
      this.seeMoreClicked.emit();
      return;
    }

    this.terminateAndClearWorker();
    this.textContainerEl.textContent = this.text;
    this.renderer.setStyle(this.multilineTextContainer, 'max-height', '100%');
    this.renderer.setStyle(this.multilineTextWithEllipsisContainer, 'max-height', '100%');
  }

  get shouldHideText(): boolean {
    return this.hideTextWhileWorking &&
      ((_.isUndefined(this.hideText) || _.isNull(this.hideText)) || this.hideText) ? true : false;
  }

  private terminateAndClearWorker(): void {
    if (this.inlineWorker) {
      this.inlineWorker?.terminate();
      this.inlineWorker = null;
    }
  }

  private terminateResizeObserver(): void {
    this.textResizeObserver?.unobserve(this.textContainerEl);
    this.textResizeObserver?.disconnect();
  }
}
