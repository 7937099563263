import { Component, Input, Inject, EventEmitter, Output } from '@angular/core';
import { AOEvent } from '../../services/yeti-protocol/event';
import { Countries } from 'src/app/services/yeti-protocol/countries';
import * as moment from 'moment';
import { CONTEXT_SERVICE, ContextService } from 'src/app/services/context/context.model';
import { NavControllerService } from 'src/app/services/nav-controller.service';
import { UIUtilsServiceInterface, UI_UTILS_SERVICE } from 'src/app/services/utils/ui-utils.service.interface';
import { VerificationStatus } from '../../services/verification.model';
import { VerificationService } from '../../services/verification.service';
import { ActionSource } from 'src/app/services/yeti-protocol/tracking';
import { AppNavController } from 'src/app/services/app-nav-controller.service';

@Component({
  selector: 'app-event-card',
  templateUrl: './event-card.component.html',
  styleUrls: ['./event-card.component.scss'],
})
export class EventCardComponent {

  @Input() event: AOEvent;
  @Input() countries: Countries;
  @Input() displayCheckbox: false;
  @Input() selected = false;
  @Input() displayActions = true;
  @Input() disableItem = false;
  @Input() source: ActionSource;

  @Output() eventsSelectionChanged: EventEmitter<boolean> = new EventEmitter();
  @Output() openEvent: EventEmitter<string> = new EventEmitter();

  constructor(
    private navController: NavControllerService,
    @Inject(CONTEXT_SERVICE) private contextService: ContextService,
    @Inject(UI_UTILS_SERVICE) private uiUtilsService: UIUtilsServiceInterface,
    private verificationService: VerificationService,
    private appNavController: AppNavController
  ) { }

  get duration(): string {
    if (this.event) {
      return this.event.endDate !== 'TBA' || null || '' ? `${moment(this.event.startDate).format('MMM D')} -
      ${moment(this.event.endDate).format('MMM D, YYYY')}`
        : `${moment(this.event.startDate).format('MMM D')}`;
    }

    return '';
  }

  get location(): string {
    return this.uiUtilsService.getEventLocation(this.event, this.countries);
  }

  async openEventDetailsPage(): Promise<void> {
    if (!this.event?._id) {
      return;
    }

    this.openEvent.emit(this.event?._id);

    const userVerificationStatus = await this.verificationService.verify();
    if (userVerificationStatus === VerificationStatus.VERIFIED) {
      this.appNavController.openEvent(this.event?._id, this.source);
    }
  }

  get eventType(): string {
    return (this.event?.eventType || '').toLocaleLowerCase();
  }

  checkBoxClick(): void {
    this.selected = !this.selected;
    this.eventsSelectionChanged.emit(this.selected);
  }

}
