import { Component, HostBinding, Inject, Input } from '@angular/core';
import { MessageCardBase } from '../message-card-base';

// models
import { Message } from 'src/app/services/yeti-protocol/message';

// services
import { ResponsiveUtilsService } from 'src/app/services/utils/responsive-utils.service';
import { CONTEXT_SERVICE, ContextService } from 'src/app/services/context/context.model';
import { NavControllerService } from 'src/app/services/nav-controller.service';

@Component({
  selector: 'app-message-card-case',
  templateUrl: './message-card-case.component.html',
  styleUrls: ['./message-card-case.component.scss'],
})
export class MessageCardCaseComponent extends MessageCardBase {
  @Input() message: Message;
  @Input() userId: string;

  constructor(
    respomsoveUtilsService: ResponsiveUtilsService,
    @Inject(CONTEXT_SERVICE) private contextService: ContextService,
    private navController: NavControllerService
  ) {
    super(respomsoveUtilsService);
  }

  @HostBinding('class') get viewMode(): string {
    if(this.isOwnMessage){
      return 'chat-initiator';
    }
    return '';
  }

  onOpenCase(caseId: string): void {
    const context = this.contextService.currentContext.key;
    this.navController.navigateForward(`/${context}/case/${caseId}?source=secureChat`);
  }
}
