export type TrackingRequestParams = Record<string, string | number | Array<string> | ArticleTrackingParam | GenericTrackingParam
  | EventOriginTrackingParam | ExternalLinkTrackingParam | UserPublicProfileTrackingParam>;

// use this to be extended/inherited on all classes that need to send these params to API drywall service requests
export interface SourceCampaignForTracking {
  source?: ActionSource | string; // every object should track source/marketingCampaign if available
  marketingCampaign?: string;
}
export interface BaseTrackingParam extends SourceCampaignForTracking {
  platform?: string,
  isIonic?: boolean | true
}

export interface ArticleTrackingParam extends BaseTrackingParam {
  articleId?: string,
  articleTitle?: string,
  channelId?: string,
  url?: string,
  type?: string,
  sourceCampaign?: SourceCampaignForTracking,
  recommendedByUserId?: string
}

export interface ArticleShareTrackingParam extends BaseTrackingParam {
  articleId?: string,
  articleTitle?: string,
  sharingTarget?: string
}

export interface EventOriginTrackingParam extends BaseTrackingParam {
  eventId?: string,
  eventTitle?: string,
  myaoEventId?: string,
  url?: string,
  eventType?: string,
  sourceCampaign?: SourceCampaignForTracking
}

export interface ExternalLinkTrackingParam extends BaseTrackingParam {
  url?: string
}

export interface GenericTrackingParam extends BaseTrackingParam {
  objectId?: string,
  objectType?: string,
  objectTitle?: string
}

export interface TranslationTrackingParam extends GenericTrackingParam {
  toLanguage?: string,
  action?: string
}

export interface GroupPageTrackingParam extends BaseTrackingParam {
  groupId?: string,
  recommendedByUserId?: string
}

export interface GenericShareTrackingParam extends GenericTrackingParam {
  sharingTarget?: string
}

export interface GroupShareTrackingParam extends BaseTrackingParam {
  groupId?: string,
  groupTitle?: string,
  sharingTarget?: string
}

export interface UserPublicProfileTrackingParam extends BaseTrackingParam {
  userId?: string,
}

export interface SignInSignUpTrackingParam extends BaseTrackingParam {
  provider?: string,
  username?: string,
  sourceCampaign?: SourceCampaignForTracking
}

export interface OrcIdTrackingParam extends BaseTrackingParam {
  id?: string,
  title: string,
  ownerId: string,
  source: string,
}

export interface TrackingRequest extends TrackingRequestParams {
  action: string,
  anonymId?: string,
  appid?: string,
  params?: ArticleTrackingParam | SignInSignUpTrackingParam,
  awsPersonalisationId?: string
}

export interface UploadMediaTrackingParam extends GenericTrackingParam {
  scope?: string;
  uploadFailed?: boolean;
  reason?: string;
  errorInfo?: string;
}

export interface BaseImpressionTrackingParam extends BaseTrackingParam {
  objectId?: string | Array<string>, // id of the main object (post/article/...)
  objectType?: string, // type of the main object (post/article/...)
  objectTitle?: string | Array<string>,// title of main object
  appId?: string
}

export interface RecommendationCardImpressionTrackingParam extends BaseImpressionTrackingParam {
  recommendedByUser?: string;
}

export interface PostCardImpressionTrackingParams extends BaseImpressionTrackingParam {
  parentType?: string, // this would be the parent type (group or ELP).
  parentId?: string, // this would be the parent id (group or ELP).
  postType?: string // this would be the type of post (e.g.: text | attachment | .... )
}

export interface SharedCaseCardImpressionTrackingParams extends PostCardImpressionTrackingParams {
  subObjectType?: string, // if applicable, for post with type = case, this would be case.
  subObjectId?: string,
}

export type ImpressionTrackingRequest = BaseImpressionTrackingParam |
  RecommendationCardImpressionTrackingParam |
  SharedCaseCardImpressionTrackingParams |
  PostCardImpressionTrackingParams;

export interface OrcidOriginOpenedTrackingParam extends GenericTrackingParam {
  reference: string;
  userId: string;
  orcid: string;
}

export interface LinkOrcIdUrlOpenedTrackingParam extends GenericTrackingParam {
  error: string;
  platform: string;
}

export interface AuthorizeOrcidTrackingParam extends GenericTrackingParam {
  authorizationFailed?: boolean;
  reason?: string;
  errorInfo?: string;
}

export enum ActionTracked {
  aoEventOpened = 'ao:event:open',
  aoEventViewOrigin = 'ao:event:view-origin', // navigate to AO Event details
  aoVideosOpened = 'ao-videos-opened',
  aoVideosShared = 'ao-videos-shared',
  aoVideosRecommended = 'ao-videos-recommended',
  articleFreeFulltextOpened = 'free-fulltext-opened',
  articlePubmedFulltextOpened = 'pubmed-fulltext-opened',
  articleAoFulltextOpened = 'ao-fulltext-opened',
  articleOriginOpened = 'article-origin-opened',
  articleOpened = 'article-opened',
  orcIdOpened = 'orcid-opened',
  articleShared = 'article-shared',
  articleRecommended = 'article-recommended',
  clicked = 'clicked',
  eventPageOpened = 'event-page-opened',
  eventCardClicked = 'event-card-clicked',
  eventShared = 'event-shared',
  caseOpened = 'case-opened',
  caseShared = 'case-shared',
  channelOpened = 'channel-opened', // journal page open
  externalLinkOpened = 'external-link-opened',
  groupPageOpened = 'group-page-opened',
  groupShared = 'group-shared',
  joinMyAoOpened = 'join-myao-opened',
  journalFollowed = 'journal-followed',
  journalUnFollowed = 'journal-unfollowed',
  pageOpened = 'page-opened', // generic (not tied to an id) page opened/loaded/viewed
  postOpened = 'post-opened',
  profileShared = 'profile-shared',
  publicProfileOpened = 'public-profile-opened',
  signUp = 'signup',
  signInStart = 'signin-start',
  signUpStart = 'signup-start',
  signUpCancel = 'signup-cancel',
  surgeryReferenceShared = 'surgery-reference-shared',
  surgeryReferenceLinkOpened = 'surgery-reference-link-opened',
  verificationFlow = 'verification-flow',
  unknown = 'unknown',
  qrCodeShared = 'qr-shared',
  translated = 'translated',
  uploaded = 'uploaded',
  caseFeatured = 'CaseFeatured',
  caseUnfeatured = 'CaseUnfeatured',
  orcidOriginOpened = 'orcid-origin-opened',
  linkOrcIdUrlOpened = 'link-orcid-url-opened',
  orcIdArticleShared = 'orcid-article-shared',
  orcIdAuthorize = 'orcid-authorize',
  journalLatestPublicationsOpened = 'journal-latest-publications-opened',
  orcidPublicationsOpened = 'orcid-publications-opened',
  aoVideoHubPageOpened = 'ao-video-hub-page-opened',
  ignorePerson = 'ignore-person',
  connect = 'connect',
  openPublicProfile = 'open-public-profile',
  userFollow = 'user-follow',
  userUnfollow = 'user-unfollow',
}

export enum TranslationAction {
  translate = 'translate',
  backToOriginal = 'translate-back-to-original'
}

export enum ActionSource {
  activeGroups = 'activeGroups',
  activityPage = 'activityPage', // also known as updatesPage
  articlePublishing = 'articlePublishing',
  articleVideoPage = 'articleVideoPage',
  articleVideoCard = 'articleVideoCard',
  contactsPage = 'contactsPage',
  customiseFeed = 'customiseFeed',
  eventsPage = 'eventsPage',
  eventPage = 'eventPage',
  homeStream = 'homeStream',
  socialStream = 'socialStream',
  smartStream = 'smartStream',
  joinMyAoPage = 'joinMyAoPage',
  journalDetailsPage = 'journalDetailsPage',
  myAoLoginPage = 'myAoLoginPage',
  recommendations = 'recommendations',
  searchResult = 'searchResult',
  userProfileSettings = 'userProfileSettings',
  videoHub = 'videoHub',
  groupParticipants = 'groupParticipants',
  groupCard = 'groupCard',
  unspecified = 'unspecified',
  userProfilePage = 'userProfilePage',
  userPublicProfilePage = 'userPublicProfilePage',
  feedPage = 'feedPage',
  myGroupsFeed = 'myGroupsFeed',
  caseFolioPage = 'caseFolioPage',
  eventCasePage = 'eventCasePage',
  eventPostPage = 'eventPostPage',
  groupPage = 'groupPage',
  groupPublicDetails = 'groupPublicDetails',
  groupCasePage = 'groupCasePage',
  groupPostPage = 'groupPostPage',
  groupsPage = 'groupsPage',
  journalsPage = 'journalsPage',
  secureChatPage = 'secureChatPage',
  myGroupsPage = 'myGroupsPage',
  myJournalsPage = 'myJournalsPage',
  myPeoplePage = 'myPeoplePage',
  recommendedGroupsPage = 'recommendedGroupsPage',
  recommendedPeoplePage = 'recommendedPeoplePage',
  searchPage = 'searchPage',
  authenticationPage = 'authenticationPage',
  orcidAuthRedirectPage = 'orcidAuthRedirectPage',
  authV2AoRedirect = 'auth-v2-ao-redirect',
  coContributorsPage = 'coContributorsPage',
  promptForNotificationsPermission = 'promptForNotificationsPermission',
  doNotificationsLogin = 'doNotificationsLogin',
  doNotificationsLogout = 'doNotificationsLogout',
  pushNotification = 'pushNotification',
  journalLatestPublicationsPage = 'journalLatestPublicationsPage',
  orcidPublicationsPage = 'orcidPublicationsPage',
  videoHubPage = 'videoHubPage',
  knowledgePage = 'knowledgePage',
  savedKnowledgePage = 'savedKnowledgePage',
  topContributorsDialog = 'topContributorsDialog',
  triggerPointRecommendation = 'triggerPointRecommendation',
  caseDetailsPage = 'caseDetailsPage',
  sharedCasePage = 'sharedCasePage',
  postPage = 'postPage',
  activitySuggestedActions = 'activitySuggestedActions'
}
