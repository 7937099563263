import { ContextRequest } from './context-request';
import { ErrorResponse } from './error';
import { ShortPublicProfile } from './public-profile';

export interface CasePublicData {
  blurredUrl: string,
  createdDate: string
  deleted: boolean,
  numberOfDocuments: number,
  owner: ShortPublicProfile,
  title: string,
  _id: string
}

/* eslint-disable */
export interface GroupPublicData {}
export interface ProfilePublicData {}
/* eslint-enable */

export enum ObjectRequestAccessType {
  'SHARED' = 'shared',
  'ALL' = 'all'
}

export enum ObjectRequestAccessStatus {
  'NONE' = 'none',
  'PENDING' = 'pending',
  'GRANTED' = 'granted',
  'DENIED' = 'denied'
}

export type CasePublicDataResponse = CasePublicDataSuccessResponse | ErrorResponse;
export type CasePublicDataRequestParams = ContextRequest;

export interface CasePublicDataSuccessResponse {
  _schemaPath?: '/chatter/ionic/cases/public/{{caseId}}';
  _schemaMethod?: 'get';
  result: CasePublicData;
}

export type GroupPublicDataResponse = GroupPublicDataSuccessResponse | ErrorResponse;
export type GroupPublicDataRequestParams = ContextRequest;

export interface GroupPublicDataSuccessResponse {
  _schemaPath?: '';
  _schemaMethod?: 'get';
  result: GroupPublicData;
}

export type ProfilePublicDataResponse = ProfilePublicDataSuccessResponse | ErrorResponse;
export type ProfilePublicDataRequestParams = ContextRequest;

export interface ProfilePublicDataSuccessResponse {
  _schemaPath?: '';
  _schemaMethod?: 'get';
  result: ProfilePublicData;
}

export type ObjectRequestAccessStatusResponse = ObjectRequestAccessStatusSuccessResponse | ErrorResponse;
export type ObjectRequestAccessStatusRequestParams = {
  type: ObjectRequestAccessType;
} & ContextRequest;

export interface ObjectRequestAccessStatusSuccessResponse {
  _schemaPath?: '/chatter/ionic/{{objectType}}/{{objectId}}/accessRights?type={{ObjectRequestAccessType}}';
  _schemaMethod?: 'get';
  result: ObjectRequestAccessStatus;
}
