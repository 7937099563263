import { ActiveConversationsState } from './active-conversations/active-conversations.state';
import { ClinicalCasesAccessRequestsState } from './clinical-cases-access-requests/clinical-cases-access-requests.state';
import { ClinicalCasesState } from './clinical-cases/clinical-cases.state';
import { CoContributorsState } from './co-contributors/co-contributors.state';
import { GroupsFeedState } from './groups-feed/groups-feed.state';
import { GroupFeedState } from './group-feed/group-feed.state';
import { PendingConnectionRequestsState } from './pending-connection-requests/pending-connection-requests.state';
import { EventFeedWithParticipantsState } from './event-feed-with-participants/event-feed-with-participants.state';
import { PostsFeedState } from './posts-feed/posts-feed.state';
import { EventFeedFacultyOnlyState } from './event-feed-faculty-only/event-feed-faculty-only.state';
import { PostsState } from './posts/posts.state';
import { MyNetworkStreamState } from './my-network-stream/my-network-stream.state';
import { ActivitiesState } from './activities/activities.state';
import { MyKnowledgeStreamState } from './my-knowledge-stream/my-knowledge-stream.state';
import { SavedKnowledgeState } from './saved-knowledge/saved-knowledge.state';
import { PeopleYouMayKnowState } from './people-you-may-know/people-you-may-know.state';

export const states = [
    ClinicalCasesState,
    ClinicalCasesAccessRequestsState,
    PendingConnectionRequestsState,
    CoContributorsState,
    ActiveConversationsState,
    GroupsFeedState,
    PostsFeedState,
    GroupFeedState,
    EventFeedWithParticipantsState,
    EventFeedFacultyOnlyState,
    PostsState,
    MyNetworkStreamState,
    ActivitiesState,
    MyKnowledgeStreamState,
    SavedKnowledgeState,
    PeopleYouMayKnowState
];