import { Component, EventEmitter, Inject, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { Subscription } from 'rxjs';

// models
import { GroupListItem } from 'src/app/services/yeti-protocol/chatter-api';
import { GroupUserScopes, GroupVisibility } from 'src/app/services/groups/group.model';
import { UserProfile } from 'src/app/services/yeti-protocol/auth/mi';

// services
import { AuthService } from 'src/app/services/auth/auth.service';
import { CONTEXT_SERVICE, ContextService } from 'src/app/services/context/context.model';
import { GroupsService } from 'src/app/services/groups/groups.service';
import { GroupsUIService } from 'src/app/services/groups/groups-ui.service';
import { ToastService, ToastMode } from 'src/app/services/toast.service';
import { UIUtilsServiceInterface, UI_UTILS_SERVICE } from 'src/app/services/utils/ui-utils.service.interface';
import { InfoSheetActionItem } from 'src/app/modules/info-sheet/models/info-sheet-action-item.model';
import { GroupUserPermissions, RemoveGroupEvent } from '../../../services/groups/group.model';
import { ResponsiveUtilsService } from '../../../services/utils/responsive-utils.service';
import { InfoSheetService } from 'src/app/modules/info-sheet/services/info-sheet.service';
import { VerificationService } from 'src/app/services/verification.service';
import { VerificationStatus } from '../../../services/verification.model';
import { CreateGroupService } from 'src/app/services/create-content/group/create-group.service';
import { GroupsDataService } from 'src/app/services/groups/groups-data.service';
import { Group, LeaveGroupMemberStatus } from '../../../services/yeti-protocol/chatter-api';
import { SharingUIService } from 'src/app/services/sharing/sharing-ui.service';
import {
  ItemTypes,
  RecommendationsBookmarksService
} from 'src/app/services/recommendations-service/recommendations-bookmarks.service';

enum GroupActions {
  EDIT_GROUP = 'EDIT_GROUP',
  DELETE_GROUP = 'DELETE_GROUP',
  LEAVE_GROUP = 'LEAVE_GROUP',
  SHARE_GROUP = 'SHARE_GROUP',
  RECOMMEND = 'RECOMMEND'
}

@Component({
  selector: 'app-groups-row-list-item',
  templateUrl: './groups-row-list-item.component.html',
  styleUrls: ['./groups-row-list-item.component.scss'],
})
export class GroupsRowListItemComponent implements OnInit, OnDestroy, OnChanges {
  @Input() group: GroupListItem;
  @Input() hideBorder = false;
  @Input() withoutOwnerActions: boolean;
  @Input() displayOwnerName = true;
  @Input() displayActionBtns = true;
  @Input() disableGroupOpen = false;
  @Input() showImageBanner = false;

  @Output() openGroup: EventEmitter<string> = new EventEmitter();
  @Output() removeGroup: EventEmitter<RemoveGroupEvent> = new EventEmitter<RemoveGroupEvent>();

  groupsUiService: GroupsUIService;
  GroupVisibility = GroupVisibility;
  infoSheetId = 'group-list-item-info-sheet';
  groupUserPermissions: GroupUserPermissions;

  protected joiningGroup: boolean;
  protected requestingToJoin: boolean;

  private userProfile: UserProfile;
  private userProfileSubscription: Subscription;
  private groupUserPermissionsSubscription: Subscription;

  constructor(
    private authService: AuthService,
    @Inject(CONTEXT_SERVICE) private contextService: ContextService,
    private groupsService: GroupsService,
    private toast: ToastService,
    @Inject(UI_UTILS_SERVICE) private uiUtilsService: UIUtilsServiceInterface,
    private responsiveUtilsService: ResponsiveUtilsService,
    private infoSheetService: InfoSheetService,
    private verificationService: VerificationService,
    private createGroupService: CreateGroupService,
    private groupsDataService: GroupsDataService,
    private sharingUIService: SharingUIService,
    private recommendationsService: RecommendationsBookmarksService,
  ) {
    this.groupsUiService = new GroupsUIService();
  }

  ngOnInit(): void {

    this.infoSheetId = this.infoSheetService.generateNewInfoSheetIdIfDuplicate(this.infoSheetId);

    this.userProfileSubscription = this.authService.userProfileAsObservable.subscribe((userProfile: UserProfile) => {
      this.userProfile = userProfile;
    });

    this.groupUserPermissionsSubscription = this.groupsUiService.groupUserPermissions.subscribe((permissions: GroupUserPermissions) => {
      this.groupUserPermissions = permissions;
    });
  }

  ngOnDestroy(): void {
    this.userProfileSubscription?.unsubscribe();
    this.groupUserPermissionsSubscription?.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.group) {
      this.groupsUiService.setGroup(changes.group.currentValue);
    }
  }

  checkAndPromptCanAccessAoMembersOnlyGroup(): Promise<boolean> {
    return this.uiUtilsService.checkAndPromptCanAccessAoMembersOnlyGroup(
      this.group?.AOMembersOnly,
      this.userProfile?.isAOMember,
      this.contextService?.currentContext?.aoMembershipUrl
    );
  }

  async joinGroup(event: Event): Promise<void> {

    this.uiUtilsService.stopEventPropagation(event);

    if (this.joiningGroup) {
      return;
    }

    const canAccess = await this.checkAndPromptCanAccessAoMembersOnlyGroup();
    if (!canAccess) {
      return;
    }

    this.joiningGroup = true;

    this.groupsService.joinGroup(this.group?._id, this.userProfile?.email, this.userProfile?.id)
      .then(() => {
        return this.fetchGroup();
      }).then(group => {
        this.setGroup(group);
      }).catch(err => {
        if (err?.error?.error?.message?.errfor?.message) {
          this.showError(err?.error?.error?.message?.errfor?.message);
        }
      })
      .finally(() => {
        this.joiningGroup = false;
      });
  }

  async requestToJoin(event: Event): Promise<void> {

    this.uiUtilsService.stopEventPropagation(event);

    if (this.requestingToJoin) {
      return;
    }

    const canAccess = await this.checkAndPromptCanAccessAoMembersOnlyGroup();
    if (!canAccess) {
      return;
    }

    this.requestingToJoin = true;

    this.groupsService.requestToJoinGroup(this.group?._id, this.userProfile?.email, this.userProfile?.id)
      .then(() => {
        return this.fetchGroup();
      }).then(group => {
        this.setGroup(group);
      }).catch(err => {
        if (err?.error?.error?.message?.errfor?.message) {
          this.showError(err?.error?.error?.message?.errfor?.message);
        }
      }).finally(() => {
        this.requestingToJoin = false;
      });
  }

  requestToJoinSent(event: Event): void {
    this.uiUtilsService.stopEventPropagation(event);
  }

  setGroup(group: GroupListItem): void {
    this.group = group;
    this.groupsUiService.setGroup(group);
  }

  /* eslint-disable */
  showError(err: any): void {
    /* eslint-enable */
    this.toast.showWithMessage(err, 'app.common.error-default', ToastMode.ERROR);
  }

  fetchGroup(): Promise<GroupListItem> {
    return this.groupsService.getGroupById(this.group?._id)
      .then(res => {
        if ('result' in res) {
          return res.result;
        }
        return Promise.reject('Group is not found');
      });
  }

  onOpenGroup(event: Event): void {
    this.uiUtilsService.stopEventPropagation(event);
    this.openGroup.emit(this.group._id)
  }

  get showOpenButton(): boolean {
    if (this.group?.visibility === 'Public') {
      return this.group?.status === GroupUserScopes.OWNER ||
        this.group?.status === GroupUserScopes.MEMBER ||
        this.group?.status === GroupUserScopes.MODERATOR ||
        this.group?.status === GroupUserScopes.PENDING_VERIFICATION_REQUEST;
    } else {
      return this.group?.status === GroupUserScopes.OWNER ||
        this.group?.status === GroupUserScopes.MEMBER ||
        this.group?.status === GroupUserScopes.MODERATOR;
    }
  }

  async openGroupSettings(event: Event): Promise<void> {
    this.uiUtilsService.stopEventPropagation(event);
    if (this.responsiveUtilsService.isDesktop) {
      this.infoSheetService.openWeb(this.infoSheetActions, event)
        .then(action => {
          if (action && action.code) {
            action.handler();
          }
        });
    } else {
      if (this.groupUserPermissions) {
        this.infoSheetService.open(this.infoSheetId);
      }
    }
  }

  get infoSheetActions(): Array<InfoSheetActionItem> {
    const actions: Array<InfoSheetActionItem> = [];
    if (this.groupUserPermissions.editGroup) {
      actions.push({
        id: 'list_item_edit_group_btn',
        icon: 'md-icon-pencil',
        textKey: 'app.groups.edit-group',
        code: GroupActions.EDIT_GROUP,
        handler: () => {
          this.editGroup();
        }
      });
    }
    if (this.groupUserPermissions.recommend) {
      actions.push({
        id: 'list_item_recommend_group_btn',
        icon: this.group.hasRecommended ? 'md-icon-recommend-filled' : 'md-icon-recommend',
        textKey: this.group.hasRecommended ? 'app.groups.recommended' : 'app.groups.recommend',
        code: GroupActions.RECOMMEND,
        handler: () => {
          this.onRecommendButtonClick();
        }
      });
    }
    if (this.groupUserPermissions.share) {
      actions.push({
        id: 'list_item_share_group_btn',
        icon: 'md-icon-share',
        textKey: 'app.groups.share-group',
        code: GroupActions.SHARE_GROUP,
        handler: () => {
          this.presentShareDialog(this.group);
        }
      });
    }
    if (this.groupUserPermissions.leaveGroup) {
      actions.push({
        id: 'list_item_leave_group_btn',
        icon: 'md-icon-leave',
        textKey: 'app.groups.leave-group',
        code: GroupActions.LEAVE_GROUP,
        handler: () => {
          this.presentLeaveGroupConfirm();
        }
      })
    }
    if (this.groupUserPermissions.deleteGroup) {
      actions.push({
        id: 'list_item_delete_group_btn',
        icon: 'md-icon-bin',
        textKey: 'app.groups.delete-group',
        code: GroupActions.DELETE_GROUP,
        handler: () => {
          this.presentDeleteGroupConfirm();
        }
      });
    }
    return actions;
  }

  private async editGroup(): Promise<void> {
    const userVerificationStatus = await this.verificationService.verify();
    if (userVerificationStatus === VerificationStatus.VERIFIED)
      this.createGroupService.showCreateEditGroupDialog(this.group);
  }

  private async presentDeleteGroupConfirm(): Promise<void> {
    await this.groupsService.presentDeleteGroupConfirm()
      .then((confirm: boolean) => {
        if (confirm) {
          this.deleteGroup();
        }
      });
  }

  private deleteGroup(): void {
    this.groupsService.deleteGroup(this.group?._id).then(() => {
      this.groupsDataService.triggerGroupDeletedAction(this.group);
      this.removeGroup.emit({ groupId: this.group._id });
    }).catch(err => {
      if (err?.error?.error?.message?.errfor?.message) {
        this.showError(err?.error?.error?.message?.errfor?.message);
      }
    });
  }

  private async presentLeaveGroupConfirm(): Promise<void> {
    await this.groupsService.presentLeaveGroupConfirm()
      .then((confirm: boolean) => {
        if (confirm) {
          this.leaveGroup();
        }
      });
  }

  private async leaveGroup(): Promise<void> {
    this.groupsService.leaveGroup(this.group?._id, this.userProfile?.id, LeaveGroupMemberStatus.LEFT).then(() => {
      this.toast.show('app.groups.group-left-message', null, ToastMode.SUCCESS);
      this.groupsDataService.triggerGroupLeftAction(this.group);
      this.removeGroup.emit({ groupId: this.group._id });
    }).catch(err => {
      if (err?.error?.error?.message?.errfor?.message) {
        this.showError(err?.error?.error?.message?.errfor?.message);
      }
    });
  }

  private presentShareDialog(group: Group): void {
    this.sharingUIService.showShareDialogForObject(group);
  }

  private onRecommendButtonClick(): void {
    if (this.group.hasRecommended === true) {
      this.removeRecommendGroup();
    } else {
      this.recommendGroup();
    }
  }

  private recommendGroup(): void {
    this.recommendationsService.addRecommendationBookmarkItem('group', ItemTypes.RECOMMENDATIONS, this.group._id)
      .then(response => {
        if (response && response.success)
          this.group.hasRecommended = true;
        this.toast.show('app.recommendedItems.group-recommend');
      })
  }

  private removeRecommendGroup(): void {
    this.recommendationsService.removeRecommendationBookmarkItem('group', ItemTypes.RECOMMENDATIONS, this.group._id)
      .then(response => {
        if (response && response.success)
          this.group.hasRecommended = false;
        this.toast.show('app.recommendedItems.remove-recommended-group');
      })
  }
}
