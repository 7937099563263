import { Component, Inject, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { IonInfiniteScroll, ModalController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import * as _ from 'lodash';

import { CustomTab } from 'src/app/components/custom-tabs/custom-tabs.component';
import { AppTranslationService } from 'src/app/services/app-translation.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { GroupVisibility } from 'src/app/services/groups/group.model';
import { GroupsService } from 'src/app/services/groups/groups.service';
import { ToastMode, ToastService } from 'src/app/services/toast.service';
import { UserProfile } from 'src/app/services/yeti-protocol/auth/mi';
import { GroupListItem, LastActiveGroupsSuccessResponse } from 'src/app/services/yeti-protocol/chatter-api';
import { Connection } from 'src/app/services/yeti-protocol/connections';
import { ObjectForSharing, ShareToObject, ShareToObjType } from 'src/app/services/sharing/sharing.model';
import {
  UIUtilsServiceInterface,
  UI_UTILS_SERVICE
} from 'src/app/services/utils/ui-utils.service.interface';
import { ConnectedUser } from 'src/app/services/yeti-protocol/public-profile';
import { EventsService } from 'src/app/services/events/events.service';
import { AOEvent } from '../../services/yeti-protocol/event';

enum TabIds {
  groups = 'groups',
  events = 'events'
}

@Component({
  selector: 'app-share-recipients-dialog',
  templateUrl: './share-recipients-dialog.component.html',
  styleUrls: ['./share-recipients-dialog.component.scss'],
})
export class ShareRecipientsDialogComponent implements OnInit, OnDestroy {

  @ViewChild('infiniteScrollGroups') infiniteScrollGroups: IonInfiniteScroll;
  @ViewChild('infiniteScrollEvents') infiniteScrollEvents: IonInfiniteScroll;

  @Input() selectedMemberGroups: Array<ShareToObject> = [];
  @Input() selectedEvents: Array<ShareToObject> = [];
  @Input() objectForSharing: ObjectForSharing;

  maxRecipientsSelectCount = 10;
  maxRecipientsSelectCountGroups = 3;
  customTabs: Array<CustomTab> = [];
  activeTabId: string;
  TabIds = TabIds;
  GroupVisibility = GroupVisibility;
  memberGroups: Array<ShareToObject> = [];
  totalMemberGroups: number;
  memberGroupsLoading = true;
  participantsPageCount = 20;
  userEvents: Array<ShareToObject> = new Array<ShareToObject>();
  userEventsLoading = true;
  totalUserEvents: number;

  private userProfileSubscription: Subscription;
  private userProfile: UserProfile;
  private originalySelectedMemberGroups: Array<ShareToObject> = [];
  private originalySelectedEvents: Array<ShareToObject> = [];
  private eventsSubscription: Subscription;

  constructor(
    private appTranslationService: AppTranslationService,
    private modalController: ModalController,
    private toast: ToastService,
    private authService: AuthService,
    @Inject(UI_UTILS_SERVICE) private uiUtilsService: UIUtilsServiceInterface,
    private groupsService: GroupsService,
    private eventsService: EventsService
  ) { }

  ngOnInit(): void {
    this.generateTabs();

    this.userProfileSubscription = this.authService.userProfileAsObservable.subscribe((userProfile: UserProfile) => {
      this.userProfile = userProfile;
    });

    this.getMemberGroups()
      .then(groups => {
        this.memberGroups = groups;
      }).finally(() => {
        this.memberGroupsLoading = false;
      });

    this.getUserEvents()
      .then(events => {
        this.userEvents = events;
      }).finally(() => {
        this.userEventsLoading = false;
      })

    this.originalySelectedMemberGroups = _.cloneDeep(this.selectedMemberGroups);
    this.originalySelectedEvents = _.cloneDeep(this.selectedEvents);
  }

  ngOnDestroy(): void {
    this.userProfileSubscription?.unsubscribe();
    this.eventsSubscription?.unsubscribe();
  }

  get selectedRecipientsCount(): number {
    return (this.selectedMemberGroups?.length || 0) +
      (this.selectedEvents?.length || 0);
  }

  setSelectedRecipients(): void {
    this.modalController.dismiss({
      selectedMemberGroups: this.selectedMemberGroups || [],
      selectedEvents: this.selectedEvents || []
    });
  }

  onBack(): void {
    this.modalController.dismiss({
      selectedMemberGroups: this.originalySelectedMemberGroups || [],
      selectedEvents: this.originalySelectedEvents || [],
      closed: true
    });
  }

  get nextButtonDisabled(): boolean {
    return this.selectedRecipientsCount < 1;
  }

  setActiveTab(tab: CustomTab): void {
    this.activeTabId = tab.id;
  }

  isOriginallySelected({object, type}: ShareToObject): boolean {
    if (type === ShareToObjType.GROUP) {
      return this.objectForSharing?.meta?.groupsSharedTo?.includes(object._id);
    } else {
      return this.objectForSharing?.meta?.eventsSharedTo?.includes(object._id);
    }
  }

  private generateTabs(): void {

    this.customTabs = [
      {
        id: TabIds.groups,
        text: this.appTranslationService.instant('app.dialogs.SocialSharing.groups'),
        active: true
      },
      {
        id: TabIds.events,
        text: this.appTranslationService.instant('app.dialogs.SocialSharing.ao-events'),
        active: false
      }];

    this.activeTabId = TabIds.groups;
  }

  getMemberGroups(start: number = 0): Promise<Array<ShareToObject>> {

    if (!this.groupsService) {
      return Promise.resolve([]);
    }

    return this.groupsService.getLastActiveGroups(start, this.participantsPageCount, true)
      .then((lastActiveGroupsResponse: LastActiveGroupsSuccessResponse) => {

        this.totalMemberGroups = lastActiveGroupsResponse.totalItemsCount;

        return lastActiveGroupsResponse?.result.map(group => ({
          object: group,
          type: ShareToObjType.GROUP
        }));
      });
  }

  loadMoreMemberGroups(): void {

    if (this.memberGroups.length >= this.totalMemberGroups) {
      this.disableInfiniteScrollGroups(true);
      return;
    }

    const start = Math.floor(this.memberGroups.length / this.participantsPageCount);

    this.getMemberGroups(start).then((groups) => {
      this.memberGroups = [...this.memberGroups, ...groups];
    }).catch(err => {
      this.showError(err.message);
    }).finally(() => {

      // Hide Infinite List Loader on Complete
      this.infiniteScrollGroups.complete();
    });
  }

  getUserEvents(start: number = 0): Promise<Array<ShareToObject>> {
    return new Promise((resolve, reject) => {
      this.eventsSubscription = this.eventsService.getUserEvents(start, this.participantsPageCount, true, false, false, true)
        .subscribe({
          next: (events) => {
            this.totalUserEvents = events?.totalItemsCount;
            resolve(events?.result.map(event => ({
              object: event,
              type: ShareToObjType.EVENT
            })));
          }, error: (err) => {
            reject(err);
          }
        });
    });
  }

  loadMoreUserEvents(): void {
    if (this.userEvents.length >= this.totalUserEvents) {
      this.disableInfiniteScrollUserEvents(true);
      return;
    }

    const start = Math.floor(this.userEvents.length / this.participantsPageCount);

    this.getUserEvents(start).then((events) => {
      this.userEvents = [...this.userEvents, ...events];
    }).catch(err => {
      this.showError(err.message);
    }).finally(() => {
      this.infiniteScrollEvents.complete();
    });
  }

  isMemberGroupSelected(item: ShareToObject): boolean {

    this.selectedMemberGroups = this.selectedMemberGroups || [];

    const index = this.selectedMemberGroups.findIndex(g => g?.object?._id === item?.object?._id);

    return index > -1 || this.isOriginallySelected(item);
  }

  isEventSelected(item: ShareToObject): boolean {

    this.selectedEvents = this.selectedEvents || [];

    const index = this.selectedEvents.findIndex(e => e?.object?._id === item?.object?._id);

    return index > -1 || this.isOriginallySelected(item);
  }

  onGroupSelectionChange(
    selected: boolean,
    group: ShareToObject): void {
    this.onRecipientSelectionChange(selected, group, null, null);
  }

  onEventsSelectionChange(
    selected: boolean,
    event: ShareToObject): void {
    this.onRecipientSelectionChange(selected, null, null, event);
  }

  getConnectionPartner(connection: Connection): ConnectedUser {

    if (connection?.initiator?.userId !== this.userProfile?.userId) {
      return connection.initiator;
    } else {
      return connection.partner;
    }
  }

  get disableSelectableItems(): boolean {
    return this.selectedRecipientsCount >= this.maxRecipientsSelectCount;
  }

  get disableSelectableGroupItems(): boolean {

    if (!this.objectForSharing) {
      return this.selectedMemberGroups?.length >= this.maxRecipientsSelectCountGroups;
    } else {
      const leftNumberOfGroupsToBeSelected = this.maxRecipientsSelectCountGroups - this.objectForSharing?.meta?.numberOfGroupsSharedTo;
      return this.selectedMemberGroups?.length >= leftNumberOfGroupsToBeSelected;
    }
  }

  disableGroupsSelection(group: GroupListItem): boolean {
    return this.uiUtilsService.disableGroupsSelection(this.objectForSharing, group);
  }

  disableEventsSelection(event: AOEvent): boolean {
    return this.uiUtilsService.disableEventsSelection(this.objectForSharing, event);
  }

  showSelectionLimitReachedError(): void {
    const errorMessage = this.appTranslationService.instant('app.dialogs.SocialSharing.too-many-recipients-groups-error-text');
    this.showError(errorMessage, 'app.dialogs.SocialSharing.too-many-recipients-error-title');
  }

  showSelectionLimitReachedGroupsError(): void {
    const errorMessage = this.appTranslationService.instant('app.dialogs.SocialSharing.too-many-recipients-groups-error-text');
    this.showError(errorMessage, 'app.dialogs.SocialSharing.too-many-recipients-error-title');
  }

  private onRecipientSelectionChange(
    selected: boolean,
    group?: ShareToObject,
    contact?: ShareToObject,
    event?: ShareToObject): void {

    if (group && contact && event) {
      console.error('You should provide only group or contact or event'); // dev error
      return;
    }

    if (!group && !contact && !event) {
      console.error('You should provide group or contact or event'); // dev error
      return;
    }

    let selectedRecipients = [];
    let recipient: ShareToObject;
    let index = -1;

    if (group) {
      recipient = group;
      selectedRecipients = this.selectedMemberGroups;
    } else {
      recipient = event;
      selectedRecipients = this.selectedEvents;
    }

    index = selectedRecipients.findIndex(r => r?.object?._id === recipient?.object?._id);

    if (selected && index === -1) {
      selectedRecipients.push(recipient);
    } else if (!selected && index > -1) {
      selectedRecipients.splice(index, 1);
    }
  }

  private disableInfiniteScrollGroups(value: boolean): void {
    if (this.infiniteScrollGroups) {
      this.infiniteScrollGroups.disabled = value;
    }
  }

  private disableInfiniteScrollUserEvents(value: boolean): void {
    if (this.infiniteScrollEvents) {
      this.infiniteScrollEvents.disabled = value;
    }
  }

  private showError(msg: string, toastHeaderErrorId?: string): void {
    toastHeaderErrorId = toastHeaderErrorId ? toastHeaderErrorId : 'app.common.error-default';
    this.toast.showWithMessage(msg, toastHeaderErrorId, ToastMode.ERROR);
  }

}
