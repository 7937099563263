import {Component, Input, OnInit} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {ActionSource} from '../../services/yeti-protocol/tracking';
import {PublicProfile} from '../../services/yeti-protocol/public-profile';

@Component({
  selector: 'app-group-top-contributors-dialog',
  templateUrl: './group-top-contributors-dialog.component.html',
  styleUrls: ['./group-top-contributors-dialog.component.scss'],
})
export class GroupTopContributorsDialogComponent implements OnInit {

  @Input() groupId?: string;

  ActionSource = ActionSource;
  modifiedTopContributors: {[id: string]: PublicProfile} = {};

  constructor(
    private modalController: ModalController,
  ) {}

  ngOnInit(): void {

  }

  onTopContributorModified(topContributor: PublicProfile): void {
    this.modifiedTopContributors[topContributor.userId] = topContributor;
  }

  onClose(): void {
    this.modalController.dismiss(this.modifiedTopContributors, 'cancel');
  }

}
