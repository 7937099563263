import { Component, EventEmitter, Input, Output } from '@angular/core';


@Component({
  selector: 'app-accept-reject-card',
  templateUrl: './accept-reject-card.component.html',
  styleUrls: ['./accept-reject-card.component.scss'],
})
export class AcceptRejectCardComponent {

  @Input() id: string;
  @Input() imageUrl: string;
  @Input() userId: string;
  @Input() isAOMember: string;
  @Input() firstName: string;
  @Input() lastName: string;
  @Input() isVerified: string;
  @Input() entityTitle: string;
  @Input() infoBubbleMessage: string;

  @Output() accepted: EventEmitter<{ id: string, userId: string }> = new EventEmitter();
  @Output() rejected: EventEmitter<{ id: string, userId: string }> = new EventEmitter();
  @Output() imageClicked: EventEmitter<string> = new EventEmitter();
  @Output() infoBubbleMessageClicked: EventEmitter<{ id: string, userId: string }> = new EventEmitter();

  onImageClick(userId: string): void {
    this.imageClicked.emit(userId);
  }

  acceptClicked(): void {
    this.accepted.emit({ id: this.id, userId: this.userId });
  }

  rejectClicked(): void {
    this.rejected.emit({ id: this.id, userId: this.userId });
  }

  onInfoBubbleMessageClick(): void {
    this.infoBubbleMessageClicked.emit({ id: this.id, userId: this.userId });
  }
}
