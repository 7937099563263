import { Component, Input, OnInit, Inject, OnDestroy } from '@angular/core';
import { ClinicalCase, FeaturedCasesResponse, FeaturedCasesSuccessResponse } from '../../services/yeti-protocol/clinical-case';
import { IonRefresher, ModalController } from '@ionic/angular';
import { ClinicalCaseService } from '../../services/case-library/clinical-case.service';
import { ToastMode, ToastService } from '../../services/toast.service';
import { VerticalListLoadMoreData } from 'src/app/components/vertical-list/vertical-list.component';
import { ActionSource, ImpressionTrackingRequest } from '../../services/yeti-protocol/tracking';
import { ContextService, CONTEXT_SERVICE } from '../../services/context/context.model';
import { NavControllerService } from '../../services/nav-controller.service';
import { Subscription } from 'rxjs';
import { TargetImpression, VisibilityTrackerService } from '../../modules/visibility-tracker/visibility-tracker.service';
import { TrackingService, TRACKING_SERVICE } from '../../services/tracking/tracking.model';

@Component({
  selector: 'app-featured-cases-dialog',
  templateUrl: './featured-cases-dialog.component.html',
  styleUrls: ['./featured-cases-dialog.component.scss'],
})
export class FeaturedCasesDialogComponent implements OnInit, OnDestroy {

  @Input() userId: string;

  featuredCases: Array<ClinicalCase> = [];
  loading: boolean;
  totalItemsCount: number;
  readonly count = 10;
  readonly visibilityRootKey = 'featured-cases-impressions-tracking-root';

  private itemsTrackingSubscription: Subscription;

  constructor(
    private modalController: ModalController,
    private clinicalCaseService: ClinicalCaseService,
    private toast: ToastService,
    @Inject(CONTEXT_SERVICE) private contextService: ContextService,
    private navController: NavControllerService,
    private visibilityTrackerService: VisibilityTrackerService,
    @Inject(TRACKING_SERVICE) private trackingService: TrackingService,
  ) { }

  ngOnInit(): void {
    this.getFeaturedCases();

    this.itemsTrackingSubscription = this.visibilityTrackerService.impressionsAsObservable.subscribe(targetImression => {
      this.trackListItemImpression(targetImression);
    });
  }

  ngOnDestroy(): void {
    this.itemsTrackingSubscription?.unsubscribe();
  }

  getFeaturedCases(start = 0): Promise<FeaturedCasesResponse> {

    this.loading = true;
    const promise = this.clinicalCaseService.getFeaturedCases(start, this.count, this.userId);

    promise.then(response => {

      if (response && response.success) {

        response = response as FeaturedCasesSuccessResponse;
        this.totalItemsCount = response.totalItemsCount || 0;
        this.featuredCases = [...this.featuredCases || [], ...response?.result || []];
      }
    }).catch(err => {
      console.error(err);
      this.showError(err?.error?.error?.message?.errfor?.message);
    }).finally(() => {
      this.loading = false;
    });

    return promise;
  }

  async refreshFeaturedCases(refresher: IonRefresher): Promise<void> {

    this.featuredCases = [];
    this.totalItemsCount = 0;

    this.getFeaturedCases()?.finally(() => {
      refresher.complete();
    });
  }

  async loadMoreFeaturedCases(verticalListLoadMoreData: VerticalListLoadMoreData): Promise<void> {

    let start = 0;
    start = Math.floor(this.featuredCases?.length / this.count);

    this.getFeaturedCases(start)?.finally(() => {
      verticalListLoadMoreData.infiniteScroll.complete();
    });
  }

  onClose(): void {
    this.modalController.dismiss();
  }

  private showError(msg: string): void {
    this.toast.showWithMessage(msg, 'app.common.error-default', ToastMode.ERROR);
  }

  openCase(clinicalCase: ClinicalCase): void {

    if (!clinicalCase?._id) {
      return;
    }

    const source = this.userId ? ActionSource.userPublicProfilePage : ActionSource.userProfilePage;
    const context = this.contextService.currentContext.key;
    this.navController.navigateForward(`/${context}/case/${clinicalCase?._id}?source=${source}`);
    this.modalController.dismiss();
  }

  trackListItemImpression(targetImpression: TargetImpression): void {

    if (targetImpression.rootKey !== this.visibilityRootKey) {
      return;
    }

    if (!targetImpression?.targetKey?.length) {
      console.error(`missing target key for item in list: ${targetImpression.rootKey}`);
      return;
    }


    const itemObj = this.featuredCases.find(clinicalCase => clinicalCase?._id === targetImpression?.targetKey);

    if (!itemObj) {
      return;
    }

    const impressionTrackingRequest: ImpressionTrackingRequest = {
      objectId: targetImpression?.targetKey,
      objectType: 'case',
    };

    impressionTrackingRequest.source = 'seeAllFeaturedCasesDialog';

    const objectTitle = (itemObj as any)?.title || '';

    if (objectTitle) {
      impressionTrackingRequest.objectTitle = objectTitle;
    }

    this.trackingService.trackListItemImpression(impressionTrackingRequest);
  }

}
