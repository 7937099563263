import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild, Inject } from '@angular/core';
import { Observable, Subscription, catchError, first } from 'rxjs';
import { ParentType, Post, PostListItem, PostType } from '../../services/yeti-protocol/chatter-api';
import { IonInfiniteScroll } from '@ionic/angular';
import { ToastMode, ToastService } from 'src/app/services/toast.service';
import { ChatterApiService } from '../../services/chatter-api.service';
import { UIUtilsServiceInterface, UI_UTILS_SERVICE } from '../../services/utils/ui-utils.service.interface';
import { DialogsUIService } from '../../services/dialogs/dialogs.ui.service';
import { AppTranslationService } from '../../services/app-translation.service';
import { TargetImpression, VisibilityTrackerService } from 'src/app/modules/visibility-tracker/visibility-tracker.service';
import { TRACKING_SERVICE, TrackingService } from 'src/app/services/tracking/tracking.model';
import {
  ImpressionTrackingRequest,
  PostCardImpressionTrackingParams,
  SharedCaseCardImpressionTrackingParams
} from 'src/app/services/yeti-protocol/tracking';
import { Store } from '@ngxs/store';
import { StateReset } from 'ngxs-reset-plugin';
import { PostsFeedStateModel } from 'src/app/state/posts-feed/posts-feed.model';
import { PostsFeed } from 'src/app/state/posts-feed/posts-feed.actions';

@Component({
  selector: 'app-posts-list',
  templateUrl: './posts-list.component.html',
  styleUrls: ['./posts-list.component.scss'],
})
export class PostsListComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild(IonInfiniteScroll) infiniteScroll: IonInfiniteScroll;

  @Input() parentId: string;
  @Input() parentType: ParentType;
  @Input() facultyOnly: boolean;
  @Input() isModerator: boolean;
  @Input() noPadding: boolean;
  @Input() noMargin: boolean;
  @Input() visibilityRootKey: string;
  @Input() streamTrackingId: string;
  @Input() stateSelector: any;
  @Input() state: any;
  @Input() stateActions: any;

  @Output() showEmptyState: EventEmitter<boolean> = new EventEmitter<boolean>(false);
  @Output() noAccess: EventEmitter<void> = new EventEmitter<void>();

  postType = PostType;
  posts: Array<PostListItem> = [];
  pinnedPosts: Array<PostListItem> = [];
  loading: boolean;
  loadedPostsCount: number;
  postsState$: Observable<PostsFeedStateModel>;

  protected totalItemsCount: number;

  private readonly start = 0;
  private readonly count = 10;
  private postsTrackingSubscription: Subscription;
  private postsStateSubscription: Subscription;

  constructor(
    private toast: ToastService,
    private chatterApiService: ChatterApiService,
    @Inject(UI_UTILS_SERVICE) public uiUtilsService: UIUtilsServiceInterface,
    private dialogs: DialogsUIService,
    private appTranslationService: AppTranslationService,
    private visibilityTrackerService: VisibilityTrackerService,
    @Inject(TRACKING_SERVICE) private trackingService: TrackingService,
    private store: Store
  ) { }

  ngOnInit(): void {

    this.postsTrackingSubscription = this.visibilityTrackerService.impressionsAsObservable.subscribe(targetImression => {
      this.trackListItemImpression(targetImression);
    });

    if (this.stateSelector) {
      this.postsState$ = this.store.select(this.stateSelector);
    }

    this.postsStateSubscription = this.postsState$?.subscribe(state => {

      this.loading = state.fetchPostsFeedItemsloading;
      this.totalItemsCount = state.totalCount;
      this.posts = state.postsFeedItemsWithoutPinned;
      this.pinnedPosts = state.pinnedPostsFeedItems;
      this.loadedPostsCount = state.allPostsFeedItems?.length;

      this.showEmptyState.emit(!this.loadedPostsCount && !this.loading);

      if (!this.loading) {
        this.infiniteScroll?.complete();

        if (this.loadedPostsCount < this.totalItemsCount) {
          this.disableInfiniteScroll(false);
        } else {
          this.disableInfiniteScroll(true);
        }
      }

      if (this.parentType && this.parentId && !state.parentType && !state.parentId) {
        this.store.dispatch(new this.stateActions.SetParentTypeAndParentId(this.parentType, this.parentId));
      }
    });
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.reloadPosts();
    }, 0);
  }

  ngOnDestroy(): void {
    this.postsTrackingSubscription?.unsubscribe();
    this.postsStateSubscription?.unsubscribe();
  }

  reloadPosts(): void {

    if (this.state) {
      this.store.dispatch(
        new StateReset(this.state)
      );
    }

    this.getPosts(this.start, this.count);
    this.disableInfiniteScroll(false);
  }

  getPosts(start: number = this.start, count: number = this.count): void {

    const payloadParams: PostsFeed.FetchPostsFeedPayload = {
      parentId: this.parentId,
      parentType: this.parentType,
      facultyOnly: this.facultyOnly,
      pageIndex: start,
      pageSize: count
    };

    if (this.stateActions) {
      this.store.dispatch(new this.stateActions.FetchPostsFeed(payloadParams)).pipe(
        first(),
        catchError((err) => {

          const errCode = err?.error?.error?.message?.errfor?.errorCode;
          const message = err?.error?.error?.message?.errfor?.message;

          if (message) {
            this.showError(message);
          }

          if (errCode === 'noAccessForNonParticipants' || errCode === 'noAccessForNonVerified') {
            this.noAccess.emit();
          }

          return [];
        })
      ).subscribe();
    }
  }

  loadMorePosts(_event: Event): void {

    if (!this.canLoadMore) {
      this.disableInfiniteScroll(true);
      return;
    }

    if (!this.posts?.length) {
      return;
    }

    const start = Math.floor(this.loadedPostsCount / this.count);
    this.getPosts(start, this.count);
  }

  get canLoadMore(): boolean {

    if (!this.totalItemsCount) {
      return false;
    }

    return this.loadedPostsCount < this.totalItemsCount;
  }

  async pinPost(postId: string): Promise<void> {

    const shouldPinPost = await this.checkIfPinPostIsAllowed();

    if (!shouldPinPost) {
      return;
    }

    this.store.dispatch(new this.stateActions.PinPostsFeedItem(postId)).pipe(
      first(),
      catchError((err) => {

        const toastMessage = err?.error?.error?.message?.errfor?.message ||
          err?.error?.error?.message?.errfor?.post;

        if (toastMessage) {
          this.toast.showWithMessage(toastMessage, 'app.common.error-default', ToastMode.ERROR);
        }

        return [];
      })
    ).subscribe();
  }

  async unpinPost(postId: string): Promise<void> {

    const shouldUnpin = await this.uiUtilsService.showUnpinPostWarning();

    if (!shouldUnpin) {
      return;
    }

    this.store.dispatch(new this.stateActions.UnpinPostsFeedItem(postId)).pipe(
      first(),
      catchError((err) => {

        const toastMessage = err?.error?.error?.message?.errfor?.message ||
          err?.error?.error?.message?.errfor?.post;

        if (toastMessage) {
          this.toast.showWithMessage(toastMessage, 'app.common.error-default', ToastMode.ERROR);
        }

        return [];
      })
    ).subscribe();
  }

  private disableInfiniteScroll(value: boolean): void {
    if (this.infiniteScroll) {
      this.infiniteScroll.disabled = value;
    }
  }

  private showError(msg: string): void {
    this.toast.showWithMessage(msg, 'app.common.error-default', ToastMode.ERROR);
  }

  private async checkIfPinPostIsAllowed(): Promise<boolean> {

    if (this.pinnedPosts.length >= 3) {
      const title = await this.appTranslationService.get('app.components.PostsList.pinned-posts-limit-reached-error.title');
      const text = await this.appTranslationService.get('app.components.PostsList.pinned-posts-limit-reached-error.text');
      const buttonText = await this.appTranslationService.get('app.components.PostsList.pinned-posts-limit-reached-error.btn-confirm');

      await this.dialogs.presentAlert(title, text, [buttonText]);
      return false;
    }

    return true;
  }

  trackListItemImpression(targetImpression: TargetImpression): void {

    if (targetImpression.rootKey !== this.visibilityRootKey) {
      return;
    }

    if (!targetImpression?.targetKey?.length) {
      return;
    }

    const itemObj = this.posts.find(i => (i as any)?._id === targetImpression?.targetKey ||
      (i as any)?.id === targetImpression?.targetKey) || this.pinnedPosts.find(i => (i as any)?._id === targetImpression?.targetKey ||
        (i as any)?.id === targetImpression?.targetKey);

    if (!itemObj) {
      return;
    }

    const postOrCase = itemObj as Post;

    const impressionTrackingRequest: ImpressionTrackingRequest = {
      objectId: targetImpression?.targetKey,
      objectType: 'post',
    };

    if (this.streamTrackingId) {
      impressionTrackingRequest.source = this.streamTrackingId;
    }

    if (this.isCase(postOrCase)) {

      const objectTitle = (postOrCase as any)?.title;

      if (objectTitle) {
        impressionTrackingRequest.objectTitle = objectTitle;
      }

      (impressionTrackingRequest as SharedCaseCardImpressionTrackingParams).subObjectType = 'case';

      (impressionTrackingRequest as SharedCaseCardImpressionTrackingParams).subObjectId =
        (postOrCase as any)?.caseDiscussion?.caseId;
    }

    (impressionTrackingRequest as PostCardImpressionTrackingParams).parentType =
      postOrCase?.parentType;

    (impressionTrackingRequest as PostCardImpressionTrackingParams).parentId =
      postOrCase?.parentId;

    (impressionTrackingRequest as PostCardImpressionTrackingParams).postType =
      postOrCase?.type;

    this.trackingService.trackListItemImpression(impressionTrackingRequest);
  }

  isCase(post: Post): boolean {
    return post?.type === PostType.case;
  }
}
