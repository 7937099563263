import { Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { IonInfiniteScroll, ModalController } from '@ionic/angular';
import { CustomTab } from '../../components/custom-tabs/custom-tabs.component';

// models
import { Interest, InterestListItem } from 'src/app/services/interests.model';
import { Journal } from 'src/app/services/yeti-protocol/journal';
import { PublicProfile } from 'src/app/services/yeti-protocol/public-profile';
import { UserProfile } from 'src/app/services/yeti-protocol/auth/mi';
import { ActionSource } from 'src/app/services/yeti-protocol/tracking';

// services
import { AppNavController } from 'src/app/services/app-nav-controller.service';
import { AppTranslationService } from 'src/app/services/app-translation.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { ClinicalExpertsService } from 'src/app/services/clinical-experts.service';
import { InterestsService } from 'src/app/services/interests.service';
import { JournalsDataService } from 'src/app/services/journals/journals-data.service';
import { JournalsService } from 'src/app/services/journals/journals.service';
import { NavControllerService } from 'src/app/services/nav-controller.service';
import { ToastMode, ToastService } from 'src/app/services/toast.service';
import { CONTEXT_SERVICE, ContextService } from 'src/app/services/context/context.model';
import { TRACKING_SERVICE, TrackingService } from 'src/app/services/tracking/tracking.model';
import { CustomizeFeedService } from 'src/app/services/customize-feed.service';

export enum TabIds {
  interests = 'interests',
  journals = 'journals',
  people = 'people'
}

@Component({
  selector: 'app-customize-feed-dialog',
  templateUrl: './customize-feed.dialog.component.html',
  styleUrls: ['./customize-feed.dialog.component.scss'],
})
export class CustomizeFeedDialogComponent implements OnInit {

  @ViewChild('infiniteScrollJournals') infiniteScrollJournals: IonInfiniteScroll;
  @ViewChild('infiniteScrollPeople') infiniteScrollPeople: IonInfiniteScroll;

  @Input() tab: TabIds;

  interests: Array<InterestListItem> = new Array<InterestListItem>();
  journals: Array<Journal> = new Array<Journal>();
  people: Array<PublicProfile> = new Array<PublicProfile>();
  interestsLoading = false;
  journalsLoading = false;
  peopleLoading = false;

  contextKey: string;
  customTabs: Array<CustomTab> = [];
  activeTabId: string;
  TabIds = TabIds;
  disableInterestCheckbox = false;
  interestWereChanged = false;
  profile: UserProfile;
  ActionSource = ActionSource;

  private start = 0;
  private count = 20;
  private lastFetchLengthJournals: number;
  private lastFetchLengthPeople: number;

  constructor(
    private appNavController: AppNavController,
    private appTranslationService: AppTranslationService,
    private authService: AuthService,
    private clinicalExpertService: ClinicalExpertsService,
    @Inject(CONTEXT_SERVICE) private contextService: ContextService,
    private interestsService: InterestsService,
    private journalsDataService: JournalsDataService,
    private journalsService: JournalsService,
    private modalController: ModalController,
    private navController: NavControllerService,
    private toast: ToastService,
    @Inject(TRACKING_SERVICE) private trackingService: TrackingService,
    private customizeFeedService: CustomizeFeedService
  ) { }

  ngOnInit(): void {
    this.authService.userProfileAsObservable.subscribe(userProfile => {
      this.profile = userProfile;
      this.getInterests(this.profile?.interests);
    });
    this.contextKey = this.contextService.currentContext.key;
    this.generateTabs();

    if (this.tab) {
      this.setActiveTab(this.tab);
      this.generateTabs();
    }
  }

  onBack(): void {
    if (this.interestWereChanged) {
      this.saveInterests().then(() => {
        this.interestWereChanged = false;
        this.dismissModal();
        this.navController.back();
      });
    } else {
      this.dismissModal();
      this.navController.back();
    }

    this.customizeFeedService.triggerCustomizeFeedDialogClosed();
  }

  dismissModal(): void {
    this.modalController.dismiss();
  }

  setActiveTab(tab: CustomTab | TabIds): void {

    if (!tab) {
      return;
    }

    if ((tab as CustomTab)?.id) {
      this.activeTabId = (tab as CustomTab)?.id;
    } else {
      this.activeTabId = this.tab;
    }

    if (this.journals.length === 0 && this.activeTabId === TabIds.journals) {
      this.getJournals(this.start, false);
    } else if (this.people.length === 0 && this.activeTabId === TabIds.people) {
      this.getPeople(this.start, false);
    }
    if (this.interestWereChanged) {
      this.saveInterests().then(() => {
        this.interestWereChanged = false;
      });
    }
    this.trackCustomiseTabMenuClicked(this.activeTabId);
  }

  loadMore(): void {
    if (this.activeTabId === TabIds.journals) {
      if (this.lastFetchLengthJournals < this.count) {
        this.disableInfiniteScrollJournals(true);
        return;
      }
      const start = Math.floor(this.journals.length / this.count);
      this.getJournals(start, true)
    } else if (this.activeTabId === TabIds.people) {
      if (this.lastFetchLengthPeople < this.count) {
        this.disableInfiniteScrolPeople(true);
        return;
      }
      const start = Math.floor(this.people.length / this.count);
      this.getPeople(start, true);
    }
  }

  getInterests(profileInterests: Array<string>): void {
    this.interestsLoading = true;
    this.interestsService.getInterests(this.contextKey)
      .then((interests: Array<Interest>) => {
        this.interestsLoading = false;
        this.interests = interests.map((interest: Interest) => {
          return {
            interest,
            checked: profileInterests.indexOf(interest.id) > -1 || false
          }
        });
      });
  }

  getJournals(start: number, hideInfiniteScroll: boolean): void {
    this.journalsLoading = true;
    this.journalsService.getMixedJournals(start, this.count)
      .then((list: Array<Journal>) => {
        this.journalsLoading = false;
        this.lastFetchLengthJournals = list?.length;
        this.journals = [...this.journals, ...list];
        if (hideInfiniteScroll) {
          this.infiniteScrollJournals.complete();
        }
      });
  }

  getPeople(start: number, hideInfiniteScroll: boolean): void {
    this.peopleLoading = true;
    this.clinicalExpertService.getMixedPeople(start, this.count)
      .then((list: Array<PublicProfile>) => {
        this.peopleLoading = false;
        this.lastFetchLengthPeople = list?.length;
        this.people = [...this.people, ...list];
        if (hideInfiniteScroll) {
          this.infiniteScrollPeople.complete();
        }
      });
  }

  selectAllInterests(): void {
    this.interests.forEach((interest: InterestListItem) => {
      interest.checked = true;
    });
    this.interestWereChanged = true;
  }

  checkSelectedInterests(interest: InterestListItem): InterestListItem {
    if (interest.checked) {
      return interest;
    }
  }

  async saveInterests(): Promise<any> {
    this.authService.updateProfile(this.contextService.currentContext.key, {
      interests: this.getInterestIds()
    }, false, ActionSource.customiseFeed).then(profile => {
      this.toast.show('app.pages.StreamPage.interests-update-success-msg');
      this.profile = profile;
    }).catch(err => {
      console.error(err);
      this.toast.show('app.pages.StreamPage.interests-update-error-msg', null, ToastMode.ERROR);
    })
  }

  getInterestIdsCount(): number {
    return this.getInterestIds()?.length;
  }

  getInterestIds(): Array<string> {
    return this.interests.filter(this.checkSelectedInterests)
      .map((interestItem: InterestListItem) => {
        return interestItem?.interest?.id
      });
  }

  updateInterestsList(): void {
    this.interestWereChanged = true;
  }

  onOpenPublicProfile(userId: string): void {
    this.dismissModal();
    this.appNavController.openPublicUserProfile(userId, ActionSource.customiseFeed);
  }

  onOpenJournal(journalId: string): void {
    this.dismissModal();
    this.appNavController.openJournal(journalId);
  }

  onToggleJournalFollowing(journal: Journal): void {
    this.journalsService.followUnfollowJournal(journal.id, !journal.isFollowed, ActionSource.customiseFeed).then(success => {
      if (success) {
        journal.isFollowed = !journal.isFollowed;
        this.journalsDataService.triggerFollowUnfollowJournalAction(journal);
      }
    });
  }

  private generateTabs(): void {

    if (!this.activeTabId) {
      this.activeTabId = TabIds.interests;
    }

    this.customTabs = [{
      id: TabIds.interests,
      text: this.appTranslationService.instant('app.pages.StreamPage.interests'),
      active: this.activeTabId === TabIds.interests
    },
    {
      id: TabIds.journals,
      text: this.appTranslationService.instant('app.pages.StreamPage.journals'),
      active: this.activeTabId === TabIds.journals
    },
    {
      id: TabIds.people,
      text: this.appTranslationService.instant('app.pages.StreamPage.people'),
      active: this.activeTabId === TabIds.people
    }];
  }

  private disableInfiniteScrollJournals(value: boolean): void {
    if (this.infiniteScrollJournals) {
      this.infiniteScrollJournals.disabled = value;
    }
  }

  private disableInfiniteScrolPeople(value: boolean): void {
    if (this.infiniteScrollPeople) {
      this.infiniteScrollPeople.disabled = value;
    }
  }

  trackCustomiseTabMenuClicked(menuItem: string): void {
    if (menuItem) {
      this.trackingService.trackGenericClickedAction(menuItem, 'customiseFeed', 'customiseFeedTabMenu')
        .catch(_err => {
          console.error('Could not track navigation tab clicked/tapped action.');
        });
    }
  }
}
