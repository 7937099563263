// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  --multiline-text-with-ellipsis-width: 100%;
  --multiline-text-with-ellipsis-height: 100%;
  --multiline-text-with-ellipsis-max-width: 100%;
  --multiline-text-with-ellipsis-max-height: 100%;
}

.multiline-text-with-ellipsis-container {
  width: var(--multiline-text-with-ellipsis-width);
  height: var(--multiline-text-with-ellipsis-height);
  max-width: var(--multiline-text-with-ellipsis-max-width);
  max-height: var(--multiline-text-with-ellipsis-max-height);
}
.multiline-text-with-ellipsis-container .multiline-text-container {
  width: 100%;
  height: 100%;
  max-height: var(--multiline-text-with-ellipsis-max-height);
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  overflow: hidden;
  opacity: 1;
  transition: all 0.3s ease;
}
.multiline-text-with-ellipsis-container .multiline-text-container.hide-text {
  opacity: 0;
}
.multiline-text-with-ellipsis-container .multiline-text-container .text {
  margin: 0;
  display: inline;
}
.multiline-text-with-ellipsis-container .multiline-text-container app-copy-button {
  position: absolute;
  padding: 5px 0px 0px 3px;
}
.multiline-text-with-ellipsis-container .multiline-text-container.display-copy-icon {
  display: block;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
