import {Component, OnInit, ViewChild} from '@angular/core';
import {ClinicalExpert} from '../../services/yeti-protocol/clinical-expert';
import {ClinicalExpertsService} from '../../services/clinical-experts.service';
import {IonInfiniteScroll, ModalController} from '@ionic/angular';
import { AppNavController } from 'src/app/services/app-nav-controller.service';
import { ActionSource } from 'src/app/services/yeti-protocol/tracking';

@Component({
  selector: 'app-recommended-people-dialog',
  templateUrl: './recommended-people.dialog.component.html',
  styleUrls: ['./recommended-people.dialog.component.scss'],
})
export class RecommendedPeopleDialogComponent implements OnInit {

  @ViewChild(IonInfiniteScroll) infiniteScroll: IonInfiniteScroll;

  recommendedPeopleToFollowList: Array<ClinicalExpert> = new Array<ClinicalExpert>();
  private readonly start = 0;
  private readonly count = 15;
  private lastFetchLength: number;
  ActionSource = ActionSource;

  constructor(
    private clinicalExpertsService: ClinicalExpertsService,
    private modalController: ModalController,
    private appNavController: AppNavController
  ) { }

  ngOnInit(): void {
    this.getRecommendedPeople(this.start, false);
  }

  loadMoreRecommendedPeople(_event: Event): void {
    if(this.lastFetchLength < this.count) {
      this.disableInfiniteScroll(true);
      return;
    }
    const start = Math.floor(this.recommendedPeopleToFollowList.length / this.count);
    this.getRecommendedPeople(start, true);
  }

  onClose(): void {
    this.modalController.dismiss();
  }

  onOpenPublicUserProfile(userId: string): void {
    this.modalController.dismiss();
    this.appNavController.openPublicUserProfile(userId);
  }

  private getRecommendedPeople(start: number, hideInfiniteScroll: boolean): void {
    this.clinicalExpertsService.getRecommendedPeopleToFollow(start, this.count)
      .then((responseArray: Array<ClinicalExpert>) => {
        this.lastFetchLength = responseArray.length;
        this.recommendedPeopleToFollowList = [...this.recommendedPeopleToFollowList, ...responseArray];
        if(hideInfiniteScroll) {
          this.infiniteScroll.complete();
        }
      });
  }

  private disableInfiniteScroll(value: boolean): void {
    if (this.infiniteScroll) {
      this.infiniteScroll.disabled = value;
    }
  }
}
