import { InjectionToken } from '@angular/core';
import { DedicatedSearchDialogConfig } from 'src/app/dialogs/dedicated-search-dialog/dedicated-search-dialog.model';
import { UserProfile } from '../yeti-protocol/auth/mi';
import { AOEventParticipantType } from '../yeti-protocol/event';
import { SafeHtml } from '@angular/platform-browser';
import { ShortPublicProfile } from '../yeti-protocol/public-profile';
import { RecommendedPeopleStrategy } from '../recommended-people/recommended-people';

export interface UserInfo {
  firstName: string;
  lastName: string;
  userId: string;
}

export interface AoEventParticipantsDialogResult {
  action: 'sendMessageTo' | 'openPublicUserProfile' | null;
  data?: string;
}

export interface ConfirmDialogData {
  componentProps: ConfirmDialogComponentProps;
}

export interface ConfirmDialogComponentProps {
  image?: string;
  title: ConfirmDialogTextField;
  message: ConfirmDialogTextField;
  displayConfirmInput?: boolean;
  actions: Array<ConfirmDialogAction>;
}

export interface ConfirmDialogAction {
  key: string;
  label: ConfirmDialogTextField;
  className?: string;
}

export interface ConfirmDialogTextField {
  text?: string;
  translationKey?: string;
  safeHTML?: SafeHtml;
}

export interface FieldMaxTextLengthExceeded {
  fieldName: string;
  maxTextLength: number;
}


export interface ContextDialogsUI {
  // returns true if request was sent
  showSendNewConnectionRequestDialog(info: UserInfo, source?: string): Promise<boolean>;
  showRecommendedPeople(): Promise<void>;
  showCoparticipantsListDialog(
    fn: (fn) => void,
    titleTranslationKey: string,
    recommendedPeopleStrategy: RecommendedPeopleStrategy,
    showXButton?: boolean,
    checkVisibility?: boolean,
    source?: string,
    clickTrackingObjId?: string): void;
  showRecommendedJournalsDialog(): Promise<void>;
  showAoEventParticipantsDialog(eventId: string, participantType: AOEventParticipantType): Promise<AoEventParticipantsDialogResult>;
  inviteGroupParticipants(fn: (fn) => void, groupId: string, groupVisibility: string, userProfile: UserProfile): void;
  editCurrentUserProfile(): Promise<UserProfile>;
  showArticleRecommends(articleId: string): Promise<void>;
  showMyAoAuthors(myAoAuthors: Array<ShortPublicProfile>): Promise<void>;
  showUserSettings(): Promise<boolean>;
  showSelectLanguageDialog(): Promise<void>;
  showApplaudsList(objectId: string, objectType: 'post' | 'case' | 'comment' | 'orcid' | 'article'): Promise<void>;
  showOrcIdArticleApplaudsList(orcIdArticleId: string): Promise<void>;
  showDedicatedSearchDialog(dedicatedSearchDialogConfig: DedicatedSearchDialogConfig): Promise<void>;
  showClinicalCaseSharedWith(caseId: string): Promise<any>;
}

export const CONTEXT_DIALOGS_UI = new InjectionToken<ContextDialogsUI>('context-dialogs-ui.service');
