// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.suggested-articles-card {
  width: 100%;
  height: 100%;
}
.suggested-articles-card app-mi-card {
  --mi-card-margin-top: 0;
  --mi-card-margin-bottom: 20px;
  --mi-card-margin-start: 15px;
  --mi-card-margin-end: 15px;
  --mi-card-padding-top: 15px;
  --mi-card-padding-bottom: 15px;
  --mi-card-padding-start: 15px;
  --mi-card-padding-end: 15px;
}
.suggested-articles-card app-mi-card ::ng-deep ion-card .card-title-container {
  margin-bottom: 12px;
}
.suggested-articles-card app-mi-card ::ng-deep ion-card .card-title-container .card-title {
  font-weight: var(--font-weight-medium);
  font-style: normal;
  font-size: var(--font-size-large);
  line-height: var(--font-line-height-large);
  color: var(--mi-secondary-color-2);
  margin: 0;
  text-align: left;
}
.suggested-articles-card app-mi-card ::ng-deep ion-card .suggested-articles-list-container {
  display: grid;
  grid-template-columns: 100%;
  grid-auto-flow: row;
  grid-auto-rows: min-content;
  row-gap: 13px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
