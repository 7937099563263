import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule, ModalController } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { CreateContentStrategy } from 'src/app/services/create-content/create-content';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/services/auth/auth.service';
import { UserProfile } from 'src/app/services/yeti-protocol/auth/mi';
import { UsersModule } from 'src/app/modules/users/users.module';
import { MIComponentsModule } from '../components.module';
import {
  CreateContentParentOptionsComponent,
  SelectableParent
} from '../create-content-parent-options/create-content-parent-options.component';
import { InfoSheetService } from 'src/app/modules/info-sheet/services/info-sheet.service';
import { InfoSheetModule } from 'src/app/modules/info-sheet/info-sheet.module';
import { ResponsiveUtilsService } from 'src/app/services/utils/responsive-utils.service';
import { CreateContentParentSelectDialogComponent }
  from '../../dialogs/create-content-parent-select-dialog/create-content-parent-select-dialog.component';
import { ParentType } from 'src/app/services/yeti-protocol/chatter-api';
import { GroupListItem } from 'src/app/services/groups/group.model';
import { AOEvent } from 'src/app/services/yeti-protocol/event';
import { AppTranslationService } from 'src/app/services/app-translation.service';

@Component({
  selector: 'app-create-content-parent-select',
  templateUrl: './create-content-parent-select.component.html',
  styleUrls: ['./create-content-parent-select.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    IonicModule,
    TranslateModule,
    UsersModule,
    MIComponentsModule,
    CreateContentParentOptionsComponent,
    InfoSheetModule,
    CreateContentParentSelectDialogComponent
  ]
})
export class CreateContentParentSelectComponent implements OnInit, OnDestroy {

  @Input() createContentStrategy: CreateContentStrategy;
  @Input() disableSelect: boolean;

  userProfile: UserProfile;
  infoSheetId = 'create-content-parent-select-info-sheet-id';
  selectedParent: SelectableParent;
  SelectableParent = SelectableParent;

  private userProfileSubscription: Subscription;

  constructor(
    private authService: AuthService,
    private infoSheetService: InfoSheetService,
    private responsiveUtilsService: ResponsiveUtilsService,
    private modalController: ModalController,
    private appTranslationService: AppTranslationService
  ) { }

  ngOnInit(): void {
    this.userProfileSubscription = this.authService.userProfileAsObservable.subscribe(userProfile => {
      this.userProfile = userProfile;
    });

    this.initialParentSelect();
  }

  ngOnDestroy(): void {
    this.userProfileSubscription?.unsubscribe();
  }

  showParentSelect(): void {

    if (this.disableSelect) {
      return;
    }

    if (this.isDesktop) {
      this.showCreateContentParentSelectionDialog();
    } else {
      this.openInfoSheetCreateContentParentSelection();
    }
  }

  openInfoSheetCreateContentParentSelection(): void {
    this.infoSheetService.open(this.infoSheetId);
  }

  async showCreateContentParentSelectionDialog(): Promise<void> {

    const dialog = await this.modalController.create({
      component: CreateContentParentSelectDialogComponent,
      cssClass: 'create-content-parent-select-dialog',
      componentProps: {
        createContentStrategy: this.createContentStrategy,
        selectedParent: this.selectedParent
      }
    });

    await dialog.present();

    dialog.onDidDismiss().then(res => {
      if (res) {
        this.selectedParent = res.data;
      }
    });
  }

  get isDesktop(): boolean {
    return this.responsiveUtilsService.isDesktop;
  }

  get selectedGroupsEventsText(): string {
    if (!this.selectedParent || this.selectedParent !== SelectableParent.GROUPS_EVENTS) {
      return '';
    }

    if (this.createContentStrategy?.parentsData?.length === 1) {
      switch (this.createContentStrategy?.parentsData?.[0].parentsType) {
        case ParentType.GROUP:
          if (this.createContentStrategy?.parentsData?.[0].parents.length === 1) {
            return (this.createContentStrategy?.parentsData?.[0].parents?.[0] as GroupListItem)?.title;
          } else {
            const groupsTranslatedText = this.appTranslationService.instant('app.component.CreateContentParentSelect.groups');
            return `${this.createContentStrategy?.parentsData?.[0].parents?.length} ${groupsTranslatedText}`;
          }
        case ParentType.EVENT:
          if (this.createContentStrategy?.parentsData?.[0].parents.length === 1) {
            return (this.createContentStrategy?.parentsData?.[0].parents?.[0] as AOEvent)?.text;
          } else {
            const eventsTranslatedText = this.appTranslationService.instant('app.component.CreateContentParentSelect.events');
            return `${this.createContentStrategy?.parentsData?.[0].parents?.length} ${eventsTranslatedText}`;
          }
      }
    } else {
      let count = 0;

      this.createContentStrategy?.parentsData?.forEach(parentData => {
        count += parentData?.parents?.length;
      });

      const groupsAndEventsTranslatedText = this.appTranslationService.instant('app.component.CreateContentParentSelect.groups-and-events');
      return `${count} ${groupsAndEventsTranslatedText}`;
    }
  }

  initialParentSelect(): void {
    if (!this.selectedParent && this.createContentStrategy?.parentsData?.length) {
      if (this.createContentStrategy?.parentsData?.length === 1) {
        switch (this.createContentStrategy?.parentsData?.[0]?.parentsType) {
          case ParentType.CHAT:
            this.selectedParent = SelectableParent.PRIVATE;
            break;
          case ParentType.USER:
            this.selectedParent = SelectableParent.PUBLIC;
            break;
          case ParentType.GROUP:
          case ParentType.EVENT:
            this.selectedParent = SelectableParent.GROUPS_EVENTS;
            break;
        }
      } else {
        this.selectedParent = SelectableParent.GROUPS_EVENTS;
      }
    }
  }

}
