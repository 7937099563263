import { NgModule } from '@angular/core';

// modules
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';

// components
import { UserProfileImageBubblesComponent } from './components/user-profile-image-bubbles/user-profile-image-bubbles.component';
import { UserProfileImageComponent } from './components/user-profile-image/user-profile-image.component';
import { AoMemberSymbolComponent } from './components/ao-member-symbol/ao-member-symbol.component';
import { IconModule } from '../icon/icon.module';
import {
  UserProfileImageUploadComponent
} from './components/user-profile-image-upload/user-profile-image-upload.component';
import {FileSelectModule} from '../file-select/file-select.module';
import {ImageEditorModule} from '../image-editor/image-editor.module';


@NgModule({
  declarations: [
    UserProfileImageComponent,
    UserProfileImageBubblesComponent,
    AoMemberSymbolComponent,
    UserProfileImageUploadComponent
  ],
  exports: [
    UserProfileImageComponent,
    UserProfileImageBubblesComponent,
    AoMemberSymbolComponent,
    UserProfileImageUploadComponent
  ],
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        TranslateModule,
        IconModule,
        FileSelectModule,
        ImageEditorModule
    ]
})
export class UsersModule { }
