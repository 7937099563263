import { Component, Inject, Input } from '@angular/core';
import { Clipboard } from '@awesome-cordova-plugins/clipboard/ngx';

import { Group } from 'src/app/services/groups/group.model';
import { Platform } from 'src/config/config.model';
import { isMobilePlatform } from 'src/app/services/utils/utils';

import { SharingUIService } from 'src/app/services/sharing/sharing-ui.service';
import { CONTEXT_SERVICE, ContextService } from 'src/app/services/context/context.model';

import appConfig from 'src/config/config';

interface ShareGroupCardComponentConfig {
  platform: Platform
}

@Component({
  selector: 'app-share-group-card',
  templateUrl: './share-group-card.component.html',
  styleUrls: ['./share-group-card.component.scss'],
})
export class ShareGroupCardComponent {

  @Input() group: Group;
  config: ShareGroupCardComponentConfig = appConfig;

  constructor(
    private sharingUIService: SharingUIService,
    @Inject(CONTEXT_SERVICE) private contextService: ContextService,
    private clipboard: Clipboard
  ) {}

  copyGroupLink(): void {
    if (isMobilePlatform(this.config.platform)) {
      this.clipboard.copy(this.shareableLink);
    } else {
      navigator.clipboard.writeText(this.shareableLink);
    }
  }

  shareGroup(): void {
    this.sharingUIService.showShareDialogForObject(this.group);
  }

  get shareableLink(): string {
    return `${appConfig.webUrl}${this.contextService.currentContext.key}/group/${this.group?._id}?source=shared`;
  }
}
