import {ChangeDetectorRef, Component, EventEmitter, Inject, Input, Output, ViewChild} from '@angular/core';
import { AppNavController } from 'src/app/services/app-nav-controller.service';

// services
import { UIUtilsServiceInterface, UI_UTILS_SERVICE } from 'src/app/services/utils/ui-utils.service.interface';
import {UserProfileImageUploadComponent} from '../user-profile-image-upload/user-profile-image-upload.component';

@Component({
  selector: 'app-user-profile-image',
  templateUrl: './user-profile-image.component.html',
  styleUrls: ['./user-profile-image.component.scss'],
})
export class UserProfileImageComponent {

  @Input() userImageUrl: string;
  @Input() userId: string;
  @Input() aoMember: boolean;
  @Input() isCustomOpenPublicProfile = false;
  @Input() showChangeProfileImageButton = false;

  @Output() openPublicProfile: EventEmitter<string> = new EventEmitter();
  @Output() profileImageClicked: EventEmitter<void> = new EventEmitter();
  @ViewChild('userProfileImageUpload') userProfileImageUploadComponent: UserProfileImageUploadComponent;

  showDefaultUserProfileImage: boolean;
  imageLoaded = false;

  constructor(
    private appNavController: AppNavController,
    @Inject(UI_UTILS_SERVICE) private uiUtilsService: UIUtilsServiceInterface,
    public changeDetectorRef: ChangeDetectorRef // do not remove, used from rich text document
  ) { }

  onClick(event: Event): void {
    this.uiUtilsService.stopEventPropagation(event);
    event.stopPropagation();
    if (this.showChangeProfileImageButton) {
      this.userProfileImageUploadComponent.onChangeProfileImageClick();
    } else {
      this.openUserProfile();
    }
    this.profileImageClicked.emit();
  }

  openUserProfile(): void {

    if (!this.userId) {
      return;
    }

    if(this.isCustomOpenPublicProfile){
      return this.openPublicProfile.emit(this.userId);
    }
    this.appNavController.openPublicUserProfile(this.userId);
  }
}
