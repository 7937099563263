import { Component, Inject, Input } from '@angular/core';
import { Router } from '@angular/router';

// services
import { ResponsiveUtilsService } from 'src/app/services/utils/responsive-utils.service';
import { ContextDialogsUI, CONTEXT_DIALOGS_UI } from 'src/app/services/dialogs/dialogs.ui.interface';
import { CONTEXT_SERVICE, ContextService } from 'src/app/services/context/context.model';
import { ActionSource } from 'src/app/services/yeti-protocol/tracking';

@Component({
  selector: 'app-empty-state-items-list',
  templateUrl: './empty-state-items-list.component.html',
  styleUrls: ['./empty-state-items-list.component.scss'],
})
export class EmptyStateItemsListComponent {
  @Input() source: ActionSource;

  constructor(
    private router: Router,
    @Inject(CONTEXT_SERVICE) private contextService: ContextService,
    private responsiveUtilsService: ResponsiveUtilsService,
    @Inject(CONTEXT_DIALOGS_UI) private dialogs: ContextDialogsUI
  ) { }

  async openRecommendedGroups(): Promise<void> {
    await this.router.navigateByUrl([this.contextService.currentContext.homePath,
      `groups?tab=discover&source=${this.source}`].join('/'));
  }

  async openRecommendedPeople(): Promise<void> {
    if (this.isDesktop) {
      this.dialogs.showRecommendedPeople();
    } else {
      this.router.navigateByUrl(`${this.contextService.currentContext.homePath}/recommended-people`);
    }
  }

  get isDesktop(): boolean {
    return this.responsiveUtilsService.isDesktop;
  }
}
