import { Component, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { InfoSheetService } from 'src/app/modules/info-sheet/services/info-sheet.service';

// models
import { Group, GroupListItem } from 'src/app/services/groups/group.model';
import { GroupsService } from 'src/app/services/groups/groups.service';
import { DomUtilsService } from 'src/app/services/utils/dom-utils.service';
import { ResponsiveUtilsService } from 'src/app/services/utils/responsive-utils.service';
import { LastActiveGroupsSuccessResponse } from 'src/app/services/yeti-protocol/chatter-api';

@Component({
  selector: 'app-create-group-content-group-select',
  templateUrl: './create-group-content-group-select.component.html',
  styleUrls: ['./create-group-content-group-select.component.scss'],
})
export class CreateGroupContentGroupSelectComponent implements OnChanges {

  @Input() group: Group;

  @Output() groupSelected: EventEmitter<GroupListItem> = new EventEmitter();
  @Output() groupSelectionOpened: EventEmitter<void> = new EventEmitter();

  groups: Array<GroupListItem> = [];
  newSelectedGroup: Group;
  infoSheetNewSelectedGroup: Group;
  showDefaultGroupImage: boolean;
  groupsCount = 10;
  totalGroupsCount: number;
  infoSheetId = 'create-group-content-group-select-info-sheet-id';

  constructor(
    private el: ElementRef,
    private groupsService: GroupsService,
    private infoSheetService: InfoSheetService,
    private domUtils: DomUtilsService,
    public responsiveUtilsService: ResponsiveUtilsService
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      this.newSelectedGroup &&
      changes?.group &&
      changes?.group?.currentValue &&
      changes?.group?.currentValue?._id === this.newSelectedGroup?._id) {
      this.group = null;
    }
  }

  get noSelectedGroup(): boolean {
    return !this.group && !this.newSelectedGroup;
  }

  onSelectGroup(): void {

    if (this.group) {
      return;
    }

    if (!this.groups?.length) {
      this.fetchGroups();
    }

    if (this.responsiveUtilsService.isDesktop) {
      const el = this.el.nativeElement.querySelector('.groups-select');
      el?.click();
    } else {
      this.onOpenInfoSheetGroupSelection();
    }

    this.setGroupsListScrollListener();
    this.groupSelectionOpened.emit();
  }

  fetchGroups(): void {

    if (this.groups.length >= this.totalGroupsCount) {
      return;
    }

    const start = Math.floor(this.groups.length / this.groupsCount);

    this.groupsService.getLastActiveGroups(start, this.groupsCount).then((lastActiveGroupsResponse: LastActiveGroupsSuccessResponse) => {
      this.totalGroupsCount = lastActiveGroupsResponse.totalItemsCount;
      this.groups = [...this.groups, ...lastActiveGroupsResponse.result];
    }).catch(err => {
      console.error(err);
    });
  }

  setGroupsListScrollListener(): void {
    setTimeout(() => {
      const groupsListDropdownEl = this.domUtils.querySelector('.groups-select-popover > .popover-wrapper > .popover-content');
      const groupsListInfoSheetEl = this.domUtils.querySelector('.groups-list-info-sheet')?.parentElement;
      const groupsListEl = groupsListDropdownEl || groupsListInfoSheetEl;

      if (groupsListEl) {
        groupsListEl.addEventListener('scroll', this.groupsListScrollListener);
      }

    }, 200);
  }

  groupsListScrollListener = (event: Event): void => {
    const target = event.target as HTMLElement;
    const targetHeight = target?.clientHeight > -1 ? target?.clientHeight : 0;
    const targetScrollTop = target?.scrollTop > -1 ? target?.scrollTop : 0;
    const scrollingElementHeight =
      (target?.firstChild as HTMLElement)?.clientHeight > -1 ? (target?.firstChild as HTMLElement)?.clientHeight : 0;

    if (scrollingElementHeight - targetHeight === targetScrollTop) {
      this.loadMoreGroups();
    }
  }

  loadMoreGroups(): void {
    this.fetchGroups();
  }

  onNewGroupSelected(): void {
    this.groupSelected.emit(this.newSelectedGroup);
  }

  onInfoSheetNewGroupSelected(): void {
    this.newSelectedGroup = this.infoSheetNewSelectedGroup;
    this.infoSheetService?.close(this.infoSheetId);
    this.onNewGroupSelected();
  }

  onOpenInfoSheetGroupSelection(): void {
    this.infoSheetService.open(this.infoSheetId);
  }
}

