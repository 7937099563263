export const CLINICAL_CASES_STATE_KEY = 'clinicalCases';
export const CLINICAL_CASES_ACCESS_REQUESTS_STATE_KEY = 'clinicalCasesAccessRequests';
export const PENDING_CONNECTION_REQUESTS_STATE_KEY = 'pendingConnectionRequests';
export const CO_CONTRIBUTORS_STATE_KEY = 'coContributors';
export const ACTIVE_CONVERSATIONS_STATE_KEY = 'activeConversations';
export const GROUPS_FEED_STATE_KEY = 'groupsFeed';
export const GROUP_FEED_STATE_KEY = 'groupFeed';
export const POSTS_FEED_STATE_KEY = 'postsFeed';
export const EVENT_FEED_WITH_PARTICIPANTS_STATE_KEY = 'eventFeedWithParticipants';
export const EVENT_FEED_FACULTY_ONLY_STATE_KEY = 'eventFeedFacultyOnly';
export const POSTS_STATE_STATE_KEY = 'posts';
export const MY_NETWORK_STREAM_STATE_KEY = 'myNetworkStream';
export const MY_KNOWLEDGE_STREAM_STATE_KEY = 'myKnowledgeStream';
export const ACTIVITIES_STATE_KEY = 'activities';
export const SAVED_KNOWLEDGE_STATE_KEY = 'savedKnowledge';
export const PEOPLE_YOU_MAY_KNOW_STATE_KEY = 'peopleYouMayKnow';
