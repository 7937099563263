import { Component, Input, OnInit } from '@angular/core';
import { ShortPublicProfile } from '../../services/yeti-protocol/public-profile';

@Component({
  selector: 'app-orcid-article-card-header',
  templateUrl: './orcid-article-card-header.component.html',
  styleUrls: ['./orcid-article-card-header.component.scss'],
})
export class OrcidArticleCardHeaderComponent  implements OnInit {

  @Input() orcIdArticleOwner: ShortPublicProfile;

  constructor() { }

  ngOnInit(): void {}

}
