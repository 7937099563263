import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { AoEventParticipantsDialogComponent } from 'src/app/dialogs/ao-event-participants-dialog/ao-event-participants-dialog.component';
import { ArticleRecommendsListComponent } from 'src/app/dialogs/article-recommends-list/article-recommends-list.component';
import { CoparticipantsListDialogComponent } from 'src/app/dialogs/coparticipants-list/coparticipants-list.dialog.component';
import { GroupInvitationsComponent } from 'src/app/dialogs/group-invitations/group-invitations.component';
import {
  SendNewConnectionRequestDialogComponent
} from 'src/app/dialogs/messenger/send-new-connection-request-dialog/send-new-connection-request-dialog.component';
import { RecommendedJournalsDialogComponent } from 'src/app/dialogs/recommended-journals/recommended-journals.dialog.component';
import { RecommendedPeopleDialogComponent } from 'src/app/dialogs/recommended-people/recommended-people.dialog.component';
import { UserProfileSettingsComponent } from 'src/app/dialogs/user-profile-settings/user-profile-settings.component';
import { UserSettingsComponent } from 'src/app/dialogs/user-settings/user-settings.component';
import { UserProfile } from '../yeti-protocol/auth/mi';
import { AOEventParticipantType } from '../yeti-protocol/event';
import { AoEventParticipantsDialogResult, ContextDialogsUI, UserInfo, ConfirmDialogData } from './dialogs.ui.interface';
import { DialogsUIService } from './dialogs.ui.service';
import {
  DedicatedSearchDialogComponent
} from 'src/app/dialogs/dedicated-search-dialog/dedicated-search-dialog.component';
import {
  ClinicalCaseSharedWithDialogComponent
} from 'src/app/dialogs/clinical-case-shared-with-dialog/clinical-case-shared-with-dialog.component';
import { DedicatedSearchDialogConfig } from 'src/app/dialogs/dedicated-search-dialog/dedicated-search-dialog.model';
import { MyAoAuthorsDialogComponent } from 'src/app/dialogs/myao-authors-dialog/myao-authors-dialog.component';
import { ShortPublicProfile } from '../yeti-protocol/public-profile';
import { RecommendedPeopleStrategy } from '../recommended-people/recommended-people';
import {SeeAllApplaudsDialogComponent} from '../../dialogs/see-all-applauds-dialog/see-all-applauds-dialog.component';

@Injectable()
export class ContextDialogsUIService implements ContextDialogsUI {
  constructor(
    private modalController: ModalController,
    private dialogs: DialogsUIService
  ) { }

  // returns true if request was sent
  showSendNewConnectionRequestDialog(info: UserInfo, source?: string): Promise<boolean> {
    return this.modalController.create({
      component: SendNewConnectionRequestDialogComponent,
      componentProps: {
        firstName: info.firstName,
        lastName: info.lastName,
        userId: info.userId,
        source: source
      },
      cssClass: 'send-new-connection-request-dialog'
    }).then(dialog => {
      return new Promise((resolve, reject) => {
        dialog.onDidDismiss().then(res => {
          if (res && res?.data) {
            return resolve(true);
          }
          return resolve(false);
        }).catch(err => {
          reject(err);
        });

        dialog.present().catch(err => {
          reject(err);
        });
      })
    });
  }

  // context specific
  showRecommendedPeople(): Promise<void> {
    return this.modalController.create({
      component: RecommendedPeopleDialogComponent,
      cssClass: 'group-participants-dialog',
    }).then(dialog => {
      dialog.present();
    });
  }

  // context specific
  showCoparticipantsListDialog(fn: (fn) => void,
    titleTranslationKey: string,
    recommendedPeopleStrategy: RecommendedPeopleStrategy,
    showXButton?: boolean,
    checkVisibility?: boolean,
    source?: string,
    clickTrackingObjId?: string): void {
    const logic = function (modalController: ModalController) {
      return modalController.create({
        component: CoparticipantsListDialogComponent,
        cssClass: 'coparticipants-dialog',
        componentProps: {
          titleTranslationKey: titleTranslationKey,
          recommendedPeopleStrategy: recommendedPeopleStrategy,
          showXButton: showXButton,
          checkVisibility: checkVisibility,
          source: source,
          clickTrackingObjId: clickTrackingObjId
        }
      }).then(dialog => {
        dialog.present();
      });
    }
    fn(logic);
  }

  // context dialogs
  showRecommendedJournalsDialog(): Promise<void> {
    return this.modalController.create({
      component: RecommendedJournalsDialogComponent,
      cssClass: 'group-participants-dialog',
    }).then(dialog => {
      dialog.present();
    });
  }

  showAoEventParticipantsDialog(eventId: string, participantType: AOEventParticipantType): Promise<AoEventParticipantsDialogResult> {
    return this.modalController.create({
      component: AoEventParticipantsDialogComponent,
      cssClass: 'ao-event-participants-dialog',
      componentProps: {
        eventId: eventId,
        aoEventParticipantType: participantType
      }
    }).then(dialog => {
      return new Promise((resolve, reject) => {
        dialog.onDidDismiss().then(res => {
          if (res.data) {
            if (res.data.sendMessageTo) {
              return resolve({
                action: 'sendMessageTo',
                data: res.data.sendMessageTo
              });
            }
            if (res.data.openPublicUserProfile) {
              return resolve({
                action: 'openPublicUserProfile',
                data: res.data.openPublicUserProfile
              });
            }
          }
          resolve({ action: null });
        });
        dialog.present()
          .catch(err => {
            reject(err);
          });
      })
    });
  }

  inviteGroupParticipants(fn: (fn) => void, groupId: string, groupVisibility: string, userProfile: UserProfile): void {
    const logic = function (modalController: ModalController) {
      return modalController.create({
        component: GroupInvitationsComponent,
        cssClass: 'group-invitations-dialog',
        componentProps: {
          groupId,
          groupVisibility,
          userProfile
        }
      }).then(dialog => {
        dialog.present();
      });
    };
    fn(logic);
  }

  editCurrentUserProfile(): Promise<UserProfile> {
    return this.modalController.create({
      component: UserSettingsComponent,
      cssClass: 'user-profile-dialog'
    }).then(dialog => {
      return Promise.all([
        dialog,
        dialog.present()
      ]);
    }).then(res => {
      const [dialog] = res;
      return dialog.onDidDismiss()
    }).then(res => {
      return res?.data;
    });
  }

  showArticleRecommends(articleId: string): Promise<void> {
    return this.modalController.create({
      component: ArticleRecommendsListComponent,
      cssClass: 'article-recommends-dialog',
      componentProps: {
        articleId
      }
    }).then(dialog => {
      return dialog.present();
    });
  }

  showMyAoAuthors(myAoAuthors: Array<ShortPublicProfile>): Promise<void> {
    return this.modalController.create({
      component: MyAoAuthorsDialogComponent,
      cssClass: 'myao-authors-dialog',
      componentProps: {
        myAoAuthors: myAoAuthors
      }
    }).then(dialog => {
      return dialog.present();
    });
  }

  // returns true if logout is requested
  showUserSettings(): Promise<boolean> {
    return this.modalController.create({
      component: UserProfileSettingsComponent,
      cssClass: 'user-profile-settings-dialog',
      componentProps: {}
    }).then(dialog => {
      return new Promise((resolve, reject) => {
        dialog.onDidDismiss().then(res => {
          if (res.data && res.data.signOut === true) {
            return resolve(true);
          }
          resolve(false);
        });
        dialog.present().catch(err => {
          reject(err);
        });
      });
    });
  }

  showSelectLanguageDialog(): Promise<void> {
    const acceptActionKey = 'yes';
    const goBackActionKey = 'no';

    const confirmSelectLanguage: ConfirmDialogData = {
      componentProps: {
        title: {
          translationKey: 'app.dialogs.confirm.select-translation-language-title'
        },
        message: {
          translationKey: 'app.dialogs.confirm.select-translation-language-message'
        },
        actions: [
          {
            key: goBackActionKey,
            label: {
              translationKey: 'app.common.no'
            },
            className: 'secondary'
          },
          {
            key: acceptActionKey,
            label: {
              translationKey: 'app.common.yes'
            },
            className: 'primary'
          }
        ]

      }
    }

    return this.dialogs.createConfirmDialog(confirmSelectLanguage)
      .then(dlg => {
        dlg.onDidDismiss()
          .then(res => {
            if (res && res?.data.actionKey === acceptActionKey) {
              this.showUserSettings();
            }
          }).catch(err => {
            console.log(err);
          })
        dlg.present();
      });
  }

  async showApplaudsList(objectId: string, objectType: 'post' | 'case' | 'comment' | 'orcid' | 'article'): Promise<void> {
    const dialog = await this.modalController.create({
      component: SeeAllApplaudsDialogComponent,
      cssClass: 'post-likes-dialog',
      componentProps: { objectId, objectType }
    });
    await dialog.present();
  }

  async showOrcIdArticleApplaudsList(orcIdArticleId: string): Promise<void> {
    await this.showApplaudsList(orcIdArticleId, 'orcid');
  }

  async showDedicatedSearchDialog(dedicatedSearchDialogConfig: DedicatedSearchDialogConfig): Promise<void> {

    const dialog = await this.modalController.create({
      component: DedicatedSearchDialogComponent,
      cssClass: 'dedicated-search-dialog',
      componentProps: {
        ...dedicatedSearchDialogConfig
      },
      backdropDismiss: true
    });

    dialog.present();

    try {
      await dialog.onDidDismiss();
    } catch (err) {
      console.error(err);
    }
  }

  async showClinicalCaseSharedWith(caseId: string): Promise<any> {
    const dialog = await this.modalController.create({
      component: ClinicalCaseSharedWithDialogComponent,
      cssClass: 'clinical-case-shared-with-dialog',
      componentProps: {
        caseId: caseId
      }
    });

    dialog.present();
    return dialog.onDidDismiss();
  }
}
