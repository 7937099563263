import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';

import { TranslateModule } from '@ngx-translate/core';
import { NgSelectModule } from '@ng-select/ng-select';

import { MIComponentsModule } from '../components/components.module';
import { MIPipesModule } from '../pipes/pipes.module';
import { IconButtonModule } from '../modules/icon-button/icon-button.module';
import { EventsModule } from '../modules/events/events.module';
import { InfoSheetModule } from '../modules/info-sheet/info-sheet.module';
import { PdfViewerModule } from 'ng2-pdf-viewer';


import { SettingsInterestsComponent } from './settings-interests/settings-interests.component';
import { SettingsTranslationLanguagesComponent } from './settings-translation-languages/settings-translation-languages.component';
import { AuthDialogComponent } from './auth-dialog/auth-dialog.component';
import { AuthSignInPage } from './auth/auth-signin/auth-signin.page';
import { AuthSignUpContextPage } from './auth/auth-signup-02-context/auth-signup-context.page';
import { AuthSignUpOtherRolesPage } from './auth/auth-signup-04-other-roles/auth-signup-other-roles.page';
import { AuthSignUpMainRolesPage } from './auth/auth-signup-03-main-roles/auth-signup-main-roles.page';
import { AddContextComponent } from './add-context/add-context.component';
import { AuthSignUpConfirmPage } from './auth/auth-signup-06-confirm/auth-signup-confirm.page';
import { ErrorDialogComponent } from './error-dialog/error-dialog.component';
import { CreateEditGroupComponent } from './create-edit-group/create-edit-group.component';
import { ShareDialogComponent } from './share-dialog/share-dialog.component';
import { UserProfileSettingsComponent } from './user-profile-settings/user-profile-settings.component'
import { UserSettingsComponent } from './user-settings/user-settings.component';
import { FruitSaladComponent } from './fruit-salad/fruit-salad.component';
import { GroupParticipantsComponent } from './group-participants/group-participants.component';
import { SeeAllPendingRequestsPendingInvitationsComponent }
  from './all-pending-requests/see-all-pending-requests-pending-invitations.component';
import { GroupInvitationsComponent } from './group-invitations/group-invitations.component';
import { GroupInvitationsAoSearchComponent } from './group-invitations-ao-search/group-invitations-ao-search.component';
import { PopupDialogComponent } from './popup-dialog/popup-dialog.component';
import { ArticleRecommendsListComponent } from './article-recommends-list/article-recommends-list.component';
import { AllRecommendationsListComponent } from './all-recommendations-list/all-recommendations-list/all-recommendations-list.component';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { SeeAllGroupModeratorsComponent } from './see-all-group-moderators/see-all-group-moderators.component';
import { SeeAllParticipantsComponent } from './see-all-participants/see-all-participants.component';
import { RecommendedJournalsDialogComponent } from './recommended-journals/recommended-journals.dialog.component';
import { RecommendedPeopleDialogComponent } from './recommended-people/recommended-people.dialog.component';
import { DialogForNewlyEnrolledUsersComponent } from './dialog-for-newly-enrolled-users/dialog-for-newly-enrolled-users.component';
import { UserFollowersDialogComponent } from './user-followers-dialog/user-followers-dialog.component';
import { SearchDialogComponent } from './search-dialog/search-dialog.component';
import { SHARE_DIALOG_COMPONENT_TOKEN } from './share-dialog/share-dialog-token';
import { CaseAccessRequestsDialogComponent } from './case-access-requests-dialog/case-access-requests-dialog.component';
import { ConnectionRequestsDialogComponent } from './messenger/connection-requests-dialog/connection-requests-dialog.component';
import { NewMessageDialogComponent } from './messenger/new-message-dialog/new-message-dialog.component';
import { SendNewConnectionRequestDialogComponent }
  from './messenger/send-new-connection-request-dialog/send-new-connection-request-dialog.component';
import { AoEventParticipantsDialogComponent } from './ao-event-participants-dialog/ao-event-participants-dialog.component';
import { MIButtonsModule } from '../modules/buttons/buttons.module';
import { UsersModule } from '../modules/users/users.module';
import { StreamFiltersDialogComponent } from './stream-filters-dialog/stream-filters-dialog.component';
import { EditTextDialogComponent } from './edit-text-dialog/edit-text-dialog.component';
import { EditCaseImagesComponent } from './edit-case-images/edit-case-images.component';
import { ShareRecipientsDialogComponent } from './share-recipients-dialog/share-recipients-dialog.component';
import { RichTextDocumentEditorModule } from '../modules/rich-text-document-editor/rich-text-document-editor.module';
import { CustomizeFeedDialogComponent } from './customize-feed/customize-feed.dialog.component';
import { CoparticipantsListDialogComponent } from './coparticipants-list/coparticipants-list.dialog.component';
import { CoparticipantsListItemComponent } from './coparticipants-list/coparticipants-list-item/coparticipants-list-item.component';
import { ClinicalCaseModule } from '../modules/clinical-case/clinical-case.module';
import { ImageEditorModule } from '../modules/image-editor/image-editor.module';
import { CreateGroupCaseComponent } from './create-group-case/create-group-case.component';
import { SwiperModule } from 'swiper/angular';
import { MobilePdfViewComponent } from './mobile-pdf-view/mobile-pdf-view.component';
import { CreatePostComponent } from './create-post/create-post.component';
import { FileSelectModule } from '../modules/file-select/file-select.module';
import { TutorialDialogComponent } from './tutorial-dialog/tutorial-dialog.component';
import { DedicatedSearchDialogComponent } from './dedicated-search-dialog/dedicated-search-dialog.component';
import { ClinicalCaseSharedWithDialogComponent } from './clinical-case-shared-with-dialog/clinical-case-shared-with-dialog.component';
import { TopBarModule } from '../modules/top-bar/top-bar.module';
import { VerificationDialogComponent } from '../components/verification-dialog/verification-dialog.component';
import { QrCodeDialogComponent } from './qr-code-dialog/qr-code-dialog.component';
import { QRCodeModule } from 'angularx-qrcode';
import { SurgeryReferenceDialogComponent } from './surgery-reference/surgery-reference-dialog/surgery-reference-dialog.component';
import { FractureSelectPage } from './surgery-reference/fracture-select/fracture-select.page';
import { TreatmentSelectPage } from './surgery-reference/treatment-select/treatment-select.page';
import { ReviewPage } from './surgery-reference/review/review.page';
import { ChipsModule } from '../modules/chips/chips.module';
import { SurgeryReferenceModule } from '../modules/surgery-reference/surgery-reference.module';
import { MIDirectivesModule } from '../directives/directives.module';
import { CaseLibraryFilterDialogComponent } from './case-library-filter-dialog/case-library-filter-dialog.component';
import { GroupMemberInvitationsDialogComponent } from './group-member-invitations-dialog/group-member-invitations-dialog.component';
import { GroupMemberRequestsDialogComponent } from './group-member-requests-dialog/group-member-requests-dialog.component';
import { ReorderCaseMediaDialogComponent } from 'src/app/dialogs/reorder-case-media-dialog/reorder-case-media-dialog.component';
import { VisibilityTrackerModule } from '../modules/visibility-tracker/visibility-tracker.module';
import { MyRecommendationsDialogComponent } from 'src/app/dialogs/my-recommendations-dialog/my-recommendations-dialog.component';
import { GroupsSectionDialogComponent } from './groups-section-dialog/groups-section-dialog.component';
import {
  UserRecentPublicContributionsDialogComponent
} from './user-recent-public-contributions-dialog/user-recent-public-contributions-dialog.component';
import { EventsSectionDialogComponent } from './events-section-dialog/events-section-dialog.component';
import { FeaturedCasesDialogComponent } from './featured-cases-dialog/featured-cases-dialog.component';
import { OrcidPublicationsDialogComponent } from './orcid-publications-dialog/orcid-publications-dialog.component';
import { MyAoAuthorsDialogComponent } from 'src/app/dialogs/myao-authors-dialog/myao-authors-dialog.component';
import { SurgeryReferenceReviewContainerComponent }
  from '../modules/surgery-reference/surgery-reference-review-container/surgery-reference-review-container.component';
import {
  GroupTopContributorsDialogComponent
} from './group-top-contributors-dialog/group-top-contributors-dialog.component';
import { CreateContentParentSelectComponent } from '../components/create-content-parent-select/create-content-parent-select.component';
import {ShareRecipientsPeopleComponent} from './share-recipients-people/share-recipients-people.component';
import {SeeAllApplaudsDialogComponent} from './see-all-applauds-dialog/see-all-applauds-dialog.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    IonicModule,
    NgSelectModule,
    SwiperModule,
    MIPipesModule,
    InfoSheetModule,
    MIButtonsModule,
    MIComponentsModule,
    TranslateModule,
    IconButtonModule,
    FormsModule,
    EventsModule,
    UsersModule,
    RichTextDocumentEditorModule,
    ClinicalCaseModule,
    ImageEditorModule,
    PdfViewerModule,
    FileSelectModule,
    TopBarModule,
    QRCodeModule,
    ChipsModule,
    SurgeryReferenceModule,
    MIDirectivesModule,
    VisibilityTrackerModule,
    SurgeryReferenceReviewContainerComponent,
    CreateContentParentSelectComponent
  ],
  exports: [
    AuthSignInPage,
    AuthSignUpContextPage,
    AuthSignUpMainRolesPage,
    AuthSignUpOtherRolesPage,
    AuthSignUpConfirmPage,
    AuthDialogComponent,
    SettingsInterestsComponent,
    SettingsTranslationLanguagesComponent,
    AddContextComponent,
    ErrorDialogComponent,
    CreateEditGroupComponent,
    ShareDialogComponent,
    FruitSaladComponent,
    UserProfileSettingsComponent,
    UserSettingsComponent,
    GroupParticipantsComponent,
    SeeAllPendingRequestsPendingInvitationsComponent,
    GroupInvitationsComponent,
    GroupInvitationsAoSearchComponent,
    PopupDialogComponent,
    QrCodeDialogComponent,
    ArticleRecommendsListComponent,
    AllRecommendationsListComponent,
    ConfirmDialogComponent,
    SeeAllGroupModeratorsComponent,
    SeeAllParticipantsComponent,
    RecommendedJournalsDialogComponent,
    RecommendedPeopleDialogComponent,
    DialogForNewlyEnrolledUsersComponent,
    UserFollowersDialogComponent,
    SearchDialogComponent,
    CaseAccessRequestsDialogComponent,
    ConnectionRequestsDialogComponent,
    NewMessageDialogComponent,
    SendNewConnectionRequestDialogComponent,
    AoEventParticipantsDialogComponent,
    StreamFiltersDialogComponent,
    EditTextDialogComponent,
    EditCaseImagesComponent,
    ShareRecipientsDialogComponent,
    CustomizeFeedDialogComponent,
    CoparticipantsListDialogComponent,
    CreateGroupCaseComponent,
    MobilePdfViewComponent,
    CreatePostComponent,
    TutorialDialogComponent,
    DedicatedSearchDialogComponent,
    VerificationDialogComponent,
    ClinicalCaseSharedWithDialogComponent,
    SurgeryReferenceDialogComponent,
    FractureSelectPage,
    TreatmentSelectPage,
    ReviewPage,
    CaseLibraryFilterDialogComponent,
    GroupMemberInvitationsDialogComponent,
    GroupMemberRequestsDialogComponent,
    ReorderCaseMediaDialogComponent,
    MyRecommendationsDialogComponent,
    GroupsSectionDialogComponent,
    UserRecentPublicContributionsDialogComponent,
    EventsSectionDialogComponent,
    FeaturedCasesDialogComponent,
    OrcidPublicationsDialogComponent,
    MyAoAuthorsDialogComponent,
    GroupTopContributorsDialogComponent,
    ShareRecipientsPeopleComponent,
    SeeAllApplaudsDialogComponent
  ],
  declarations: [
    AuthSignInPage,
    AuthSignUpContextPage,
    AuthSignUpMainRolesPage,
    AuthSignUpOtherRolesPage,
    AuthSignUpConfirmPage,
    AuthDialogComponent,
    SettingsInterestsComponent,
    SettingsTranslationLanguagesComponent,
    AddContextComponent,
    ErrorDialogComponent,
    CreateEditGroupComponent,
    FruitSaladComponent,
    ShareDialogComponent,
    UserProfileSettingsComponent,
    UserSettingsComponent,
    GroupParticipantsComponent,
    SeeAllPendingRequestsPendingInvitationsComponent,
    GroupInvitationsComponent,
    GroupInvitationsAoSearchComponent,
    PopupDialogComponent,
    QrCodeDialogComponent,
    ArticleRecommendsListComponent,
    AllRecommendationsListComponent,
    ConfirmDialogComponent,
    SeeAllGroupModeratorsComponent,
    SeeAllParticipantsComponent,
    RecommendedJournalsDialogComponent,
    RecommendedPeopleDialogComponent,
    DialogForNewlyEnrolledUsersComponent,
    UserFollowersDialogComponent,
    SearchDialogComponent,
    CaseAccessRequestsDialogComponent,
    ConnectionRequestsDialogComponent,
    NewMessageDialogComponent,
    SendNewConnectionRequestDialogComponent,
    AoEventParticipantsDialogComponent,
    StreamFiltersDialogComponent,
    EditTextDialogComponent,
    EditCaseImagesComponent,
    ShareRecipientsDialogComponent,
    CustomizeFeedDialogComponent,
    CoparticipantsListItemComponent,
    CoparticipantsListDialogComponent,
    CreateGroupCaseComponent,
    MobilePdfViewComponent,
    CreatePostComponent,
    TutorialDialogComponent,
    DedicatedSearchDialogComponent,
    VerificationDialogComponent,
    ClinicalCaseSharedWithDialogComponent,
    SurgeryReferenceDialogComponent,
    FractureSelectPage,
    TreatmentSelectPage,
    ReviewPage,
    CaseLibraryFilterDialogComponent,
    GroupMemberInvitationsDialogComponent,
    GroupMemberRequestsDialogComponent,
    ReorderCaseMediaDialogComponent,
    MyRecommendationsDialogComponent,
    GroupsSectionDialogComponent,
    UserRecentPublicContributionsDialogComponent,
    EventsSectionDialogComponent,
    FeaturedCasesDialogComponent,
    OrcidPublicationsDialogComponent,
    MyAoAuthorsDialogComponent,
    GroupTopContributorsDialogComponent,
    ShareRecipientsPeopleComponent,
    SeeAllApplaudsDialogComponent
  ],
  providers: [
    { provide: SHARE_DIALOG_COMPONENT_TOKEN, useValue: ShareDialogComponent }
  ]
})
export class MIDialogsModule { }
