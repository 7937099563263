import { Component, OnInit, Input, OnDestroy, Inject } from '@angular/core';
import {
  LikeUnlikeOrcIdArticleSuccessResponse,
  OrcIdArticle,
} from '../../services/yeti-protocol/article';
import {
  ActionSheetConfigOrcIdArticle,
  MiCardActionsObjectType,
  OrcIdArticleActions,
  OrcIdArticleActionsVisibility,
} from '../../services/mi-card-actions.service';
import {
  ActionSource,
  ActionTracked,
  OrcIdTrackingParam,
  TrackingRequest,
  TranslationAction,
} from '../../services/yeti-protocol/tracking';
import { ShortPublicProfile } from '../../services/yeti-protocol/public-profile';
import { LinkOpenerService } from '../../services/link-opener.service';
import {
  TranslatorData,
  TranslatorObject,
} from 'src/app/services/translator.model';
import { TranslatorService } from 'src/app/services/translator.service';
import { ToastMode, ToastService } from '../../services/toast.service';
import {
  TRACKING_SERVICE,
  TrackingService,
} from '../../services/tracking/tracking.model';
import { Subscription } from 'rxjs';
import { AuthService } from '../../services/auth/auth.service';
import { UserProfile } from '../../services/yeti-protocol/auth/mi';
import {
  CONTEXT_DIALOGS_UI,
  ContextDialogsUI,
} from '../../services/dialogs/dialogs.ui.interface';
import { SharingUIService } from 'src/app/services/sharing/sharing-ui.service';
import { ArticleService } from 'src/app/services/article/article.service';
import { BaseUserProfile, NUMBER_USER_IMG_BUBBLES_CARDS } from 'src/app/modules/users/users.model';
import { IconLabelPosition } from 'src/app/modules/buttons/base-button/base-button.component';

@Component({
  selector: 'app-orcid-article-card',
  templateUrl: './orcid-article-card.component.html',
  styleUrls: ['./orcid-article-card.component.scss'],
})
export class OrcidArticleCardComponent implements OnInit, OnDestroy {
  @Input() orcIdArticle: OrcIdArticle;
  @Input() orcIdArticleAuthor: ShortPublicProfile;
  @Input() source: ActionSource;
  @Input() awsPersonalisationId?: string;

  orcIdArticleActionsVisibility: OrcIdArticleActionsVisibility;
  miCardActionsActionSheetConfig: ActionSheetConfigOrcIdArticle;
  isTranslated = false;
  originalTitle: string;
  user: UserProfile;
  IconLabelPosition = IconLabelPosition;

  protected applauding: boolean;

  private userProfileSubscription: Subscription;

  constructor(
    private linkOpenerService: LinkOpenerService,
    private translatorService: TranslatorService,
    private toast: ToastService,
    @Inject(TRACKING_SERVICE) private trackingService: TrackingService,
    private authService: AuthService,
    @Inject(CONTEXT_DIALOGS_UI) private dialogs: ContextDialogsUI,
    private sharingUIService: SharingUIService,
    private articleService: ArticleService
  ) { }

  ngOnInit(): void {
    this.orcIdArticleActionsVisibility = {
      translate: true,
    };

    this.miCardActionsActionSheetConfig = {
      objectType: MiCardActionsObjectType.orcIdArticle,
      object: this.orcIdArticle,
      actionsVisibilityConfig: this.orcIdArticleActionsVisibility,
    };

    this.userProfileSubscription =
      this.authService.userProfileAsObservable.subscribe(user => {
        this.user = user;
      });
  }

  ngOnDestroy(): void {
    this.userProfileSubscription?.unsubscribe();
  }

  openOrcIdArticle(): void {
    this.trackOrcIdArticleOpened();
    this.linkOpenerService.open(this.orcIdArticle?.origin || '');
  }

  trackOrcIdArticleOpened(): void {

    const paramsToTrack: OrcIdTrackingParam = {
      id: this.orcIdArticle?.id || this.orcIdArticle?._id,
      title: this.orcIdArticle?.title,
      ownerId: this.orcIdArticleAuthor?.userId,
      source: this.source,
    };

    const trackData: TrackingRequest = {
      action: ActionTracked.orcIdOpened,
      params: paramsToTrack
    };

    this.trackingService.track(trackData).catch(_err => {
      console.error('Could not track article opened action');
    });

  }

  shareOrcIdArticle(): void {
    this.sharingUIService.showShareDialogForObject(
      this.orcIdArticle,
      this.awsPersonalisationId
    );
  }

  miCardActionsActionClicked(action: string): void {
    switch (action) {
      case OrcIdArticleActions.translate:
        this.onTranslate();
        break;
    }
  }

  onTranslate(): void {
    const translationLanguage = this.user.translationLanguage || '';
    if (translationLanguage) {
      this.translateArticle(translationLanguage);
    } else {
      this.dialogs.showSelectLanguageDialog();
    }
  }

  get orcIdArticleId(): string {
    return (
      this.orcIdArticle?._id ||
      this.orcIdArticle?._id ||
      this.orcIdArticle?.origin ||
      ''
    );
  }

  private translateArticle(translationLanguage: string): void {
    if (!this.isTranslated) {
      this.originalTitle = this.orcIdArticle?.title;

      const dataToTranslateArray: Array<TranslatorObject> =
        new Array<TranslatorObject>();
      dataToTranslateArray.push({
        title: this.orcIdArticle?.title,
      });

      this.trackTranslationAction(
        this.orcIdArticleId,
        this.orcIdArticle?.title,
        TranslationAction.translate,
        translationLanguage
      );
      this.translatorService
        .translateBatch('google', translationLanguage, dataToTranslateArray)
        .then((data: TranslatorData) => {
          if (data.records.length === 1) {
            this.orcIdArticle.title = data.records[0].title;
            this.isTranslated = true;
          }
        })
        .catch(error => {
          this.toast.showWithMessage(
            error,
            'app.common.error-default',
            ToastMode.ERROR
          );
        });
    } else {
      this.orcIdArticle.title = this.originalTitle;
      this.isTranslated = false;
      this.trackTranslationAction(
        this.orcIdArticleId,
        this.orcIdArticle?.title,
        TranslationAction.backToOriginal,
        translationLanguage
      );
    }
  }

  trackTranslationAction(
    articleId: string,
    title: string,
    action: string,
    language: string
  ): void {
    this.trackingService
      .trackTranslateAction(
        articleId,
        'article',
        title,
        language,
        action,
        this.awsPersonalisationId
      )
      .catch(_err => {
        console.error('Could not track translation click action.');
      });
  }

  applaudClicked(applaud: boolean): void {
    if (this.applauding) {
      return;
    }

    this.applauding = true;
    const orcidArticleId = this.orcIdArticle?._id;

    (applaud
      ? this.articleService.likeOrcIdArticle(orcidArticleId, this.orcIdArticleAuthor?.userId)
      : this.articleService.unlikeOrcIdArticle(orcidArticleId)
    )
      .then((orcIdArticleResponse: LikeUnlikeOrcIdArticleSuccessResponse) => {
        this.orcIdArticle.hasLiked = orcIdArticleResponse.result?.hasLiked;
        this.orcIdArticle.numberOfLikes =
          orcIdArticleResponse.result?.numberOfLikes;
        this.orcIdArticle.usersThatApplauded =
          orcIdArticleResponse.result?.usersThatApplauded;
      })
      .finally(() => {
        this.applauding = false;
      });
  }

  openApplaudsList(): void {
    this.dialogs.showOrcIdArticleApplaudsList(this.orcIdArticle._id);
  }

  get displayProfileImageBubbles(): boolean {
    return this.orcIdArticle?.numberOfLikes &&
      this.orcIdArticle?.numberOfLikes > 0
      ? true
      : false;
  }

  get usersThatApplauded(): Array<BaseUserProfile> {
    if (!this.orcIdArticle || !this.orcIdArticle?.usersThatApplauded?.length) {
      return [];
    }

    return this.orcIdArticle?.usersThatApplauded
      .slice(0, NUMBER_USER_IMG_BUBBLES_CARDS)
      .map(userThatApplauded => {
        return {
          userId: userThatApplauded.userId,
          profileImageUrl: userThatApplauded.profileImageUrl,
        };
      });
  }
}
