import { Inject, Injectable } from '@angular/core';

import { Observable} from 'rxjs';

import { MyStreamRequestParams, MyStreamResponse } from './yeti-protocol/my-stream';
import appConfig from 'src/config/config';

import { AuthService } from './auth/auth.service';
import { SchemaValidatorService } from './schema-validator.service';
import { toAuthRequestParams } from './auth/logic/auth-logic.utils';
import { LoadingService } from './loadingService/loadingService.service';
import { StreamStateType } from './yeti-protocol/utils/enums';
import { CONTEXT_SERVICE, ContextService } from './context/context.model';

export interface MyStreamServiceConfig {
  serverUrlNetwork: string;
  serverUrlKnowledge: string;
  serverUrlSmartKnowledgeStream: string
}

@Injectable({
  providedIn: 'root'
})
export class MyStreamService {
  config: MyStreamServiceConfig = {
    serverUrlNetwork: `${appConfig.backendUrlIonic}insights/social`,
    serverUrlKnowledge: `${appConfig.backendUrlIonic}insights/content`,
    serverUrlSmartKnowledgeStream: `${appConfig.backendUrlIonic}insights/smart`,
  };
  private defaultStart = 0;

  constructor(
    @Inject(CONTEXT_SERVICE) private contextService: ContextService,
    private authService: AuthService,
    private schemaValidator: SchemaValidatorService,
    public loadingService: LoadingService
  ) {}

  fetchStream(start: number = this.defaultStart,
              count: number = appConfig.streamPagination,
              streamState: string = StreamStateType.network,
              published?: boolean, filter: string = '', loadSmartStream?: boolean): Observable<MyStreamResponse> {
    let serverUrl;

    if (published === undefined) {
      published = true;
    }
    const params: MyStreamRequestParams = {
      appId: this.contextService.currentContext.key,
      published: published,
      filter: filter,
      start: start,
      count: count
    };

    if(streamState === StreamStateType.network) {
      serverUrl = this.config.serverUrlNetwork;
    } else if(streamState === StreamStateType.knowledge) {
      if(loadSmartStream) {
        serverUrl = this.config.serverUrlSmartKnowledgeStream;
      } else {
        serverUrl = this.config.serverUrlKnowledge
      }
    }

    this.loadingService.dismiss();

    return this.authService.secureGet<MyStreamResponse>(serverUrl, {params: toAuthRequestParams(params)})
      .pipe(
        this.schemaValidator.isValidOperator('MyStreamResponse')
      );
  }
}
