import { Component, OnInit, OnDestroy, Optional, AfterViewInit, HostListener, Inject } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

import { Subscription } from 'rxjs';

import { Platform } from '@ionic/angular';
import { SplashScreen } from '@awesome-cordova-plugins/splash-screen/ngx';
import { StatusBar } from '@awesome-cordova-plugins/status-bar/ngx';

import { Platform as AppPlatform } from '../config/config.model';

import { AuthService } from './services/auth/auth.service';
import { LocationService } from './services/location.service';
import { AppTranslationService } from './services/app-translation.service';
import { ResourcesUpdateUIService } from './services/resources-update/resources-update-ui.service';
import { TimeoutService } from './services/utils/timeout.service';
import { DeepLinksProcessorService } from './services/deep-links/deep-links-processor.service';
import { PushNotificationsService } from './services/deep-links/push-notifications.service';
import { CookieConsentService } from './services/cookie-consent/cookie-consent.service';
import { ContextService, CONTEXT_SERVICE } from './services/context/context.model';
import { ToastMode, ToastService } from './services/toast.service';

import appConfig from '../config/config';
import { NavControllerService } from './services/nav-controller.service';
import { URL, UrlUtilsService } from './services/utils/url-utils.service';
import { DeepLinksRecognizerService } from './services/deep-links/deep-links-recognizer.service';
import { DeepLinksStorageService } from './services/deep-links/deep-links-storage.service';
import { ConnectionRequestsService } from './services/messenger/connection-requests.service';
import { AppInstallPromptService } from './services/app-install-prompt.service';
import { ScriptLoaderService } from './services/utils/script-loader.service';
import { QRCodeService } from './services/qr-code/qr-code.service';
import { UIUtilsServiceInterface, UI_UTILS_SERVICE } from './services/utils/ui-utils.service.interface';
import { ActiveConversations } from './state/active-conversations/active-conversations.actions';
import { Store } from '@ngxs/store';
import { Activities } from './state/activities/activities.actions';
import { ClinicalCasesAccessRequests } from './state/clinical-cases-access-requests/clinical-cases-access-requests.actions';
import { ACTIVITIES_STATE_KEY } from './state/state-constants';
import { SocketService } from './services/socket.service';
import { MIAuthService } from './services/auth/mi/mi-auth.service';
import { ClientConfigService } from './services/client-config.service';
import { UserProfile } from './services/yeti-protocol/auth/mi';

interface AppComponentConfig {
  platform: AppPlatform;
  authenticationAO: {
    serverUrl: string;
  };
  appUpdateDisabled: boolean;
  appUpdateCheckDelay: number;
  showResourceUpdateInstallPrompt: boolean;
}

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {

  isSignedInSub: Subscription;
  accessBlockedSub: Subscription;
  routerSub: Subscription;
  deepLinkSub: Subscription;
  platformResumeSubscription: Subscription;
  platformPauseSubscription: Subscription;
  isNewVersionAvailable = false;
  signedIn: boolean = null;
  config: AppComponentConfig = appConfig;
  indicatorsInitialized = false;
  signedInSub: Subscription;
  signedOutSub: Subscription;
  miRefreshTokensSub: Subscription;

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private authService: AuthService,
    private router: Router,
    private locationService: LocationService,
    private appTranslationService: AppTranslationService,
    private timeoutService: TimeoutService,
    @Inject(CONTEXT_SERVICE) private contextService: ContextService,
    private urlUtils: UrlUtilsService,
    private toast: ToastService,
    private navController: NavControllerService,
    @Optional() public resourceUpdateUIService: ResourcesUpdateUIService,
    private deepLinkRecogniserService: DeepLinksRecognizerService,
    private deepLinksProcessorService: DeepLinksProcessorService,
    private deepLinkStorage: DeepLinksStorageService,
    @Optional() private pushNotificationsService: PushNotificationsService,
    @Optional() private cookieConsentService: CookieConsentService,
    private connectionRequestsService: ConnectionRequestsService,
    @Optional() private appInstallPromptService: AppInstallPromptService,
    private scriptLoaderService: ScriptLoaderService,
    private qrCodeService: QRCodeService,
    @Inject(UI_UTILS_SERVICE) private uiUtilsService: UIUtilsServiceInterface,
    private store: Store,
    private socketService: SocketService, // we need to keep this here in order to create socket connection on app init,
    private miAuthService: MIAuthService,
    private clientConfigService: ClientConfigService
  ) {
    this.appTranslationService.init();
    if (this.appInstallPromptService) {
      this.appInstallPromptService.initPwaPrompt();
    }
    this.initializeApp();
  }

  initializeApp(): Promise<void> {
    return this.platform.ready().then((readySource: string) => {
      console.log(`platform.ready(): ${readySource}`);
      if (readySource === 'cordova') {
        // with cordova available
        this.statusBar.styleDefault();
        this.statusBar.overlaysWebView(false);
        this.splashScreen.hide();
      }

      // for testing:
      // setTimeout(() => {
      //   this.isNewVersionAvailable = true;
      // }, 1000);
      this.deepLinkSub = this.deepLinksProcessorService.deepLink.subscribe(deepLink => {
        this._proceesDeepLink(deepLink);
      });
      this.deepLinksProcessorService.enabled = true;
      this.deepLinksProcessorService.init();
    });
  }

  @HostListener('document:click', ['$event'])
  onClick(ev: MouseEvent): void {
    this.uiUtilsService.emitAppClickEvent(ev);
  }

  get isMobile(): boolean {
    return this.config.platform === AppPlatform.ANDROID || this.config.platform === AppPlatform.IOS;
  }

  get hasAOSignIn(): boolean {
    return !!this.config.authenticationAO;
  }

  ngOnInit(): void {
    this.isSignedInSub = this.authService.isSignedInAsObservable.subscribe(isSignedIn => {
      this._onSignInStateChanged(isSignedIn);
    });
    this.accessBlockedSub = this.authService.accessBlockedAsObservable.subscribe(accessBlocked => {
      if (accessBlocked) {
        this.router.navigateByUrl('/auth/access-blocked');
      }
    });
    this.signedInSub = this.authService.signedIn.subscribe(async _isSignedIn => {
      const isUserLoggedIn = await this.authService.isSignedIn();
      if (isUserLoggedIn) {
        this._doPushNotificationsLogin();
      }
    })
    this.signedOutSub = this.authService.signedOut.subscribe(isSignedOut => {
      if (isSignedOut) {
        this._doPushNotificationsLogout();
      }
    })
    this._processAppLoading();
    this._processResourceUpdate();
    this.navController.startHistoryTracking();
    if (!this.isMobile) {
      this.scriptLoaderService.loadScript('cookieProScript')
        .then(() => Promise.resolve())
        .catch(err => {
          console.error(err);
          // if anything wrong, script not available or not enabled, show previous/old cookieConsent
          // TODO: check if we still need the miAllowed cookie
          this.cookieConsentService?.init();
        });
    }
    if (this.isMobile) {
      this._trackCurrentContext();
    }

    this.miRefreshTokensSub = this.miAuthService.miTokensRefreshObservable.subscribe(() => {
      this.clientConfigService.getClientConfig(true);
    });
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.qrCodeService.setDisplayQRCode = true;
    }, 5000);
  }

  _processAppLoading(): Promise<void> {
    const parsedUrl: URL = this.urlUtils.parse(this.locationService.href);
    const isDeepLink = this.deepLinkRecogniserService.isDeepLink(parsedUrl.path);
    if (!isDeepLink) {
      // save source/marketing info, if present
      return this.deepLinkStorage.saveCampaignInfoIfPresent(this.locationService.href)
        .then(() => Promise.resolve());
    }
    return this.deepLinkStorage.saveDeepLinkIfNotSignedIn(this.locationService.href)
      .then(() => Promise.resolve());
  }

  _trackCurrentContext(): void {
    this.routerSub = this.router.events.subscribe(evt => {
      if (evt instanceof NavigationEnd) {
        this.contextService.saveCurrentContextKey();
      }
    });
  }

  _proceesDeepLink(deepLink: string): void {
    if (!deepLink) {
      return;
    }
    this.deepLinkStorage.saveDeepLinkIfNotSignedIn(deepLink)
      .then(() => {
        this.router.navigateByUrl(deepLink);
      });
  }

  _processResourceUpdate(): void {
    if (this.config.appUpdateDisabled || !this.resourceUpdateUIService) {
      return;
    }
    if (this.config.appUpdateCheckDelay) {
      this.timeoutService.setTimeout(() => {
        this.downloadResourceUpdateIfAvailable();
      }, this.config.appUpdateCheckDelay);
    }
  }

  async _initIndicators(): Promise<void> {
    // to update UI indicators

    let profile: UserProfile = null;

    try {
      const userContext = (await this.contextService.getCurrentContext())?.key;

      if (userContext) {
        profile = await this.authService.getProfile(userContext);
      }
    } catch (err) {
      console.error(err);
    }

    if (profile?.homeDevision) {
      this.store.dispatch(
        new ActiveConversations.FetchActiveConversations({ pageIndex: 0 })
      );

      const activitiesLoading = this.store.snapshot()?.[ACTIVITIES_STATE_KEY]?.activitiesLoading;
      const activities = this.store.snapshot()?.[ACTIVITIES_STATE_KEY]?.activities;

      if (!activitiesLoading && !activities?.length) {
        this.store.dispatch(new Activities.FetchActivities({ pageIndex: 0 }));
      }

      this.store.dispatch(new ClinicalCasesAccessRequests.FetchClinicalCasesAccessRequests({ pageIndex: 0 }));

      return Promise.all([
        this.connectionRequestsService.init()
      ]).then(() => { return Promise.resolve(); });
    }
  }

  _onSignInStateChanged(isSignedIn: boolean): void {

    let shouldReloadIfNotLoggedIn = false;

    if (this.signedIn !== null && this.signedIn !== undefined) { // if not initial app load
      shouldReloadIfNotLoggedIn = true;
    }

    if (this.signedIn === isSignedIn) {
      return; // ignore
    }
    this.signedIn = isSignedIn;
    if (isSignedIn && !this.indicatorsInitialized) {

      setTimeout(() => {
        this._initIndicators()
          .finally(() => {
            this.indicatorsInitialized = true;
          })
      }, 250);
    } else {
      this.indicatorsInitialized = false;
    }

    if (!isSignedIn && shouldReloadIfNotLoggedIn) {
      location.reload();
    }
  }

  ngOnDestroy(): void {
    this.isSignedInSub?.unsubscribe();
    this.accessBlockedSub?.unsubscribe();
    this.routerSub?.unsubscribe();
    this.deepLinkSub?.unsubscribe();
    this.platformResumeSubscription?.unsubscribe();
    this.platformPauseSubscription?.unsubscribe();
    this.signedInSub?.unsubscribe();
    this.signedOutSub?.unsubscribe();
    this.miRefreshTokensSub?.unsubscribe();
  }

  downloadResourceUpdateIfAvailable(): void {
    this.resourceUpdateUIService.downloadNewVersionIfAvailable()
      .then(() => {
        return this.resourceUpdateUIService.hasNewVersionAvailable();
      })
      .then(newVersionAvailable => {
        this.isNewVersionAvailable = this.config.showResourceUpdateInstallPrompt && newVersionAvailable;
        if (!newVersionAvailable) {
          this.resourceUpdateUIService.cleanupOldVersions();
        }
      });
  }

  onAppyResourceUpdate(): void {
    this.resourceUpdateUIService.switchAtNewVersion()
      .catch(err => {
        this.toast.showWithMessage(err, 'app.common.error-default', ToastMode.ERROR, false, 3000);
      });
  }

  onCloseResourceUpdateChip(): void {
    this.isNewVersionAvailable = false;
  }

  _isSignedIn(): Promise<boolean> {
    if (this.signedIn !== null) {
      return Promise.resolve(this.signedIn);
    }
    return this.authService.isSignedIn().then(signedIn => {
      this.signedIn = signedIn;
      return signedIn;
    })
  }

  async _doPushNotificationsLogin(): Promise<void> {
    if (this._isWithPushNotifications) {
      try {
        const context = await this.contextService.getCurrentContext();

        return this.authService.getProfile(context?.key).then(profile => {
          this.pushNotificationsService.onLogin(profile.id);
        });

      } catch (err) {
        console.error(err);
      }

      return Promise.resolve();
    }
    return Promise.resolve();
  }

  _doPushNotificationsLogout(): void {
    if (this._isWithPushNotifications) {
      this.pushNotificationsService.onLogout();
    }
  }

  get _isWithPushNotifications(): boolean {
    return this.pushNotificationsService && this.platform.is('cordova');
  }

}
