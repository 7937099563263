import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { ThreeColumnLayoutFlexComponent } from './three-column-layout-flex/three-column-layout-flex.component';
import { ThreeColumnLayoutComponent } from './three-column-layout/three-column-layout.component';
import { TwoColumnLayoutComponent } from './two-column-layout/two-column-layout.component';


@NgModule({
  declarations: [
    TwoColumnLayoutComponent,
    ThreeColumnLayoutFlexComponent,
    ThreeColumnLayoutComponent
  ],
  exports: [
    TwoColumnLayoutComponent,
    ThreeColumnLayoutFlexComponent,
    ThreeColumnLayoutComponent
  ],
  imports: [
    CommonModule,
    IonicModule,
  ]
})
export class LayoutModule { }
