export type Environment = 'dev' | 'stage' | 'prod';

export enum Platform {
  BROWSER = 'browser',
  IOS = 'ios',
  ANDROID = 'android',
  PWA = 'pwa'
}

export enum Language {
  EN = 'en'
}

export const NOT_USED = null;

export interface Script {
  name: string;
  src: string;
  dataDomainScript?: string,
  enabled: boolean | false;
}

export type Scripts = Array<Script>;

// --- context configuration -- begin

export interface ContextConfigInterface {
  key: string;
  label: string;
  homePath: string;
  homePathAliases?: Array<string>;
  logoImageName: string;
  iconName: string;
  aoMembershipUrl: string;
  moreEventsLink: string;
}

export interface ContextEnvironmentConfigInterface {
  environment: Environment;
  kaltura: KalturaConfig;
  aoMembershipUrl: string;
  moreEventsLink: string;
}

export interface KalturaConfig {
  partnerId: string;
  uiconfId: string;
}

export type ContextConfigType = ContextConfigInterface & ContextEnvironmentConfigInterface;

// --- app configuration -- begin

interface ClientConfiguration {
  showSmartStreamSwitch: boolean | false
}

interface ProfessionInfo {
  key: string,
  labelKey: string,
  icon: string
}

interface CookieConsentPalette {
  popup: { // colors
    background: string;
    text: string;
    link: string;
  },
  button: {
    background: string;
    text: string;
    border: string
  }
}

export interface InAppBrowserColors {
  toolbarcolor: string,
  closebuttoncolor: string
}

export interface OneSignalPushNotificationConfig {
  appId: string;
  firebaseProjectNumber: string;
  triggerKey: string;
  triggerValue: string;
}

export type TextResourceId = string;

export interface AppConfigInterface {
  name: string;
  contexts: Array<ContextConfigType>;
  defaultLanguage: Language,
  requestTimeout: number;
  logoImageName: string;
  cookieConsentDomain: string;
  cookieConsentPalette: CookieConsentPalette;
  inAppBrowserColors: InAppBrowserColors;
  cookieConsentExpiresDays: number;
  eventMessageSenderGroupNames: Array<string>;
  videoChannels: Array<string>;

  authMainProfessionKeys: Array<string>;
  link: {
    termsOfUseUrl: string,
    contact: string,
    appStore: string,
    playStore: string,
    accessBlockedHelpUrl: string,
    feedbackUrl: string,
    helpCenter: string,
    qrCodePopup: string,
    qrCodeButton: string
  }

  appUpdateFieldSuffixName: string;
  appUpdateCheckDelay: number; // msec

  groupNameMaxChars: number;
  groupDescriptionMaxChars: number;
  groupModeratorsMaxNum: number;

  maxNumberVerticalItems: number;

  professions: Array<ProfessionInfo>;
  deepLinks: Array<string>; // app level deep links
  contextDeepLinks: Array<string>; // with <%= name %> template parameters from ContextConfigType
  tabsConfig: Array<TabConfig>;
  scripts: Scripts; // lazy loaded scripts
  streamPagination: number;
  clinicalCasesPagination: number;
  oneSignalPushNotification: OneSignalPushNotificationConfig;
  webSocketReconnectAttemptInterval: number; // msec

  defaultProfileImageUrl: string;
  clientConfigurations?: ClientConfiguration;
}

export interface AuthenticationConfig {
  clientId: string;
  clientSecret: string;
  serverUrl: string;
}

export interface AuthenticationAOConfig {
  clientId: string;
  serverUrl: string;
  redirectUri: string;
  version: number;
  scopes: Array<string>;
  updateEmailAddressAOLink: string;
  updateUserDataAoLink: string;
  userRegistrationUrl: string;
  logoutUrl: string;
  logoutRedirectUri: string;
  signUpRedirectUri: string;
  signUpRedirectUriIOS: string;
}

export interface AuthenticationOrcIdConfig {
  clientId: string;
  serverUrl: string;
  redirectUri: string;
  clientSecret: string;
}

export interface AppEnvironmentConfigInterface {
  environment: Environment;
  authentication: AuthenticationConfig;
  authenticationAO: AuthenticationAOConfig | null;
  authenticationOrcId: AuthenticationOrcIdConfig | null;
  serverUrl: string;
  serverUrlIonic: string;
  backendUrl: string;
  backendUrlIonic: string;
  appUpdateUrl: string;
  imagesProxyServerUrl: string;
  webUrl: string;
  eventUrl: string;
  eventGroupsUrl: string;
  sharedGroupsUrl: string;
  sharedEventsUrl: string;
  chatterUrl: string;
  drywallServer: string;
  searchAOServer: string;
  webSocketServer: string;
  aiUrl: string;
  recommendationsUrl: string;
  showResourceUpdateInstallPrompt: boolean;
  aiSurgeryReferenceApiKey?: string;
}

export interface AppPlatformConfigInterface {
  platform: Platform;
  appUpdateVersionFieldName: string;
  appUpdateContentUrlFieldName: string;
  appUpdateDisabled?: boolean;
  cookieConsentDomain?: string;
  socialLoginDisabled?: boolean;
}

export interface TabConfig {
  id: TabIds;
  label: string;
  icon: string;
  route: string;
  routeRe?: RegExp;
  type: TabType;
}

export enum TabIds {
  FEED = 'feed',
  CASEFOLIO = 'casefolio',
  GROUPS = 'groups',
  CONTACTS = 'contacts',
  ACTIVITY = 'activity',
  SEARCH = 'search',
}

export enum TabType {
  DEFAULT = 'default',
  ADD_CONTENT = 'add-content'
}

export type AppConfigType = AppConfigInterface & AppEnvironmentConfigInterface & AppPlatformConfigInterface;

// --- app configuration -- end

// info extracted from package.json
export interface PackageJsonConfig {
  oneSignal: OneSignalPushNotificationConfig;
  aiSurgeryReferenceApiKey: string;
  schemaValidationEnabled: boolean;
  feedbackUrl: string;
}
