import { Component, Input } from '@angular/core';

// models
import { Article } from '../../services/yeti-protocol/article';
import { ActionSource } from '../../services/yeti-protocol/tracking';

@Component({
  selector: 'app-suggested-articles-card',
  templateUrl: './suggested-articles-card.component.html',
  styleUrls: ['./suggested-articles-card.component.scss'],
})
export class SuggestedArticlesCardComponent{
  @Input() articles: Article[] = [];
  @Input() source = ActionSource;
}
