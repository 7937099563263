import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { UserProfile } from '../../services/yeti-protocol/auth/mi';
import { VerificationStatus } from 'src/app/services/verification.model';
import { AuthService } from '../../services/auth/auth.service';
import { VerificationService } from 'src/app/services/verification.service';
import { CreateContentStrategy } from 'src/app/services/create-content/create-content';
import { ActionSource } from '../../services/yeti-protocol/tracking';
import { TRACKING_SERVICE, TrackingService } from '../../services/tracking/tracking.model';

@Component({
  selector: 'app-add-content-card',
  templateUrl: './add-content-card.component.html',
  styleUrls: ['./add-content-card.component.scss'],
})
export class AddContentCardComponent implements OnInit, OnDestroy {

  @Input() createContentStrategy: CreateContentStrategy;
  @Input() source: ActionSource;

  user: UserProfile;
  private userSubscription: Subscription;

  constructor(
    private authService: AuthService,
    private verificationService: VerificationService,
    @Inject(TRACKING_SERVICE) private trackingService: TrackingService
  ) { }

  ngOnInit(): void {
    this.userSubscription = this.authService.userProfileAsObservable.subscribe(userProfile => {
      this.user = userProfile;
    });
  }

  ngOnDestroy(): void {
    this.userSubscription?.unsubscribe();
  }

  async createPost(): Promise<void> {

    const verificationStatus = await this.checkVerificationStatus();

    if (!verificationStatus) {
      return;
    }

    this.trackCreateContentClicked('');
    this.createContentStrategy.openCreatePost();
  }

  async shareACase(): Promise<void> {

    const verificationStatus = await this.checkVerificationStatus();

    if (!verificationStatus) {
      return;
    }

    this.trackCreateContentClicked('case');
    this.createContentStrategy.shareCaseInPost = true;
    this.createContentStrategy.openCreateCase();
  }

  async addImages(): Promise<void> {

    const verificationStatus = await this.checkVerificationStatus();

    if (!verificationStatus) {
      return;
    }

    this.trackCreateContentClicked('images');
    this.createContentStrategy.openAddImages();
  }

  async attachDocument(): Promise<void> {

    const verificationStatus = await this.checkVerificationStatus();

    if (!verificationStatus) {
      return;
    }
    this.trackCreateContentClicked('document');
    this.createContentStrategy.openAttachDocument();
  }

  async addVideo(): Promise<void> {

    const verificationStatus = await this.checkVerificationStatus();

    if (!verificationStatus) {
      return;
    }

    this.trackCreateContentClicked('video');
    this.createContentStrategy.openAddVideo();

  }

  async checkVerificationStatus(): Promise<boolean> {
    const userVerificationStatus = await this.verificationService.verify();

    if (userVerificationStatus === VerificationStatus.VERIFIED) {
      return true;
    }

    return false;
  }

  trackCreateContentClicked(postType?: string,): void {
    const objId = 'createContent';
    let objType = 'post';
    if(postType) {
      objType += '_' + postType;
    }
    this.trackingService.trackGenericClickedAction(objId, objType, 'postCreated',
      { source: this.source || ActionSource.unspecified })
      .catch(_err => {
        console.error('Could not track content created click action.');
      });
  }
}
