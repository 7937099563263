// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.post-to-your-network-button {
  width: 100%;
  background: var(--mi-blue-color-4);
  outline: none;
  border: none;
  padding: 13px;
  margin: 0;
  border-radius: 7px;
}
.post-to-your-network-button .button-wrapper {
  display: grid;
  grid-template-columns: min-content auto min-content;
  grid-template-rows: auto;
  align-items: center;
  -moz-column-gap: 13px;
       column-gap: 13px;
}
.post-to-your-network-button .button-wrapper .icon-start-container {
  grid-row: 1;
  grid-column: 1;
  height: 35px;
  width: 35px;
  border-radius: 50%;
}
.post-to-your-network-button .button-wrapper .icon-start-container app-image {
  height: 35px;
  width: 35px;
}
.post-to-your-network-button .button-wrapper .icon-start-container app-image ::ng-deep div, .post-to-your-network-button .button-wrapper .icon-start-container app-image ::ng-deep div ion-img, .post-to-your-network-button .button-wrapper .icon-start-container app-image ::ng-deep div ion-img::part(image) {
  border-radius: 50%;
}
.post-to-your-network-button .button-wrapper .text-container {
  grid-row: 1;
  grid-column: 2;
  position: relative;
  height: 14px;
}
.post-to-your-network-button .button-wrapper .text-container .wrapper {
  position: absolute;
  top: 0;
  left: 0;
}
.post-to-your-network-button .button-wrapper .text-container .wrapper .button-text {
  margin: 0;
  color: var(--mi-grey-color-13);
  font-size: var(--font-size-regular);
  font-weight: var(--font-weight-regular);
}
.post-to-your-network-button .button-wrapper .icon-end-container {
  grid-row: 1;
  grid-column: 3;
}
.post-to-your-network-button .button-wrapper .icon-end-container app-icon {
  --icon-size: 30px;
  color: var(--mi-black-color);
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
