import { Action, Selector, State, StateContext, StateToken } from '@ngxs/store';
import { PostsStateModel } from './posts.model';
import { Injectable } from '@angular/core';
import { POSTS_STATE_STATE_KEY } from '../state-constants';
import { Posts } from './posts.actions';
import { Case, ParentType, Post, PostType } from 'src/app/services/yeti-protocol/chatter-api';
import { GroupsFeed } from '../groups-feed/groups-feed.actions';
import { GroupFeed } from '../group-feed/group-feed.actions';
import { EventFeedWithParticipants } from '../event-feed-with-participants/event-feed-with-participants.actions';
import { EventFeedFacultyOnly } from '../event-feed-faculty-only/event-feed-faculty-only.actions';
import { MyNetworkStream } from '../my-network-stream/my-network-stream.actions';
import { StreamItemType } from 'src/app/services/yeti-protocol/utils/enums';

const POSTS_STATE_TOKEN = new StateToken<PostsStateModel>(
  POSTS_STATE_STATE_KEY
);

@State({
  name: POSTS_STATE_TOKEN,
})
@Injectable()
export class PostsState {

  constructor() { }

  @Selector()
  static state(state: PostsStateModel): PostsStateModel {
    return state;
  }

  @Action(Posts.AddNewPost)
  async addNewPost(
    ctx: StateContext<PostsStateModel>,
    action: Posts.AddNewPost
  ): Promise<void> {

    if (action.post.parentType === ParentType.GROUP) {
      ctx.dispatch(new GroupsFeed.InsertGroupsFeedItemBeforeIndex(
        { streamItem: action.post, streamItemType: this.getGroupsFeedStreamItemType(action.post) },
        1)
      );
    }

    ctx.dispatch(new GroupFeed.InsertPostsFeedItemBeforeIndex(action.post, 0));
    ctx.dispatch(new EventFeedWithParticipants.InsertPostsFeedItemBeforeIndex(action.post, 0));
    ctx.dispatch(new EventFeedFacultyOnly.InsertPostsFeedItemBeforeIndex(action.post, 0));
  }

  @Action(Posts.UpdatePost)
  async updatePost(
    ctx: StateContext<PostsStateModel>,
    action: Posts.UpdatePost
  ): Promise<void> {

    ctx.dispatch(new GroupsFeed.UpdateGroupsFeedItem(
      { streamItem: action.post, streamItemType: this.getGroupsFeedStreamItemType(action.post) })
    );

    ctx.dispatch(new GroupFeed.UpdatePostsFeedItem(action.post));
    ctx.dispatch(new EventFeedWithParticipants.UpdatePostsFeedItem(action.post));
    ctx.dispatch(new EventFeedFacultyOnly.UpdatePostsFeedItem(action.post));

    const streamItemType =
      action?.post?.type === PostType.case
        ? StreamItemType.case
        : StreamItemType.post;

    const streamItem = {
      streamItemType: streamItemType,
      streamItem: action?.post as any
    }

    ctx.dispatch(new MyNetworkStream.UpdateMyNetworkStreamItem(streamItem));
  }

  @Action(Posts.DeletePost)
  async deletePost(
    ctx: StateContext<PostsStateModel>,
    action: Posts.DeletePost
  ): Promise<void> {

    ctx.dispatch(new GroupsFeed.RemoveGroupsFeedItem(
      { streamItem: action.post, streamItemType: this.getGroupsFeedStreamItemType(action.post) })
    );

    ctx.dispatch(new GroupFeed.RemovePostsFeedItem(action.post));
    ctx.dispatch(new EventFeedWithParticipants.RemovePostsFeedItem(action.post));
    ctx.dispatch(new EventFeedFacultyOnly.RemovePostsFeedItem(action.post));

    const streamItemType =
      action?.post?.type === PostType.case
        ? StreamItemType.case
        : StreamItemType.post;

    const streamItem = {
      streamItemType: streamItemType,
      streamItem: action?.post as any
    }

    ctx.dispatch(new MyNetworkStream.RemoveMyNetworkStreamItem(streamItem));
  }

  @Action(Posts.UpdatePostsOwnerFollowingStatus)
  async updatePostsOwnerFollowingStatus(
    ctx: StateContext<PostsStateModel>,
    action: Posts.UpdatePostsOwnerFollowingStatus
  ): Promise<void> {
    ctx.dispatch(new GroupsFeed.UpdateGroupsFeedItemsOwnerFollowingStatus(action.userId, action.isFollower));
    ctx.dispatch(new GroupFeed.UpdatePostsFeedItemsOwnerFollowingStatus(action.userId, action.isFollower));
    ctx.dispatch(new EventFeedWithParticipants.UpdatePostsFeedItemsOwnerFollowingStatus(action.userId, action.isFollower));
    ctx.dispatch(new EventFeedFacultyOnly.UpdatePostsFeedItemsOwnerFollowingStatus(action.userId, action.isFollower));
    ctx.dispatch(new MyNetworkStream.UpdateMyNetworkStreamItemsOwnerFollowingStatus(action.userId, action.isFollower));
  }

  getGroupsFeedStreamItemType(item: Post | Case): string {

    if (item?.type === PostType.case) {
      return PostType.case;
    }

    return PostType.post;
  }
}
