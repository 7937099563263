import {Component, EventEmitter, Inject, Input, Output} from '@angular/core';

// services
import {ClinicalExpertsService} from 'src/app/services/clinical-experts.service';
import {ClinicalExpertsDataService} from 'src/app/services/clinical-experts-data.service';
import {PublicProfile} from 'src/app/services/yeti-protocol/public-profile';
import {UI_UTILS_SERVICE, UIUtilsServiceInterface} from 'src/app/services/utils/ui-utils.service.interface';
import {ActionSource} from '../../../services/yeti-protocol/tracking';
import { Store } from '@ngxs/store';
import { Posts } from 'src/app/state/posts/posts.actions';

@Component({
  selector: 'app-clinical-expert-card',
  templateUrl: './clinical-expert-card.component.html',
  styleUrls: ['./clinical-expert-card.component.scss'],
})
export class ClinicalExpertCardComponent {
  @Input() clinicalExpert: PublicProfile;
  @Input() source: ActionSource = ActionSource.unspecified;
  @Output() openPublicProfile:EventEmitter<string> = new EventEmitter();

  constructor(
    private clinicalExpertService: ClinicalExpertsService,
    private clinicalExpertDataService: ClinicalExpertsDataService,
    @Inject(UI_UTILS_SERVICE) private uiUtilsService: UIUtilsServiceInterface,
    private store: Store
  ) { }

  onFollowUnfollowClinicalExpert(event: Event, clinicalExpertId: string, follow: boolean): void {
    this.uiUtilsService.stopEventPropagation(event);
    this.clinicalExpertService.followUnfollow(clinicalExpertId, follow, this.source)
    .then((result: boolean) => {
      if(result) {
        this.clinicalExpert.isFollower = follow;
        this.clinicalExpertDataService.triggerFollowUnfollowClinicalExpertAction(this.clinicalExpert);
        this.store.dispatch(new Posts.UpdatePostsOwnerFollowingStatus(this.clinicalExpert?.userId, follow));
      }
    });
  }

  onOpenPublicProfilePage(event: Event): void {
    this.uiUtilsService.stopEventPropagation(event);
    this.openPublicProfile.emit(this.clinicalExpert.userId);
  }
}
