import { AfterViewInit, Component, ElementRef, EventEmitter, Inject, Input, OnDestroy, Output } from '@angular/core';
import { Router } from '@angular/router';

import { SwiperOptions } from 'swiper';
import { SwiperLogic } from 'src/app/services/utils/swiper-logic';

// services
import {PublicProfile} from 'src/app/services/yeti-protocol/public-profile';
import {ResponsiveUtilsService} from '../../services/utils/responsive-utils.service';
import {ActionSource} from '../../services/yeti-protocol/tracking';
import { ContextDialogsUI, CONTEXT_DIALOGS_UI } from 'src/app/services/dialogs/dialogs.ui.interface';
import { CONTEXT_SERVICE, ContextService } from 'src/app/services/context/context.model';

@Component({
  selector: 'app-clinical-expert-card-list',
  templateUrl: './clinical-expert-card-list.component.html',
  styleUrls: ['./clinical-expert-card-list.component.scss'],
})

export class ClinicalExpertCardListComponent implements OnDestroy, AfterViewInit {

  @Input() clinicalExpertList: Array<PublicProfile> = new Array<PublicProfile>();
  @Input() clinicalExpertsLoading: boolean;
  @Input() source: ActionSource = ActionSource.unspecified;
  @Output() openPublicProfile:EventEmitter<string> = new EventEmitter();

  swiperLogic: SwiperLogic;
  readonly max_cards_num = 9;

  constructor(
    private router: Router,
    @Inject(CONTEXT_SERVICE) private contextService: ContextService,
    private responsiveUtilsService: ResponsiveUtilsService,
    @Inject(CONTEXT_DIALOGS_UI) private dialogs: ContextDialogsUI,
    private el: ElementRef
  ) {
    this.swiperLogic = new SwiperLogic(302, this.responsiveUtilsService, this.el);
  }

  ngOnDestroy(): void {
    this.swiperLogic.destroy();
  }

  ngAfterViewInit(): void {
    this.swiperLogic.afterViewInit();
  }

  get swiperConfig(): SwiperOptions {
    return this.swiperLogic.config;
  }

  async openRecommendedPeople(): Promise<void> {
    if (this.isDesktop) {
      this.dialogs.showRecommendedPeople();
    } else {
      this.router.navigateByUrl(`${this.contextService.currentContext.homePath}/recommended-people`);
    }
  }

  get isDesktop(): boolean {
    return this.responsiveUtilsService.isDesktop;
  }
}
