// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.also-discussed-in-container {
  width: 100%;
  border-top: 1.5px solid var(--mi-background-color);
}
.also-discussed-in-container .title {
  padding: 15px 20px;
  background-color: var(--mi-blue-light);
  height: 49px;
  color: var(--mi-black-color-2);
  font-size: var(--font-size-large);
  font-weight: var(--font-weight-medium);
  line-height: 22px;
}
.also-discussed-in-container .skeleton-container {
  padding: 20px;
}
.also-discussed-in-container .post-item-container .parent-name {
  color: var(--mi-blue-color-13);
  font-size: var(--font-size-tiny);
  font-weight: var(--font-weight-medium);
  line-height: 20px;
  padding-left: 20px;
  padding-top: 10px;
}
.also-discussed-in-container .post-item-container.top-border {
  border-top: 1.5px solid var(--mi-background-color);
}

.view-comments-button-container {
  padding: 0 var(--post-card-padding-end) 10px var(--post-card-padding-start);
}
.view-comments-button-container button {
  outline: none;
  background: none;
  margin: 0;
  padding: 0;
}
.view-comments-button-container button span {
  font-weight: var(--font-weight-regular);
  font-style: normal;
  font-size: var(--font-size-tiny);
  color: var(--mi-primary-color);
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
