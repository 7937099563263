// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  --side-padding: 20px;
}

.case-page-container {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: min-content auto;
  grid-template-columns: 100%;
}
.case-page-container .header {
  grid-row: 1;
  grid-column: 1;
}
.case-page-container ion-content {
  grid-row: 2;
  grid-column: 1;
}
.case-page-container.mobile-view {
  grid-template-rows: min-content auto min-content;
}
.case-page-container.mobile-view .bottom-bar-container {
  grid-row: 3;
  grid-column: 1;
  padding-top: 4px;
}

.loading-container {
  padding: 20px;
}

.content {
  padding: 15px 0;
}
.content .case-header-container {
  padding: 0 var(--side-padding);
}
.content .case-header-container.bottom-padding {
  padding-bottom: 15px;
}
.content .shared-with-container {
  padding: 0 var(--side-padding);
}
.content .shared-with-container app-clinical-case-shared-with-slider ::ng-deep .clinical-case-shared-with-slider-container .title-and-actions-container .title-container .wrapper .title {
  color: var(--mi-black-color);
}
.content .add-images-or-details,
.content .reply-give-feedback {
  --border-color: var(--mi-primary-color);
  --background: var(--mi-blue-color-4);
  --color: --mi-secondary-color-2;
  --box-shadow: none;
  --padding-start: 16px;
  --padding-end: 16px;
  text-transform: none;
  margin-top: 24px;
  border-radius: 8px;
  overflow: hidden;
}
.content .reply-give-feedback {
  margin-left: var(--side-padding);
  margin-right: var(--side-padding);
}
.content .reply-give-feedback .text-container {
  width: 100%;
  display: flex;
}
.content .reply-give-feedback .text-container ion-icon {
  color: var(--mi-black-color-2);
}
.content .reply-give-feedback .text-container span.text {
  padding-left: 12px;
}
.content .add-images-or-details {
  height: 100%;
  margin-left: var(--side-padding);
  margin-right: var(--side-padding);
}
.content .add-images-or-details .btn-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px 0;
}
.content .add-images-or-details .btn-wrapper app-icon {
  --icon-size: 25px;
  padding-right: 15px;
  color: var(--mi-grey-color-13);
}
.content .description-images-wrapper {
  padding-top: 30px;
  padding-bottom: 30px;
  padding-left: 15px;
  padding-right: 15px;
  background-color: var(--mi-grey-color-20);
  margin: 24px var(--side-padding) 0px;
}
.content .description-images-wrapper .section-title-container .section-title {
  color: var(--mi-black-color);
  font-size: var(--font-size-regular);
  font-weight: var(--font-weight-medium);
  margin: 0;
}
.content .description-images-wrapper .case-description-container app-clinical-case-description {
  margin-top: 16px;
}
.content .description-images-wrapper .case-description-container app-clinical-case-description ::ng-deep .card {
  background-color: var(--mi-white-color);
}
.content .description-images-wrapper .case-description-container:first-child app-clinical-case-description ::ng-deep .card {
  margin-top: 0;
}
.content .description-images-wrapper .clinical-case-images-wrapper {
  display: grid;
  grid-template-columns: 100%;
  grid-auto-flow: row;
  grid-auto-rows: min-content;
}
.content .description-images-wrapper .clinical-case-images-wrapper app-clinical-case-images {
  padding: 0 var(--side-padding);
  margin-top: 10px;
  background-color: var(--mi-white-color);
  padding-top: 15px;
  padding-bottom: 10px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}
.content .description-images-wrapper .clinical-case-images-wrapper app-create-edit-case-post-bottom-bar ::ng-deep .create-edit-case-post-bottom-bar-container {
  box-shadow: none;
}
.content .description-images-wrapper .clinical-case-images-wrapper app-create-edit-case-post-bottom-bar ::ng-deep .create-edit-case-post-bottom-bar-container .bottom-bar {
  background-color: var(--mi-light-purple-1-color);
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}
.content .documents-container {
  padding: 30px var(--side-padding) 0 var(--side-padding);
}
.content .documents-container app-case-documents-upload-edit-preview ::ng-deep .case-documents-upload-edit-preview-container .title-container .title {
  color: var(--mi-black-color);
  font-size: var(--font-size-regular);
  font-weight: var(--font-weight-medium);
}
.content .sr-container {
  padding: 27px var(--side-padding) 35px var(--side-padding);
  margin-top: 20px;
  background-color: var(--mi-light-purple-1-color);
  border-radius: 2px;
  margin-left: var(--side-padding);
  margin-right: var(--side-padding);
}
.content .sr-container app-surgery-reference-review-container ::ng-deep {
  display: block;
}
.content .sr-container app-surgery-reference-review-container ::ng-deep .header-container .title {
  color: var(--mi-black-color);
  font-size: var(--font-size-regular);
  font-weight: var(--font-weight-medium);
}
.content .sr-container app-surgery-reference-review-container ::ng-deep .header-container .add-module .text {
  color: var(--mi-black-color);
  font-size: var(--font-size-regular);
  font-weight: var(--font-weight-medium);
}
@media all and (max-width: 560px) {
  .content {
    padding-bottom: 0;
  }
  .content .sr-container,
  .content .description-images-wrapper {
    margin: 20px 0px 0px 0px;
  }
  .content .documents-container {
    padding-bottom: 20px;
  }
}

.actions-btn {
  color: var(--mi-secondary-color-2);
}

.user-placeholder-tile {
  padding-top: 15px;
}

.hidden {
  display: none;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
