import { AppConfigInterface, Language, NOT_USED, TabIds, TabType } from '../config.model';
import { default as cmfContext } from '../config-context/cmf-config';
import { default as traumaContext } from '../config-context/trauma-config';
import { default as reconContext } from '../config-context/recon-config';
import { default as spineContext } from '../config-context/spine-config';
import { default as vetContext } from '../config-context/vet-config';
import { professions } from './professions.config';
import { packageConfig } from '../package.json.config';

export default class ApplicationConfig implements AppConfigInterface {
  name = 'MyAO';
  contexts = [cmfContext, traumaContext, reconContext, spineContext, vetContext];
  defaultLanguage = Language.EN;
  requestTimeout = 20000;
  logoImageName = 'app.svg';
  cookieConsentDomain = NOT_USED;
  cookieConsentExpiresDays = 365;
  cookieConsentPalette = {
    popup: {
      background: '#dce8fd',
      text: '#888888',
      link: '#3B7FF6'
    },
    button: {
      background: '#3b7ff6',
      text: '#ffffff',
      border: 'transparent'
    }
  };
  inAppBrowserColors = {
    toolbarcolor: '#00195c',
    closebuttoncolor: '#ffffff'
  };
  eventMessageSenderGroupNames = ['root', 'event'];
  videoChannels = ['videos', 'tclicVideos', 'newsletterVideos', 'kalturaVideo'];

  appUpdateFieldSuffixName = 'resources-update-suffix';
  appUpdateCheckDelay = 5000;

  // will be used in next release
  // authMainProfessionKeys = ['Surgeon/Physician', 'Resident/Fellow', 'Physician Assistant/Nurse Practitioner'];
  authMainProfessionKeys = ['Surgeon/Physician', 'Resident/Fellow', 'Medical Student'];
  link = {
    termsOfUseUrl: 'https://www.aofoundation.org/disclaimer',
    contact: 'https://www.aofoundation.org/who-we-are/contact',
    appStore: 'https://apps.apple.com/ch/app/insights-orthopedics/id580462911',
    playStore: 'https://play.google.com/store/apps/details?id=md.insights.ort.rsp',
    accessBlockedHelpUrl: 'https://www.aofoundation.org/who-we-are/contact',
    feedbackUrl: packageConfig.feedbackUrl,
    helpCenter: 'https://welcome.myao.app/welcome/help/',
    qrCodePopup: 'https://onelink.to/myao?utm_source=Web&utm_medium=PopUp&utm_campaign=myAOPopup&utm_id=myAOPopup',
    qrCodeButton: 'https://onelink.to/myao?utm_source=Web&utm_medium=Button&utm_campaign=myAObutton&utm_id=myAObutton'
  };

  groupNameMaxChars = 50;
  groupDescriptionMaxChars = 500;
  groupModeratorsMaxNum = 15;

  maxNumberVerticalItems = 10;

  professions = professions;
  deepLinks = [
    '/auth-v2-ao-redirect',
    '/auth',
    '/marketing/:targetPlace',
    '/aoevent/:aoEventId',
    '/verify',
    '/auth-orcid-redirect',
    '/ao-auth-signup'
  ];
  contextDeepLinks = [
    '<%= homePath %>/activity',
    '<%= homePath %>/article/:articleId',
    '<%= homePath %>/event/:eventId',
    '<%= homePath %>/events',
    '<%= homePath %>/group/:groupId',
    '<%= homePath %>/group/public/:groupId',
    '<%= homePath %>/group/:groupId/post/:postId',
    '<%= homePath %>/group/case/:caseId',
    '<%= homePath %>/group/post/:postId',
    '<%= homePath %>/event/:eventId/post/:postId',
    '<%= homePath %>/event/case/:caseId',
    '<%= homePath %>/event/post/:postId',
    '<%= homePath %>/journal/:journalId',
    '<%= homePath %>/user/:userId',
    '<%= homePath %>/casefolio',
    '<%= homePath %>/case/:caseId',
    '<%= homePath %>/contacts',
    '<%= homePath %>/feed',
    '<%= homePath %>/groups',
    '<%= homePath %>/messenger',
    '<%= homePath %>/messenger/:connectionType/:chatId',
    '<%= homePath %>/messenger/:chatId',
    // new chat route
    '<%= homePath %>/chat',
    '<%= homePath %>/chat/:connectionType/:chatId',
    '<%= homePath %>/chat/:chatId',
    '<%= homePath %>/network/my-journals',
    '<%= homePath %>/public/profile/:id',
    '<%= homePath %>/wrapper/article/:articleId',
    '<%= homePath %>/wrapper/group/:groupId',
    '<%= homePath %>/wrapper/group/public/:groupId',
    '<%= homePath %>/wrapper/group/:groupId/post/:postId',
    '<%= homePath %>/wrapper/event/:eventId/post/:postId',
    '<%= homePath %>/wrapper/journal/:journalId',
    '<%= homePath %>/wrapper/user/:userId',
    '<%= homePath %>/wrapper/messenger/:chatId',
    '<%= homePath %>/wrapper/chat/:chatId',
    '<%= homePath %>/wrapper/public/profile/:id',
    '<%= homePath %>/joinmyao/:objectType/:objectId',
    '<%= homePath %>/wrapper/joinmyao/:objectType/:objectId',
    '<%= homePath %>/journals/latest-publications',
    '<%= homePath %>/verify',
    '<%= homePath %>/co-contributors',
    '<%= homePath %>/knowledge/orcid-publications',
    '<%= homePath %>/knowledge/video-hub',
    '<%= homePath %>/knowledge/saved',
    '<%= homePath %>/wrapper/co-contributors',
    '<%= homePath %>/knowledge',
    '<%= homePath %>/post/:postId',
    '<%= homePath %>/shared/case/:caseId',
  ];
  tabsConfig = [
    {
      id: TabIds.FEED,
      label: 'Feed',
      route: 'feed',
      icon: 'md-icon-house',
      type: TabType.DEFAULT
    },
    {
      id: TabIds.CASEFOLIO,
      label: 'CaseFolio',
      route: 'casefolio',
      routeRe: /casefolio\/*|^(?!.*\/group\/case\/\/*).*\/case\/\/*/,
      icon: 'md-icon-case-folio',
      type: TabType.DEFAULT
    },
    {
      id: TabIds.GROUPS,
      label: 'Groups',
      route: 'groups',
      routeRe: /group\/*/,
      icon: 'md-icon-participants',
      type: TabType.DEFAULT
    },
    {
      id: TabIds.CONTACTS,
      label: 'Contacts',
      route: 'contacts',
      icon: 'md-icon-my-network',
      type: TabType.DEFAULT
    },
    {
      id: TabIds.ACTIVITY,
      label: 'Activity',
      route: 'activity',
      icon: 'md-icon-notification',
      type: TabType.DEFAULT
    },
    {
      id: TabIds.SEARCH,
      label: 'Search',
      route: 'knowledge',
      icon: 'md-icon-magnifier',
      type: TabType.DEFAULT
    }
  ];
  scripts = [];
  streamPagination = 20;
  clinicalCasesPagination = 10;
  oneSignalPushNotification = {
    appId: packageConfig.oneSignal.appId,
    firebaseProjectNumber: packageConfig.oneSignal.firebaseProjectNumber,
    triggerKey: packageConfig.oneSignal.triggerKey,
    triggerValue: packageConfig.oneSignal.triggerValue
  };
  aiSurgeryReferenceApiKey = packageConfig.aiSurgeryReferenceApiKey;
  webSocketReconnectAttemptInterval = 10000; // 10 sec

  defaultProfileImageUrl = 'https://logindev.insights.md/api/ionic/profile/defaultImage';
}
