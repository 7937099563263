import { Component, ElementRef, Inject, Input, ViewChild } from '@angular/core';
import { ModalController } from '@ionic/angular';

// services
import { ConnectionsApiService } from 'src/app/services/messenger/connections-api.service';
import { CONTEXT_SERVICE, ContextService } from 'src/app/services/context/context.model';
import { UIUtilsServiceInterface, UI_UTILS_SERVICE } from 'src/app/services/utils/ui-utils.service.interface';
import { Connection } from 'src/app/services/yeti-protocol/connections';
import { ConfirmDialogData } from 'src/app/services/dialogs/dialogs.ui.interface';
import { DialogsUIService } from 'src/app/services/dialogs/dialogs.ui.service';
import { Store } from '@ngxs/store';
import { ActiveConversations, ArrayLocation } from '../../../state/active-conversations/active-conversations.actions';
import { PeopleYouMayKnow } from 'src/app/state/people-you-may-know/people-you-may-know.actions';

@Component({
  selector: 'app-send-new-connection-request-dialog',
  templateUrl: './send-new-connection-request-dialog.component.html',
  styleUrls: ['./send-new-connection-request-dialog.component.scss']
})
export class SendNewConnectionRequestDialogComponent {

  @ViewChild('messageInputContainer') messageInputContainer: ElementRef;

  @Input() firstName: string;
  @Input() lastName: string;
  @Input() userId: string;
  @Input() source: string;

  content = '';
  messageLimit = 500;

  constructor(
    private modalController: ModalController,
    private dialogs: DialogsUIService,
    private connectionsApiService: ConnectionsApiService,
    @Inject(CONTEXT_SERVICE) private contextService: ContextService,
    @Inject(UI_UTILS_SERVICE) private uiUtilsService: UIUtilsServiceInterface,
    private store: Store
  ) { }

  onClose(connectionSent?: boolean): void {
    this.modalController.dismiss(connectionSent);
  }

  sendConnectionRequest(): void {
    this.connectionsApiService.requestConnection(this.userId, this.content, this.source).then((connection: Connection) => {
      this.store.dispatch(new ActiveConversations.AddNewConversationFromConversationId(connection?._id, ArrayLocation.START));
      this.store.dispatch(new PeopleYouMayKnow.DisableConnectButtonForUser(this.userId));
      this.onClose(true);
    }).catch(errData => {
      if (errData.error.error.info === '{"connection":"Restricted to ao members"}') {
        this.onClose();
        this.showNonAoMemberConnectionErrorDialog();
      } else {
        this.dialogs.showErrorDialog(errData?.error?.error?.info);
      }
    })
  }

  async showNonAoMemberConnectionErrorDialog(): Promise<void> {
    const okActionKey = 'ok';
    const cancelBackActionKey = 'cancel';

    const confirmSelectLanguage: ConfirmDialogData = {
      componentProps: {
        title: {
          translationKey: 'app.messenger.new-message-connect-non-ao-member-error-dialog-title'
        },
        message: {
          translationKey: 'app.messenger.new-message-connect-non-ao-member-error-dialog-text'
        },
        actions: [
          {
            key: cancelBackActionKey,
            label: {
              translationKey: 'app.messenger.new-message-connect-non-ao-member-error-dialog-cancel-button'
            },
            className: 'secondary'
          },
          {
            key: okActionKey,
            label: {
              translationKey: 'app.messenger.new-message-connect-non-ao-member-error-dialog-ok-button'
            },
            className: 'primary'
          }
        ]
      }
    }

    const confirmModal = await this.dialogs.createConfirmDialog(confirmSelectLanguage);
    confirmModal.present();

    try {
      const res = await confirmModal.onDidDismiss();

      if (res && res?.data.actionKey === okActionKey) {
        this.uiUtilsService.onBecomeAOMember(this.contextService?.currentContext?.aoMembershipUrl);
      }
    } catch (err) {
      console.log(err);
    }
  }

}
