import { Component, EventEmitter, OnInit, Input, Output } from '@angular/core';
import { ResponseStreamItem } from 'src/app/services/yeti-protocol/my-stream';
import { ActionSource } from 'src/app/services/yeti-protocol/tracking';
import { StreamItemType } from 'src/app/services/yeti-protocol/utils/enums';

@Component({
  selector: 'app-knowledge-stream-items',
  templateUrl: './knowledge-stream-items.component.html',
  styleUrls: ['./knowledge-stream-items.component.css']
})
export class KnowledgeStreamItemsComponent implements OnInit {

  @Input() items: Array<ResponseStreamItem>;
  @Input() loading: boolean;
  @Input() visibilityRootKey: boolean;
  @Input() awsPersonalisationId: string;

  @Output() marketingTeaserCardClicked: EventEmitter<void> = new EventEmitter();
  @Output() openJournal: EventEmitter<string> = new EventEmitter();

  StreamItemType = StreamItemType;
  ActionSource = ActionSource;

  constructor() { }

  ngOnInit(): void { }

}
