import { Component, EventEmitter, OnInit, Input, Output } from '@angular/core';
import { OrcIdArticle } from 'src/app/services/yeti-protocol/article';
import { ResponseStreamItem } from 'src/app/services/yeti-protocol/my-stream';
import { ActionSource } from 'src/app/services/yeti-protocol/tracking';
import { StreamItemType } from 'src/app/services/yeti-protocol/utils/enums';

@Component({
  selector: 'app-network-stream-items',
  templateUrl: './network-stream-items.component.html',
  styleUrls: ['./network-stream-items.component.css']
})
export class NetworkStreamItemsComponent implements OnInit {

  @Input() items: Array<ResponseStreamItem>;
  @Input() loading: boolean;
  @Input() visibilityRootKey: boolean;
  @Input() awsPersonalisationId: string;

  @Output() marketingTeaserCardClicked: EventEmitter<void> = new EventEmitter();
  @Output() postDeleted: EventEmitter<string> = new EventEmitter();

  StreamItemType = StreamItemType;
  ActionSource = ActionSource;

  constructor() { }

  ngOnInit(): void { }

  getOrcIdArticleId(orcIdArticle: OrcIdArticle): string {
    return orcIdArticle?._id || orcIdArticle?.id || orcIdArticle?.origin || '';
  }

}
