import { Component, Inject, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { IonInfiniteScroll, ModalController } from '@ionic/angular';
import {
  ItemTypes,
  RecommendationsBookmarksService
} from '../../../services/recommendations-service/recommendations-bookmarks.service';
import { ToastService } from '../../../services/toast.service';
import { AOVideo, Article, Video } from '../../../services/yeti-protocol/article';
import { Group } from '../../../services/groups/group.model';
import { InfoSheetService } from '../../../modules/info-sheet/services/info-sheet.service';
import { ResponsiveUtilsService } from '../../../services/utils/responsive-utils.service';
import { ItemType, TopRecommendationType } from '../../../services/yeti-protocol/utils/enums';
import { RecommendationsBookmarksResponseResultObject } from 'src/app/services/yeti-protocol/recommendations-bookmarks';
import { InfoSheetActionItem } from 'src/app/modules/info-sheet/models/info-sheet-action-item.model';
import { TargetImpression, VisibilityTrackerService } from 'src/app/modules/visibility-tracker/visibility-tracker.service';
import { TRACKING_SERVICE, TrackingService } from 'src/app/services/tracking/tracking.model';
import { Subscription } from 'rxjs';
import { ImpressionTrackingRequest } from 'src/app/services/yeti-protocol/tracking';

type SelectedItem = Article | Video | AOVideo | Group;

@Component({
  selector: 'app-all-recommendations-list',
  templateUrl: './all-recommendations-list.component.html',
  styleUrls: ['./all-recommendations-list.component.scss'],
})
export class AllRecommendationsListComponent implements OnInit, OnDestroy {

  @ViewChild(IonInfiniteScroll) infiniteScroll: IonInfiniteScroll;
  @Input() userId: string;
  @Input() recommendedBy: string;
  recommendationItems: Array<RecommendationsBookmarksResponseResultObject> = [];
  infoSheetId = 'all-recommendations-list-actions';
  TopRecommendationType = TopRecommendationType;
  private selectedItem: SelectedItem;
  private readonly count = 15;
  private readonly start = 0;
  readonly visibilityRootKey = 'public-profile-recommended-items-stream';

  private recommendationsTrackingSubscription: Subscription;

  constructor(
    private infoSheetService: InfoSheetService,
    private modalController: ModalController,
    private recommendationsService: RecommendationsBookmarksService,
    private responsiveUtilsService: ResponsiveUtilsService,
    private toast: ToastService,
    private visibilityTrackerService: VisibilityTrackerService,
    @Inject(TRACKING_SERVICE) private trackingService: TrackingService,
  ) { }

  ngOnInit(): void {
    this.getRecommendationItems(ItemTypes.RECOMMENDATIONS, this.start, this.count, this.userId);
    this.infoSheetId = this.infoSheetService.generateNewInfoSheetIdIfDuplicate(this.infoSheetId);

    this.recommendationsTrackingSubscription = this.visibilityTrackerService.impressionsAsObservable.subscribe(targetImression => {
      this.trackListItemImpression(targetImression);
    });
  }

  ngOnDestroy(): void {
    this.recommendationsTrackingSubscription?.unsubscribe();
  }

  isItemArticle(item: Article | Video | Group): boolean {
    return (item as Article).type === ItemType.article;
  }

  onClose(): void {
    this.modalController.dismiss();
  }

  getRecommendationItems(itemType: ItemTypes, start: number, count: number, userId?: string): void {
    this.recommendationsService.getItemsList(itemType, start, count, userId)
      .then(response => {
        if (response && response.success) {
          const recommendationItems = response.result;
          // console.log(recommendationItems)
          this.recommendationItems = this.recommendationItems.concat(recommendationItems);
        }
      })
  }

  loadData(): void {
    if (this.recommendationItems.length < this.count) {
      this.disableInfiniteScroll(true);
      return;
    }

    const start = Math.floor(this.recommendationItems.length / this.count);
    this.getRecommendationItems(ItemTypes.RECOMMENDATIONS, start, this.count);
    // Hide Infinite List Loader on Complete
    this.infiniteScroll.complete();
  }

  get isDesktop(): boolean {
    return this.responsiveUtilsService.isDesktop;
  }

  async presentActionSheet(item: SelectedItem, event: Event): Promise<void> {
    this.selectedItem = item;
    if (this.isDesktop) {
      this.infoSheetService.openWeb(this.infoSheetActions, event)
        .then(action => {
          if (action && action.code) {
            action.handler();
          }
        });
    } else {
      this.infoSheetService.open(this.infoSheetId);
    }
  }

  get infoSheetActions(): Array<InfoSheetActionItem> {
    return [
      {
        id: 'remove-recommendation',
        icon: 'md-icon-bin',
        textKey: 'app.common.delete',
        code: 'DELETE_ITEM',
        handler: () => {
          this.deleteItem();
        }
      }
    ]
  }

  deleteItem(): void {
    const itemType = 'type' in this.selectedItem ? this.selectedItem.type : 'group';
    this.recommendationsService
      .removeRecommendationBookmarkItem(itemType, ItemTypes.RECOMMENDATIONS, this.selectedItem._id)
      .then(response => {
        if (response && response.success) {
          const index = this.recommendationItems.findIndex(x => x.item._id === this.selectedItem._id);
          this.recommendationItems.splice(index, 1);
          this.getRecommendationItems(ItemTypes.RECOMMENDATIONS, this.start, this.count);
          this.toast.show('app.recommendedItems.item-deleted');
        }
      })
  }

  private disableInfiniteScroll(value: boolean): void {
    if (this.infiniteScroll) {
      this.infiniteScroll.disabled = value;
    }
  }

  trackListItemImpression(targetImpression: TargetImpression): void {

    if (targetImpression.rootKey !== this.visibilityRootKey) {
      return;
    }

    if (!targetImpression?.targetKey?.length) {
      return;
    }

    const itemObj = this.recommendationItems.find(i => (i.item as any)?._id === targetImpression?.targetKey ||
      (i.item as any)?.id === targetImpression?.targetKey);

    if (!itemObj) {
      return;
    }

    const impressionTrackingRequest: ImpressionTrackingRequest = {
      objectId: targetImpression?.targetKey,
      objectType: itemObj?.type,
    };

    impressionTrackingRequest.source = 'publicProfileRecommendationStream';

    const objectTitle = (itemObj.item as any)?.title;

    if (objectTitle) {
      impressionTrackingRequest.objectTitle = objectTitle;
    }

    this.trackingService.trackListItemImpression(impressionTrackingRequest);
  }
}
