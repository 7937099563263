// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.gradient-wrapper {
  width: 100%;
  border-radius: 15px;
  background: linear-gradient(to right, #3B7FF6, #20BDFF, #A5FECB);
  display: flex;
  justify-content: center;
  align-items: center;
}
.gradient-wrapper .shared-object {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: auto min-content;
  grid-template-rows: auto;
  -moz-column-gap: 10px;
       column-gap: 10px;
  align-items: center;
  padding: 12.5px;
  border-radius: 13px;
  background-color: var(--mi-white-color);
  margin: 3px;
}
.gradient-wrapper .shared-object .wrapper {
  grid-row: 1;
  grid-column: 1;
  height: 100%;
}
.gradient-wrapper .shared-object .wrapper .chip-container {
  display: grid;
  grid-template-rows: auto;
  grid-auto-flow: column;
  grid-auto-columns: min-content;
  -moz-column-gap: 5px;
       column-gap: 5px;
  align-items: center;
  padding: 5px 10px;
  border-radius: 15px;
  background-color: var(--mi-purple-color-2);
  max-width: 130px;
  margin-bottom: 10px;
}
.gradient-wrapper .shared-object .wrapper .chip-container .chip-icon {
  color: var(--mi-purple-color);
  --icon-size: 12px;
}
.gradient-wrapper .shared-object .wrapper .chip-container .chip-text {
  margin: 0;
  color: var(--mi-purple-color);
  font-size: var(--font-size-tiny);
  font-weight: var(--font-weight-regular);
  white-space: nowrap;
}
.gradient-wrapper .shared-object .wrapper .text-container {
  display: grid;
  grid-template-columns: 100%;
  grid-auto-flow: row;
  grid-auto-rows: min-content;
  row-gap: 5px;
}
.gradient-wrapper .shared-object .wrapper .text-container .shared-object-title {
  --multiline-text-with-ellipsis-max-height: 42px;
}
.gradient-wrapper .shared-object .wrapper .text-container .shared-object-title ::ng-deep {
  font-weight: var(--font-weight-medium);
  font-size: var(--font-size-large);
  color: var(--mi-secondary-color-2);
}
.gradient-wrapper .shared-object .image {
  position: relative;
  grid-row: 1;
  grid-column: 2;
  height: 75px;
  width: 75px;
  --border-radius: 5px;
}
.gradient-wrapper .shared-object .image app-image ::ng-deep div, .gradient-wrapper .shared-object .image app-image ::ng-deep div ion-img, .gradient-wrapper .shared-object .image app-image ::ng-deep div ion-img::part(image) {
  height: 75px;
  width: 75px;
  object-fit: cover;
  border-radius: 5px;
}
.gradient-wrapper .shared-object .image .image-overlay {
  position: absolute;
  top: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--mi-white-color);
  font-size: var(--font-size-regular);
  line-height: var(--font-line-height-regular);
  font-weight: var(--font-weight-medium);
  font-style: normal;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
