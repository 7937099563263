import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, firstValueFrom } from 'rxjs';

// models
import { toAuthRequestParams } from './auth/logic/auth-logic.utils';
import {
  RecentSearchesParams,
  RecentSearchesResponse,
  SearchCluster,
  SearchParams,
  SearchResponse,
  SearchResultIdAll,
  SearchSuggestListParams,
  SearchSuggestListResponse,
  SearchSuggestListSuccessResponse,
  SearchTrackingSource,
  VideoSearch
} from './yeti-protocol/search';
import { ArticleSearch } from './yeti-protocol/search';

// services
import { AuthService } from './auth/auth.service';
import { SchemaValidatorService } from './schema-validator.service';
import { SearchUiService } from './search-ui-service.service';
import { ExternalArticleService } from './article/external-article.service';
import { CONTEXT_SERVICE, ContextService } from './context/context.model';

import appConfig from 'src/config/config';

export interface SearchServiceConfig {
  serverUrl: string;
}

@Injectable({
  providedIn: 'root'
})
export class SearchService {

  config: SearchServiceConfig = {
    serverUrl: `${appConfig.backendUrlIonic}search`
  }
  suggestList = new BehaviorSubject<Array<string>>(null);

  private defaultStart = 0;
  private defaultSearchAllItemsCount = 3;

  constructor(
    private authService: AuthService,
    private schemaValidator: SchemaValidatorService,
    @Inject(CONTEXT_SERVICE) private contextService: ContextService,
    private searchUiService: SearchUiService,
    private externalArticleService: ExternalArticleService
  ) { }

  search(
    searchTerm: string,
    sourceOfSearch: SearchTrackingSource,
    type: string = SearchResultIdAll,
    start: number = this.defaultStart,
    count: number = this.defaultSearchAllItemsCount): Promise<SearchResponse> {

    // clientVersion = 3.0 -> value can be changed as drywall only checks if present, if yes, returns events.
    // clientVersion = 4.0 -> if present, drywall returns events + federated search.
    const params: SearchParams = {
      query: searchTerm,
      type: type,
      start,
      count,
      appId: this._appId,
      clientVersion: '4.0'
    };

    if (type === SearchResultIdAll && sourceOfSearch) {
      params.source = sourceOfSearch;
    }

    return firstValueFrom(this.authService.secureGet(`${this.config.serverUrl}`, { params: toAuthRequestParams(params) }).pipe(
      this.schemaValidator.isValidOperator<SearchResponse>('SearchResponse')
    ));
  }

  getUrlBasedOnSearchResultType(type: string, searchTerm: string): string {

    if (!type) {
      return null;
    }

    const homeUrl = this.contextService.currentContext.homePath;
    return `${homeUrl}/search?searchTerm=${searchTerm}&resultId=${type}`;
  }

  getSuggestList(query: string): Promise<Array<string>> {
    const requestUrl = `${this.config.serverUrl}/autosuggest`;

    const params: SearchSuggestListParams = {
      appId: this._appId,
      query: query
    }

    return new Promise((resolve) => {
      this.authService.secureGet(requestUrl, { params: toAuthRequestParams(params) }).pipe(
        this.schemaValidator.isValidOperator<SearchSuggestListResponse>('SearchSuggestListResponse')
      ).subscribe((response: SearchSuggestListResponse) => {
        const suggestList: Array<string> = (response as SearchSuggestListSuccessResponse).result;
        return resolve(suggestList);
      });
    });
  }

  async getRecentSearches(): Promise<RecentSearchesResponse> {

    const url = `${this.config.serverUrl}/recent/v2`;

    const params: RecentSearchesParams = {
      appId: this._appId
    };

    const recentSearchesPromise = firstValueFrom(this.authService.secureGet(url, { params: toAuthRequestParams(params) }).pipe(
      this.schemaValidator.isValidOperator<RecentSearchesResponse>('RecentSearchesResponse')
    ));

    try {
      await recentSearchesPromise;
      this.searchUiService.emitRecentSearchTermsRequestFinished();
    } catch {
      this.searchUiService.emitRecentSearchTermsRequestFinished();
    }

    return recentSearchesPromise;
  }

  initSearchClustersMap(searchClustersMap: Map<string, SearchCluster>, searchClusters: Array<SearchCluster>): Map<string, SearchCluster> {

    if (!searchClustersMap) {
      searchClustersMap = new Map<string, SearchCluster>();
    }

    if (!searchClusters?.length) {
      return searchClustersMap;
    }

    searchClusters.forEach((searchCluster: SearchCluster) => {

      const searchClusterOld = searchClustersMap.get(searchCluster?.title);

      if (searchClusterOld) {
        const itemIds = [...searchClusterOld.recordIds, ...searchCluster.recordIds];
        searchClustersMap.set(searchCluster?.title, { title: searchCluster?.title, recordIds: itemIds });
      } else {
        searchClustersMap.set(searchCluster?.title, searchCluster);
      }
    });

    return searchClustersMap;
  }

  findIdForArticle(article: ArticleSearch | VideoSearch, contextKey: string): Promise<ArticleSearch | VideoSearch> {
    return this.externalArticleService.findIdForArticle(article, contextKey).then(articleId => {
      article._id = articleId;
      return article;
    });
  }

  get _appId(): string {
    return this.contextService.currentContext.key;
  }
}
