import { Component, Input, OnDestroy, OnInit, Inject } from '@angular/core';
import { AppTranslationService } from '../../services/app-translation.service';
import { CustomTab } from 'src/app/components/custom-tabs/custom-tabs.component';
import {
  Case,
  ParentType,
  Post,
  PostType,
  UserRecentPublicContributionsResponse,
  UserRecentPublicContributionsSuccessResponse
} from '../../services/yeti-protocol/chatter-api';
import { UserProfile } from '../../services/yeti-protocol/auth/mi';
import { Subscription } from 'rxjs';
import { IonRefresher, ModalController } from '@ionic/angular';
import { ToastMode, ToastService } from '../../services/toast.service';
import { AuthService } from '../../services/auth/auth.service';
import { ChatterApiService, UserRecentPublicContributionsFilter } from '../../services/chatter-api.service';
import { TargetImpression, VisibilityTrackerService } from '../../modules/visibility-tracker/visibility-tracker.service';
import { TRACKING_SERVICE, TrackingService } from 'src/app/services/tracking/tracking.model';
import { VerticalListLoadMoreData } from 'src/app/components/vertical-list/vertical-list.component';
import { ActionSource, ImpressionTrackingRequest } from '../../services/yeti-protocol/tracking';
import { AppNavController } from 'src/app/services/app-nav-controller.service';

export enum TabIds {
  cases = 'cases',
  posts = 'posts'
}

enum ImpressionsTrackingList {
  CASES = 'user-recent-public-contributions-cases-impressions-tracking-root',
  POSTS = 'user-recent-public-contributions-posts-impressions-tracking-root'
}

@Component({
  selector: 'app-user-recent-public-contributions-dialog',
  templateUrl: './user-recent-public-contributions-dialog.component.html',
  styleUrls: ['./user-recent-public-contributions-dialog.component.scss'],
})
export class UserRecentPublicContributionsDialogComponent implements OnInit, OnDestroy {

  @Input() userId: string;

  customTabs: Array<CustomTab> = [];
  activeTabId: string;
  TabIds = TabIds;
  cases: Array<Case> = [];
  posts: Array<Post> = [];
  loading: boolean;
  totalItemsCount: number;
  userProfile: UserProfile;
  ImpressionsTrackingList = ImpressionsTrackingList;
  readonly count = 10;
  private userProfileSubscription: Subscription;
  private itemsTrackingSubscription: Subscription;

  constructor(
    private modalController: ModalController,
    private appTranslationService: AppTranslationService,
    private chatterApiService: ChatterApiService,
    private toast: ToastService,
    private authService: AuthService,
    private visibilityTrackerService: VisibilityTrackerService,
    @Inject(TRACKING_SERVICE) private trackingService: TrackingService,
    private appNavController: AppNavController,
  ) { }

  ngOnInit(): void {
    this.setActiveTab(TabIds.cases);
    this.generateTabs();

    this.userProfileSubscription = this.authService.userProfileAsObservable.subscribe((userProfile: UserProfile) => {
      this.userProfile = userProfile;
    });

    this.itemsTrackingSubscription = this.visibilityTrackerService.impressionsAsObservable.subscribe(targetImression => {
      this.trackListItemImpression(targetImression);
    });
  }

  ngOnDestroy(): void {
    this.userProfileSubscription?.unsubscribe();
    this.itemsTrackingSubscription?.unsubscribe();
  }

  getUserRecentPublicContributions(filter: UserRecentPublicContributionsFilter, start = 0): Promise<UserRecentPublicContributionsResponse> {

    this.loading = true;

    const promise = (this.userId
      ? this.chatterApiService.getUserRecentPublicContributions(start, this.count, this.userId, filter)
      : this.chatterApiService.getUserRecentPublicContributions(start, this.count, undefined, filter));

    promise.then(response => {

      if (response && response.success) {

        response = response as UserRecentPublicContributionsSuccessResponse;
        this.totalItemsCount = response.totalItemsCount || 0;
        const userRecentPublicContributions = response?.result || [];

        switch (filter) {
          case UserRecentPublicContributionsFilter.CASE:

            if (!this.cases) {
              this.cases = [];
            }

            this.cases = [...this.cases, ...userRecentPublicContributions.map(res => res?.item as Case)];
            break;
          case UserRecentPublicContributionsFilter.POST:

            if (!this.posts) {
              this.posts = [];
            }

            this.posts = [...this.posts, ...userRecentPublicContributions.map(res => res?.item as Post)];
            break;
        }
      }
    }).catch(err => {
      console.error(err);
      this.showError(err?.error?.error?.message?.errfor?.message);
    }).finally(() => {
      this.loading = false;
    });

    return promise;
  }

  async refreshUserRecentPublicContributions(refresher: IonRefresher): Promise<void> {

    this.cases = [];
    this.posts = [];
    this.totalItemsCount = 0;

    let promise;

    switch (this.activeTabId) {
      case TabIds.cases:
        promise = this.getUserRecentPublicContributions(UserRecentPublicContributionsFilter.CASE);
        break;
      case TabIds.posts:
        promise = this.getUserRecentPublicContributions(UserRecentPublicContributionsFilter.POST);
        break;
    }

    promise?.finally(() => {
      refresher.complete();
    });
  }

  async loadMoreUserRecentPublicContributions(verticalListLoadMoreData: VerticalListLoadMoreData): Promise<void> {

    let start = 0;
    let promise;

    switch (this.activeTabId) {
      case TabIds.cases:
        start = Math.floor(this.cases?.length / this.count);
        promise = this.getUserRecentPublicContributions(UserRecentPublicContributionsFilter.CASE, start);
        break;
      case TabIds.posts:
        start = Math.floor(this.posts?.length / this.count);
        promise = this.getUserRecentPublicContributions(UserRecentPublicContributionsFilter.POST, start);
        break;
    }

    promise?.finally(() => {
      verticalListLoadMoreData.infiniteScroll.complete();
    });
  }

  setActiveTab(tabId: TabIds): void {

    if (!tabId) {
      return;
    }

    this.activeTabId = tabId;

    switch (tabId) {
      case TabIds.cases:
        this.cases = [];
        this.totalItemsCount = 0;
        this.getUserRecentPublicContributions(UserRecentPublicContributionsFilter.CASE);
        break;
      case TabIds.posts:
        this.posts = [];
        this.totalItemsCount = 0;
        this.getUserRecentPublicContributions(UserRecentPublicContributionsFilter.POST);
        break;
    }
  }

  private generateTabs(): void {
    this.customTabs = [{
      id: TabIds.cases,
      text: this.appTranslationService.instant('app.common.cases'),
      active: this.isCasesTabActive
    },
    {
      id: TabIds.posts,
      text: this.appTranslationService.instant('app.common.posts'),
      active: this.isPostsTabActive
    }];
  }

  get isCasesTabActive(): boolean {
    return this.activeTabId === TabIds.cases ? true : false
  }

  get isPostsTabActive(): boolean {
    return this.activeTabId === TabIds.posts ? true : false
  }

  get title(): string {
    return this.userId ? this.appTranslationService
      .instant('app.dialogs.UserRecentPublicContributionsDialog.title-recent-public-contributions') :
      this.appTranslationService.instant('app.dialogs.UserRecentPublicContributionsDialog.title-my-recent-public-contributions');
  }

  private showError(msg: string): void {
    this.toast.showWithMessage(msg, 'app.common.error-default', ToastMode.ERROR);
  }

  onClose(): void {
    this.modalController.dismiss();
  }

  get showCasesEmptyState(): boolean {
    return !this.loading && !this.cases?.length;
  }

  get showPostsEmptyState(): boolean {
    return !this.loading && !this.posts?.length;
  }

  trackListItemImpression(targetImpression: TargetImpression): void {

    if (targetImpression.rootKey !== this.ImpressionsTrackingList.CASES &&
      targetImpression.rootKey !== this.ImpressionsTrackingList.POSTS) {
      return;
    }

    if (!targetImpression?.targetKey?.length) {
      console.error(`missing target key for item in list: ${targetImpression.rootKey}`);
      return;
    }

    let itemObj: Case | Post | undefined;

    switch (targetImpression.rootKey) {
      case this.ImpressionsTrackingList.CASES:
        itemObj = this.cases.find(clinicalCase => clinicalCase?._id === targetImpression?.targetKey);
        break;
      case this.ImpressionsTrackingList.POSTS:
        itemObj = this.posts.find(post => post?._id === targetImpression?.targetKey);
        break;
    }

    if (!itemObj) {
      return;
    }

    const impressionTrackingRequest: ImpressionTrackingRequest = {
      objectId: targetImpression?.targetKey,
      objectType: 'post',
      postType: itemObj?.type,
    };

    impressionTrackingRequest.source = 'seeAllUserRecentPublicContributionsDialog';

    const objectTitle = (itemObj as any)?.title || '';

    if (objectTitle) {
      impressionTrackingRequest.objectTitle = objectTitle;
    }

    this.trackingService.trackListItemImpression(impressionTrackingRequest);
  }

  openCase(clinicalCase: Case): void {
    const trackingSource = this.userId ? ActionSource.userPublicProfilePage : ActionSource.userProfilePage;

    switch (clinicalCase?.parentType) {
      case ParentType.CHAT:
      case ParentType.USER:
        if (clinicalCase?.type === PostType.case) {
          this.appNavController.openPrivatePublicSharedCase(clinicalCase?._id, trackingSource);
        } else {
          this.appNavController.openPrivatePublicPost(clinicalCase?._id, trackingSource);
        }
        break;
      default:
        this.appNavController.openGroupCase(clinicalCase?._id, trackingSource);
        break;
    }

    this.modalController.dismiss();
  }

  openPost(post: Post): void {
    const trackingSource = this.userId ? ActionSource.userPublicProfilePage : ActionSource.userProfilePage;

    switch (post?.parentType) {
      case ParentType.CHAT:
      case ParentType.USER:
        if (post?.type === PostType.case) {
          this.appNavController.openPrivatePublicSharedCase(post?._id, trackingSource);
        } else {
          this.appNavController.openPrivatePublicPost(post?._id, trackingSource);
        }
        break;
      default:
        this.appNavController.openGroupPost(post?._id, trackingSource);
        break;
    }

    this.modalController.dismiss();
  }

}
