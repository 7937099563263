import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// components
import { InfoSheetComponent } from './components/info-sheet/info-sheet.component';
import { InfoSheetActionComponent } from './components/info-sheet-action/info-sheet-action.component';
import { InfoSheetActionsContainerComponent } from './components/info-sheet-actions-container/info-sheet-actions-container.component';

// services
import { InfoSheetService } from './services/info-sheet.service';

// modules
import { IonicModule } from '@ionic/angular';
import { IconModule } from '../icon/icon.module';
import { MIPipesModule } from 'src/app/pipes/pipes.module';
import { IconButtonModule } from '../icon-button/icon-button.module';
import { InfoSheetWebActionsComponent } from './components/info-sheet-web-actions/info-sheet-web-actions.component';
import { InfoSheetActionsComponent } from './components/info-sheet-actions/info-sheet-actions.component';
import { TranslateModule } from '@ngx-translate/core';
import { MIDirectivesModule } from '../../directives/directives.module';
import { ChipsModule } from '../chips/chips.module';

@NgModule({
  imports: [
    CommonModule,
    IconModule,
    IonicModule,
    TranslateModule,
    MIPipesModule,
    IconButtonModule,
    ChipsModule,
    MIDirectivesModule
  ],
  declarations: [
    InfoSheetComponent,
    InfoSheetActionComponent,
    InfoSheetActionsContainerComponent,
    InfoSheetWebActionsComponent,
    InfoSheetActionsComponent
  ],
  providers: [
    InfoSheetService
  ],
  exports: [
    InfoSheetComponent,
    InfoSheetActionComponent,
    InfoSheetActionsContainerComponent,
    InfoSheetActionsComponent
  ]
})
export class InfoSheetModule { }
