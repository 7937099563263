import { Inject, Injectable } from '@angular/core';
import { firstValueFrom, map, Observable, Subject, Subscription } from 'rxjs';

import { UserProfile } from '../yeti-protocol/auth/mi';
import {
  BookmarkClinicalCasesRequest,
  BookmarkClinicalCasesResponse,
  BookmarkClinicalCasesSuccessResponse,
  CaseAccessRequest,
  CaseAccessRequestData,
  CaseSharedWithData,
  CasesRequestsStatusUpdateResponse,
  CasesRequestsStatusUpdateSuccessResponse,
  CasesType,
  ClinicalCase,
  ClinicalCaseUpdateData,
  CreateClinicalCaseRequest,
  CreateClinicalCaseResponse,
  DeleteClinicalCaseRequest,
  DeleteClinicalCaseResponse,
  ExportClinicalCaseRequest,
  ExportClinicalCaseResponse,
  FeaturedCasesResponse,
  FeatureUnfeatureCaseRequest,
  FeatureUnfeatureCaseResponse,
  GetClinicalCaseResponse,
  GetClinicalCasesParams,
  GetClinicalCasesResponse,
  GetClinicalCaseSuccessResponse,
  GetPendingCasesRequestsRequest,
  GetPendingCasesRequestsResponse,
  GetSharedWithRequest,
  GetSharedWithResponse,
  LikeUnlikeCaseRequest,
  LikeUnlikeCaseResponse,
  RemoveBookmarkClinicalCaseRequest,
  RemoveBookmarkClinicalCaseResponse,
  SharedWithTypeFilter,
  UpdateClinicalCaseRequest,
  UpdateClinicalCasesResponse
} from '../yeti-protocol/clinical-case';

import { AuthService } from '../auth/auth.service';
import { toAuthRequestParams } from '../auth/logic/auth-logic.utils';
import { SchemaValidatorService } from '../schema-validator.service';

import appConfig from 'src/config/config';
import { AuthRequestOptions } from '../auth/logic/auth-logic.service.interface';
import { ErrorResponse } from '../yeti-protocol/error';
import { CONTEXT_SERVICE, ContextService } from '../context/context.model';
import { ContextRequest } from '../yeti-protocol/context-request';
import { PaginationContextRequestParams, ParentType } from '../yeti-protocol/chatter-api';
import { ActionSource } from '../yeti-protocol/tracking';
import { Store } from '@ngxs/store';
import { ClinicalCases } from 'src/app/state/clinical-cases/clinical-cases.actions';
import { ClinicalCasesAccessRequests } from 'src/app/state/clinical-cases-access-requests/clinical-cases-access-requests.actions';
import { CLINICAL_CASES_ACCESS_REQUESTS_STATE_KEY, CLINICAL_CASES_STATE_KEY } from 'src/app/state/state-constants';
import {ToastService} from '../toast.service';
import {AppTranslationService} from '../app-translation.service';

interface ClinicalCaseServiceConfig {
  chatterUrl: string;
}

@Injectable(
  { providedIn: 'root' }
)
export class ClinicalCaseService {

  config: ClinicalCaseServiceConfig = appConfig;

  userProfile: UserProfile;
  userProfileSub: Subscription;

  private clinicalCaseUpdated: Subject<ClinicalCase> = new Subject();

  readonly itemsPerPage = 10;
  private defaultStart = 0;
  private defaultCount = 10;

  constructor(
    @Inject(CONTEXT_SERVICE) private contextService: ContextService,
    private authService: AuthService,
    private schemaValidator: SchemaValidatorService,
    private store: Store,
    private toastService: ToastService,
    private appTranslationService: AppTranslationService
  ) {
    this.userProfileSub = this.authService.userProfileAsObservable.subscribe(userProfile => {
      this.userProfile = userProfile;
    });
  }

  init(): void {
    this.store.dispatch(new ClinicalCasesAccessRequests.FetchClinicalCasesAccessRequests({ pageIndex: 0 }));
  }

  getCase(caseId: string): Promise<ClinicalCase> {
    const url = `${this.config.chatterUrl}cases/${caseId}`;
    return firstValueFrom(
      this.authService.secureGet<GetClinicalCaseResponse>(
        url, this.requestContextOptions
      ).pipe(
        this.schemaValidator.isValidOperator<GetClinicalCaseResponse>('GetClinicalCaseResponse'),
        map((res: GetClinicalCaseResponse) => {
          if ((res as GetClinicalCaseSuccessResponse)?.success) {
            return (res as any).result as ClinicalCase;
          } else {
            if ((res as ErrorResponse).error?.info === '{"caseId":"Access denied"}') {
              return null;
            }
          }
        })
      )
    );
  }

  createCase(caseData: CreateClinicalCaseRequest): Promise<ClinicalCase> {

    const createCaseUrl = `${appConfig.chatterUrl}cases`;
    const createClinicalCasePromise = firstValueFrom(
      this.authService.securePost<CreateClinicalCaseRequest, CreateClinicalCaseResponse>(
        createCaseUrl, caseData, this.requestContextOptions
      ).pipe(
        this.schemaValidator.isValidOperator<CreateClinicalCaseResponse>('DeleteClinicalCaseResponse'),
        map(res => {
          return (res as any).result as ClinicalCase;
        })
      )
    );

    createClinicalCasePromise.then((clinicalCase: ClinicalCase) =>
      this.store.dispatch(new ClinicalCases.InsertClinicalCaseBeforeIndex(clinicalCase, 0)));

    return createClinicalCasePromise;
  }

  deleteCase(caseId: string): Promise<ClinicalCase> {
    const url = `${this.config.chatterUrl}cases/${caseId}`;
    return firstValueFrom(
      this.authService.secureDelete<DeleteClinicalCaseRequest, DeleteClinicalCaseResponse>(
        url, null, this.requestContextOptions
      ).pipe(
        this.schemaValidator.isValidOperator<DeleteClinicalCaseResponse>('DeleteClinicalCaseResponse'),
        map(res => {
          return (res as any).result as ClinicalCase;
        })
      )
    );
  }

  exportCase(caseId: string): Promise<ExportClinicalCaseResponse> {
    const url = `${this.config.chatterUrl}cases/export/${caseId}`;

    const params: ExportClinicalCaseRequest = {
      appId: this.contextService.currentContext.key
    }

    return firstValueFrom(this.authService.securePost<ExportClinicalCaseRequest, ExportClinicalCaseResponse>
      (url, null, { params: toAuthRequestParams(params) }).pipe(
        this.schemaValidator.isValidOperator<ExportClinicalCaseResponse>('ExportClinicalCaseResponse')
      ));
  }

  getCases(pageIndex: number = 0, pageSize: number = 10, type: CasesType = null, topicFilter?: string): Promise<GetClinicalCasesResponse> {
    const url = `${this.config.chatterUrl}cases`;

    const params: GetClinicalCasesParams = {
      appId: this.contextService.currentContext.key,
      start: pageIndex,
      count: pageSize
    };

    if (type?.length) {
      params.type = type;
    }

    if (topicFilter?.length) {
      params.topicFilter = topicFilter;
    }

    return firstValueFrom(this.authService.secureGet<GetClinicalCasesResponse>(url, {
      params: toAuthRequestParams(params)
    }).pipe(
      // this.schemaValidator.isValidOperator<GetClinicalCasesResponse>('GetClinicalCasesResponse')
    ));
  }

  async fetchCaseAccessRequests(start: number = 0, count: number = 10): Promise<CaseAccessRequestData> {

    let currentContext = '';

    try {
      currentContext = (await this.contextService.getCurrentContext())?.key;
    } catch (err) {
      currentContext = this.contextService.currentContext.key;
    } finally {
      if (!currentContext) {
        currentContext = this.contextService.currentContext.key;
      }
    }

    const url = `${this.config.chatterUrl}cases/requests`;
    const params: GetPendingCasesRequestsRequest = {
      appId: currentContext,
      start: start,
      count: count
    }

    return firstValueFrom(this.authService.secureGet<GetPendingCasesRequestsResponse>(
      url, { params: toAuthRequestParams(params) })
      .pipe(this.schemaValidator.isValidOperator<GetPendingCasesRequestsResponse>('GetPendingCasesRequestsResponse'),
        map(result => {
          return result as CaseAccessRequestData;
        })));
  }

  grantAccessCaseRequest(caseAccessRequestId: string, userId: string): Promise<boolean> {
    const url = `${this.config.chatterUrl}cases/${caseAccessRequestId}/grant/${userId}`;
    return this.updateCaseRequestStatus(url);
  }

  denyAccessCaseRequest(caseAccessRequestId: string, userId: string): Promise<boolean> {
    const url = `${this.config.chatterUrl}cases/${caseAccessRequestId}/deny/${userId}`;
    return this.updateCaseRequestStatus(url);
  }

  updateCaseRequestStatus(requestUrl: string): Promise<boolean> {
    return this.authService.asserIsSignedIn().then(() => {

      return firstValueFrom(this.authService.securePut(requestUrl, {}).pipe(
        this.schemaValidator.isValidOperator<CasesRequestsStatusUpdateResponse>('CasesRequestsStatusUpdateResponse'),
        map(res => {
          return (res as CasesRequestsStatusUpdateSuccessResponse).success;
        })
      ));
    });
  }

  removeCaseRequestFromList(caseRequest: CaseAccessRequest): void {

    const clinicalCasesAccessRequests = this.store.snapshot()?.[CLINICAL_CASES_ACCESS_REQUESTS_STATE_KEY]?.clinicalCasesAccessRequests;
    const index = clinicalCasesAccessRequests?.findIndex(request => caseRequest._id === request._id);

    if (index > -1) {
      this.store.dispatch(new ClinicalCasesAccessRequests.RemoveClinicalCaseAccessRequest(index));
    }
  }

  updateCase(caseId: string, data: ClinicalCaseUpdateData): Promise<ClinicalCase> {
    const url = `${this.config.chatterUrl}cases/${caseId}`;

    const updatePromise = firstValueFrom(
      this.authService.securePut<UpdateClinicalCaseRequest, UpdateClinicalCasesResponse>(
        url, data, this.requestContextOptions
      ).pipe(
        this.schemaValidator.isValidOperator<UpdateClinicalCasesResponse>('UpdateClinicalCasesResponse'),
        map(res => {
          return (res as any).result as ClinicalCase;
        })
      )
    );

    updatePromise.then((clinicalCase: ClinicalCase) => {
      this.emitClinicalCaseUpdated(clinicalCase);
      this._updateCasefolioClinicalCasesList(clinicalCase);
    });

    return updatePromise;
  }

  bookmarkCase(caseId: string, source?: ActionSource, parentId?: string, parentType?: ParentType,
    parentTitle?: string, marketingCampaign?: string): Promise<ClinicalCase> {

    return this.authService.asserIsSignedIn().then(async () => {
      const requestUrl = `${this.config.chatterUrl}cases/save/${caseId}`;

      const requestData: BookmarkClinicalCasesRequest = {
        parentId: parentId,
        parentType: parentType,
        parentTitle: parentTitle,
        source: source,
        marketingCampaign: marketingCampaign
      }

      return firstValueFrom(
        this.authService.securePut<BookmarkClinicalCasesRequest, BookmarkClinicalCasesResponse>(
          requestUrl, requestData, this.requestContextOptions
        ).pipe(
          this.schemaValidator.isValidOperator<BookmarkClinicalCasesSuccessResponse>(''),
          map(async res => {
            this.emitClinicalCaseUpdated((res as any).result as ClinicalCase);
            if (res.success) {
              const successMessage = await this.appTranslationService.get('app.pages.CasePage.caseBookmarked');
              this.toastService._show(successMessage);
            }
            return (res as any).result as ClinicalCase;
          })
        ))
    })
  }

  removeBookmarkedCase(caseId: string, source?: ActionSource, parentId?: string, parentType?: ParentType,
    parentTitle?: string, marketingCampaign?: string): Promise<ClinicalCase> {

    return this.authService.asserIsSignedIn().then(async () => {
      const requestUrl = `${this.config.chatterUrl}cases/remove/${caseId}`;

      const deleteData: RemoveBookmarkClinicalCaseRequest = {
        parentId: parentId,
        parentType: parentType,
        parentTitle: parentTitle,
        source: source,
        marketingCampaign: marketingCampaign
      }

      return firstValueFrom(
        this.authService.secureDelete<RemoveBookmarkClinicalCaseRequest, RemoveBookmarkClinicalCaseResponse>(
          requestUrl, deleteData, this.requestContextOptions
        ).pipe(
          this.schemaValidator.isValidOperator<BookmarkClinicalCasesSuccessResponse>('BookmarkClinicalCasesSuccessResponse'),
          map(async res => {
            this.emitClinicalCaseUpdated((res as any).result as ClinicalCase);
            if (res.success) {
              const successMessage = await this.appTranslationService.get('app.pages.CasePage.caseRemovedBookmark');
              this.toastService._show(successMessage);
            }
            return (res as any).result as ClinicalCase;
          })
        ))
    })
  }


  get requestContextOptions(): AuthRequestOptions {
    return {
      params: toAuthRequestParams({
        appId: this.contextService.currentContext.key
      })
    };
  }

  get clinicalCaseUpdatedObservable(): Observable<ClinicalCase> {
    return this.clinicalCaseUpdated.asObservable();
  }

  emitClinicalCaseUpdated(clinicalCase: ClinicalCase): void {
    this.clinicalCaseUpdated.next(clinicalCase);
  }

  getSharedWith(
    caseId: string,
    start: number = 0,
    count: number = 10,
    filter?: SharedWithTypeFilter): Promise<CaseSharedWithData> {

    const url = `${this.config.chatterUrl}cases/sharedWith/${caseId}`;
    const params: GetSharedWithRequest = {
      appId: this.contextService.currentContext.key,
      start: start,
      count: count
    }

    if (filter) {
      params.filter = filter;
    }

    return firstValueFrom(this.authService.secureGet<GetSharedWithResponse>(
      url, { params: toAuthRequestParams(params) })
      .pipe(this.schemaValidator.isValidOperator<GetSharedWithResponse>('GetSharedWithResponse'),
        map(result => {
          return result as CaseSharedWithData;
        })));
  }

  likeCase(caseId: string): Promise<LikeUnlikeCaseResponse> {
    return this.likeUnlikeCase(caseId, true);
  }

  unlikeCase(caseId: string): Promise<LikeUnlikeCaseResponse> {
    return this.likeUnlikeCase(caseId, false);
  }

  private likeUnlikeCase(caseId: string, value: boolean): Promise<LikeUnlikeCaseResponse> {
    return this.authService.asserIsSignedIn().then(() => {

      const appId = this.contextService.currentContext.key;
      const url = `${this.config.chatterUrl}cases/${caseId}/like`;

      const data: LikeUnlikeCaseRequest = {
        value: value
      };

      const requestParams: ContextRequest = {
        appId: appId
      };

      return firstValueFrom(this.authService.securePut<LikeUnlikeCaseRequest, LikeUnlikeCaseResponse>(url, data,
        { params: toAuthRequestParams(requestParams) }).pipe(
          this.schemaValidator.isValidOperator<LikeUnlikeCaseResponse>('LikeUnlikeCaseResponse')
        ));
    });
  }

  featureCase(caseId: string): Promise<FeatureUnfeatureCaseResponse> {
    return this.featureUnfeatureCase(caseId, true);
  }

  unfeatureCase(caseId: string): Promise<FeatureUnfeatureCaseResponse> {
    return this.featureUnfeatureCase(caseId, false);
  }

  private featureUnfeatureCase(caseId: string, featured: boolean): Promise<FeatureUnfeatureCaseResponse> {
    return this.authService.asserIsSignedIn().then(() => {

      const appId = this.contextService.currentContext.key;
      const url = `${this.config.chatterUrl}cases/${caseId}/feature`;

      const data: FeatureUnfeatureCaseRequest = {
        featured: featured
      };

      const requestParams: ContextRequest = {
        appId: appId
      };

      return firstValueFrom(this.authService.securePut<FeatureUnfeatureCaseRequest, FeatureUnfeatureCaseResponse>(url, data,
        { params: toAuthRequestParams(requestParams) }).pipe(
          this.schemaValidator.isValidOperator<FeatureUnfeatureCaseResponse>('FeatureUnfeatureCaseResponse')
        ));
    });
  }

  getFeaturedCases(
    start: number = this.defaultStart,
    count: number = this.defaultCount,
    userId?: string): Promise<FeaturedCasesResponse> {

    let url = '';

    if (userId) {
      url = `${this.config.chatterUrl}cases/featured/${userId}`;
    } else {
      url = `${this.config.chatterUrl}cases/featured`;
    }

    const params: PaginationContextRequestParams = {
      appId: this.contextService.currentContext.key,
      start,
      count
    }

    return firstValueFrom(this.authService.secureGet(url, { params: toAuthRequestParams(params) }).pipe(
      this.schemaValidator.isValidOperator<FeaturedCasesResponse>('FeaturedCasesResponse')
    ));
  }

  private _updateCasefolioClinicalCasesList(updatedClinicalCase: ClinicalCase): void {

    const clinicalCases = this.store.snapshot()?.[CLINICAL_CASES_STATE_KEY]?.clinicalCases;

    if (!clinicalCases?.length) {
      return;
    }

    let clinicalCaseToRemoveIndex = -1;

    clinicalCases.forEach(
      (clinicalCase: ClinicalCase, i: number) => {
        if (clinicalCase?._id === updatedClinicalCase?._id) {
          if (
            clinicalCase?.hasBookmarked &&
            !updatedClinicalCase?.hasBookmarked
          ) {
            clinicalCaseToRemoveIndex = i;
          }
        }
      }
    );

    if (clinicalCaseToRemoveIndex > -1) {
      this.store.dispatch(new ClinicalCases.RemoveClinicalCase(clinicalCaseToRemoveIndex));
    } else {
      this.store.dispatch(new ClinicalCases.UpdateClinicalCase(updatedClinicalCase));
    }
  }
}
