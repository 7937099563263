// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  --send-new-connection-request-dialog-padding-top: 25px;
  --send-new-connection-request-dialog-padding-bottom: 30px;
  --send-new-connection-request-dialog-padding-start: 15px;
  --send-new-connection-request-dialog-padding-end: 15px;
}

.send-new-connection-request-dialog-container {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: min-content auto;
  grid-template-columns: 100%;
}
.send-new-connection-request-dialog-container .top-bar-container {
  grid-row: 1;
  grid-column: 1;
}
.send-new-connection-request-dialog-container .content-container {
  grid-row: 2;
  grid-column: 1;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: auto min-content;
  row-gap: 15px;
  padding-top: var(--send-new-connection-request-dialog-padding-top);
  padding-bottom: var(--send-new-connection-request-dialog-padding-bottom);
}
.send-new-connection-request-dialog-container .content-container .message-input-container {
  grid-row: 1;
  grid-column: 1;
}
.send-new-connection-request-dialog-container .content-container .message-input-container app-text-field-limit-counter-wrapper ::ng-deep .text-field-limit-counter-wrapper .text-field-container {
  flex-grow: 1;
}
.send-new-connection-request-dialog-container .content-container .message-input-container app-text-field-limit-counter-wrapper ::ng-deep .text-field-limit-counter-wrapper .text-field-container textarea {
  width: 100%;
  height: 100%;
  padding-top: 0px;
  padding-bottom: 0px;
  padding-left: var(--send-new-connection-request-dialog-padding-start);
  padding-right: var(--send-new-connection-request-dialog-padding-end);
  border: none;
  outline: none;
  font-weight: var(--font-weight-regular);
  font-style: normal;
  font-size: var(--font-size-regular);
  line-height: var(--font-line-height-regular);
  color: var(--mi-secondary-color-2);
  resize: none;
}
.send-new-connection-request-dialog-container .content-container .message-input-container app-text-field-limit-counter-wrapper ::ng-deep .text-field-limit-counter-wrapper .text-field-container textarea::placeholder {
  font-weight: var(--font-weight-light);
  font-style: normal;
  color: var(--mi-secondary-color-2);
}
.send-new-connection-request-dialog-container .content-container .message-input-container app-text-field-limit-counter-wrapper ::ng-deep .text-field-limit-counter-wrapper .limit-counter {
  grid-row: 2;
  grid-column: 1;
  padding-left: var(--send-new-connection-request-dialog-padding-start);
  padding-right: var(--send-new-connection-request-dialog-padding-end);
}
.send-new-connection-request-dialog-container .content-container .button-container {
  grid-row: 2;
  grid-column: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: var(--send-new-connection-request-dialog-padding-start);
  padding-right: var(--send-new-connection-request-dialog-padding-end);
}
.send-new-connection-request-dialog-container .content-container .button-container ion-button {
  width: 100%;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
