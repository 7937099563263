import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { IonRefresher } from '@ionic/angular';
import { ResponsiveUtilsService } from 'src/app/services/utils/responsive-utils.service';
import { GroupsService } from '../../services/groups/groups.service';
import { ToastMode, ToastService } from 'src/app/services/toast.service';
import { GroupsDataService, GroupActionContent, GroupsActions } from 'src/app/services/groups/groups-data.service';
import { Subscription } from 'rxjs';
import { Group, GroupsListResponse, GroupListSuccessResponse } from '../../services/yeti-protocol/chatter-api';
import { VerticalListLoadMoreData } from 'src/app/components/vertical-list/vertical-list.component';
import { AppNavController } from 'src/app/services/app-nav-controller.service';
import { ActionSource } from 'src/app/services/yeti-protocol/tracking';

@Component({
  selector: 'app-my-groups',
  templateUrl: './my-groups.component.html',
  styleUrls: ['./my-groups.component.scss'],
})
export class MyGroupsComponent implements OnInit, OnDestroy {

  @Output() discoverGroups: EventEmitter<void> = new EventEmitter();
  @Output() createGroup: EventEmitter<void> = new EventEmitter();
  @Output() hideListTop: EventEmitter<boolean> = new EventEmitter();

  groups: Array<Group> = [];
  totalItemsCount: number;
  groupsLoading: boolean;
  readonly start = 0;
  readonly count = 20;
  private groupChangesSubscription: Subscription;

  constructor(
    private responsiveUtilsService: ResponsiveUtilsService,
    private groupService: GroupsService,
    private toast: ToastService,
    private groupsDataService: GroupsDataService,
    private appNavController: AppNavController
  ) { }

  ngOnInit(): void {

    this.groupChangesSubscription = this.groupsDataService.getGroupChangesStream()
      .subscribe((groupActionContent: GroupActionContent) => {
        this.executeGroupChange(groupActionContent);
      });

    this.getGroups().then(res => {
      this.totalItemsCount = res?.total || 0;
      this.groups = res?.groups || [];
    });
  }

  ngOnDestroy(): void {
    this.groupChangesSubscription?.unsubscribe();
  }

  getGroups(start: number = this.start, count: number = this.count): Promise<{ total: number, groups: Array<Group> }> {

    this.groupsLoading = true;

    return this.groupService.getMemberGroups(start, count)
      .then((groupslistResponse: GroupsListResponse) => {
        if (groupslistResponse as GroupListSuccessResponse) {
          return {
            total: (groupslistResponse as GroupListSuccessResponse).totalItemsCount,
            groups: (groupslistResponse as GroupListSuccessResponse).result
          };
        }
        return null;
      }).catch(err => {
        if (err?.error?.error?.message?.errfor?.message) {
          this.showError(err?.error?.error?.message?.errfor?.message);
        }
        return null;
      }).finally(() => {
        this.groupsLoading = false;
      });
  }

  async refreshGroups(refresher: IonRefresher): Promise<void> {
    this.getGroups().then(res => {
      this.totalItemsCount = res?.total || 0;
      this.groups = res?.groups || [];
    }).finally(() => {
      refresher.complete();
    });
  }

  async loadMoreGroups(verticalListLoadMoreData: VerticalListLoadMoreData): Promise<void> {

    const start = Math.floor(this.groups.length / this.count);

    this.getGroups(start).then(res => {
      this.totalItemsCount = res?.total || 0;
      this.groups = [...this.groups, ...res?.groups || []];
    }).finally(() => {
      verticalListLoadMoreData.infiniteScroll.complete();
    });
  }

  openGroup(groupId: string): void {
    const trackingSource = ActionSource.groupsPage + 'MyGroupsTab';
    this.appNavController.openGroup(groupId, trackingSource);
  }

  discoverGroupsClicked(): void {
    this.discoverGroups.emit();
  }

  get isDesktop(): boolean {
    return this.responsiveUtilsService.isDesktop;
  }

  onCreateGroup(): void {
    this.createGroup.emit();
  }

  get showEmptyState(): boolean {
    return !this.groupsLoading && !this.groups?.length;
  }

  onScrolled(scrolled: boolean): void {
    this.hideListTop.emit(scrolled);
  }

  private executeGroupChange(groupActionContent: GroupActionContent): void {
    if (groupActionContent?.group) {
      switch (groupActionContent.action) {
        case GroupsActions.NEW_GROUP_ADDED:
        case GroupsActions.GROUP_DELETED:
        case GroupsActions.GROUP_LEFT:
          this.getGroups().then(res => {
            this.totalItemsCount = res?.total || 0;
            this.groups = res?.groups || [];
          });
          break;
        case GroupsActions.GROUP_EDITED:
          const groupId = groupActionContent?.group?._id;
          const groupIndex = this.groups.findIndex((group: Group) => group._id === groupId);

          if (groupIndex > -1) {
            this.groups[groupIndex] = groupActionContent?.group;
          }
          break;
      }
    }
  }

  private showError(msg: string): void {
    this.toast.showWithMessage(msg, 'app.common.error-default', ToastMode.ERROR);
  }

}
