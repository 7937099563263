import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';

import { SwiperModule } from 'swiper/angular';
import { IconModule } from '../icon/icon.module';
import { InfoSheetModule } from '../info-sheet/info-sheet.module';
import { TopBarModule } from '../top-bar/top-bar.module';
import { FileSelectComponent } from './components/file-select/file-select.component';
import { ImageGalleryDialogComponent } from './components/image-gallery-dialog/image-gallery-dialog.component';
import {ImageEditorModule} from '../image-editor/image-editor.module';

@NgModule({
  declarations: [
    FileSelectComponent,
    ImageGalleryDialogComponent
  ],
  exports: [
    FileSelectComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    InfoSheetModule,
    TranslateModule,
    IconModule,
    SwiperModule,
    TopBarModule,
    ImageEditorModule
  ]
})
export class FileSelectModule { }
