import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { InputUsage } from 'src/app/components/generic-input/generic-input.component';
import { CreateGroupResponse, CreateGroupSuccessResponse, SaveGroupData, UpdateGroupResponse, UpdateGroupSuccessResponse }
  from 'src/app/services/yeti-protocol/chatter-api';
import appConfig from 'src/config/config';
import { isFieldValid } from '../auth/auth-common/auth-common'
import { UserProfile } from 'src/app/services/yeti-protocol/auth/mi';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';

// models
import { Group, GroupUserScopes, GroupVisibility } from '../../services/groups/group.model';
import { AttachmentMimeType, ImageAttachment } from 'src/app/services/attachments.model';
import { VerificationStatus } from 'src/app/services/verification.model';

// services
import { ChatterApiService } from 'src/app/services/chatter-api.service';
import { CONTEXT_SERVICE, ContextService } from 'src/app/services/context/context.model';
import { AuthService } from 'src/app/services/auth/auth.service';
import { UploadFile } from 'src/app/services/yeti-protocol/upload';
import { UploadService } from 'src/app/services/upload.service';
import { GroupsDataService } from 'src/app/services/groups/groups-data.service';
import { ResponsiveUtilsService } from '../../services/utils/responsive-utils.service';
import { FileSelectComponent } from 'src/app/modules/file-select/components/file-select/file-select.component';
import { FileSelectService } from 'src/app/modules/file-select/services/file-select.service';
import { FileSelectLimits, FileType, SelectedFile } from 'src/app/modules/file-select/file-select.model';
import { LinkOpenerService, TargetOfTheLink } from 'src/app/services/link-opener.service';
import { DialogsUIService } from 'src/app/services/dialogs/dialogs.ui.service';
import { FileSelectScope } from '../../modules/file-select/services/file-select.service';

@Component({
  selector: 'app-create-edit-group',
  templateUrl: './create-edit-group.component.html',
  styleUrls: ['./create-edit-group.component.scss'],
})

export class CreateEditGroupComponent implements OnInit, OnDestroy {
  @ViewChild('f', { static: true }) form: NgForm;
  @ViewChild('fileSelectImage') fileSelectImage: FileSelectComponent;

  FileType = FileType;
  AttachmentMimeType = AttachmentMimeType;
  InputUsage = InputUsage;
  GroupVisibility = GroupVisibility;
  GroupUserScopes = GroupUserScopes;
  VerificationStatus = VerificationStatus;
  editMode: boolean;
  group: Group;
  existingGroupName = '';
  existingGroupDescription = '';
  existingGroupImage: string | ArrayBuffer = '';
  checkedVisibility: string = GroupVisibility.PUBLIC;
  checkedRTJ = false;
  aoMembersOnly = false;
  submitBtn = 'Create group';
  user: UserProfile;
  imageFile: ImageAttachment;
  maxImageFileSizeMb = FileSelectLimits.imageMaxFileSizeMB;
  creatingOrUpdatingGroup: boolean;
  FileSelectScope = FileSelectScope;
  private userSubscription: Subscription;
  private reader: FileReader = new FileReader();


  constructor(
    private modalController: ModalController,
    private dialogs: DialogsUIService,
    private chatterService: ChatterApiService,
    private uploadService: UploadService,
    @Inject(CONTEXT_SERVICE) private contextService: ContextService,
    private authService: AuthService,
    private router: Router,
    private groupsDataService: GroupsDataService,
    private responsiveUtilsService: ResponsiveUtilsService,
    private fileSelectService: FileSelectService,
    private linkOpener: LinkOpenerService,
  ) { }

  ngOnInit(): void {
    this.userSubscription = this.authService.userProfileAsObservable.subscribe(userProfile => {
      this.user = userProfile;
    });
    if (this.editMode) {
      this.submitBtn = 'Save changes';
      this.setEditGroupFields();
    }
    this.reader.onload = e => {
      this.existingGroupImage = e.target.result;
    }
  }

  ngOnDestroy(): void {
    this.userSubscription?.unsubscribe();
  }

  backButtonClicked(): void {
    this.onClose();
  }

  onClose(group?: Group): void {
    this.modalController.dismiss(group);
  }

  onCreateEditGroup(form: NgForm): void {

    if (this.disableCreateGroupButton) {
      return;
    }

    if (this.user.verificationStatus === VerificationStatus.VERIFIED) {
      const groupData: SaveGroupData = {
        title: form.value.groupName ? form.value.groupName : '',
        description: form.value.groupDesc ? form.value.groupDesc : '',
        visibility: this.calculateGroupVisibility(form.value.groupVisibility, form.value.requestToJoin),
        image: '',
        AOMembersOnly: this.aoMembersOnly
      }

      if (!this._isDataValid(groupData)) {
        return;
      }

      if (!this.editMode) {
        this._createGroup(groupData);
      } else if (this.group.status === GroupUserScopes.OWNER || this.group.status === GroupUserScopes.MODERATOR) {
        this._updateGroup(groupData);
      }
    }
  }

  _isDataValid(groupData: SaveGroupData): boolean {
    if (groupData.title.length > appConfig.groupNameMaxChars) {
      const messageTitle = 'Please enter a group name with less than ' + appConfig.groupNameMaxChars + ' characters';
      this.dialogs.showErrorDialog(messageTitle);
      return false;
    }

    if (groupData.description.length > appConfig.groupDescriptionMaxChars) {
      const messageDesc = 'Please enter a group description with less than ' + appConfig.groupDescriptionMaxChars + ' characters'
      this.dialogs.showErrorDialog(messageDesc);
      return false;
    }

    return true;
  }

  _createGroup(groupData: SaveGroupData): void {

    this.creatingOrUpdatingGroup = true;

    this.chatterService.createGroup(groupData).then((response: CreateGroupResponse) => {
      const createdGroup = (response as CreateGroupSuccessResponse).result;
      if (this.imageFile) {
        this.uploadGroupImage(createdGroup._id).then(() => {
          this.groupsDataService.triggerNewGroupCreatedAction(createdGroup);
          this.redirectToGroupPage(createdGroup._id);
        }).catch(() => {
          this.creatingOrUpdatingGroup = false;
        });
      } else {
        this.onClose();
        this.groupsDataService.triggerNewGroupCreatedAction(createdGroup);
        this.redirectToGroupPage(createdGroup._id);
      }
    }).catch(() => {
      this.creatingOrUpdatingGroup = false;
    });
  }

  _updateGroup(groupData: SaveGroupData): void {

    this.creatingOrUpdatingGroup = true;

    this.chatterService.updateGroup(groupData, this.group._id).then((response: UpdateGroupResponse) => {
      const updatedGroup = (response as UpdateGroupSuccessResponse).result;
      if (this.imageFile) {
        this.uploadGroupImage(updatedGroup._id).then((newImg: string) => {
          updatedGroup.imageUrl = newImg;
          this.groupsDataService.triggerGroupEditedAction(updatedGroup);
        }).catch(() => {
          this.creatingOrUpdatingGroup = false;
        });
      } else {
        this.onClose(updatedGroup);
        this.groupsDataService.triggerGroupEditedAction(updatedGroup);
      }
    }).catch(() => {
      this.creatingOrUpdatingGroup = false;
    });
  }

  addImage(): void {
    this.fileSelectImage.selectFile();
  }

  imageSelected(images: Array<SelectedFile>): void {
    if (images.length > 0) {
      images?.forEach((image: SelectedFile) => {
        this.imageFile = {
          _id: null,
          fullUrl: image?.url,
          previewUrl: image?.url,
          mimeType: image?.file?.type,
          fileName: image?.file?.name,
          contentLength: image?.file?.size,
          file: image?.file
        }
        this.existingGroupImage = image?.url;
      });
    }
  }

  uploadGroupImage(groupId: string): Promise<string> {
    const uploadFile: UploadFile = {
      key: 'image',
      file: this.imageFile?.file,
      fileName: this.imageFile?.fileName,
      mimeType: this.imageFile?.mimeType
    }
    return new Promise((resolve, reject) => {
      this.uploadService.uploadGroupImage(uploadFile, this.contextService.currentContext.key, groupId).subscribe({
        next: response => {
          this.onClose();
          resolve(response?.result?.imageUrl);
        },
        error: err => {
          console.error(err);
          reject(false);
        }
      });
    });
  }

  calculateGroupVisibility(radioBtnSelection: string, requestToJoin: boolean): string {
    if (radioBtnSelection === GroupVisibility.PUBLIC) {
      return requestToJoin ? GroupVisibility.PRIVATE : GroupVisibility.PUBLIC;
    } else if (radioBtnSelection === GroupVisibility.UNLISTED) {
      return GroupVisibility.UNLISTED;
    }
  }

  isFieldValid(fieldName: string): boolean {
    return isFieldValid(this.form, fieldName);
  }

  setEditGroupFields(): void {
    this.existingGroupName = this.group.title
    this.existingGroupDescription = this.group.description;
    this.existingGroupImage = this.group.imageUrl;
    this.setGroupVisibility();
    this.aoMembersOnly = this.group.AOMembersOnly;
  }

  setGroupVisibility(): void {
    if (this.group.visibility === GroupVisibility.PRIVATE) {
      this.checkedVisibility = GroupVisibility.PUBLIC;
      this.checkedRTJ = true;
    } else if (this.group.visibility === GroupVisibility.PUBLIC) {
      this.checkedVisibility = GroupVisibility.PUBLIC;
      this.checkedRTJ = false;
    } else if (this.group.visibility === GroupVisibility.UNLISTED) {
      this.checkedVisibility = GroupVisibility.UNLISTED;
      this.checkedRTJ = false;
    }
  }

  redirectToGroupPage(groupId: string): void {
    this.router.navigateByUrl('/' + [this.contextService.currentContext.key, 'group', groupId].join('/'));
  }

  get isDesktop(): boolean {
    return this.responsiveUtilsService.isDesktop;
  }

  get defaultImageMimeTypes(): string {
    return this.fileSelectService?.defaultImageMimeTypes.join(',');
  }

  onRTJChange(): void {
    this.checkedRTJ = !this.checkedRTJ;
    if (this.checkedRTJ && this.checkedVisibility === GroupVisibility.UNLISTED) {
      this.checkedVisibility = GroupVisibility.PUBLIC;
    }
  }

  onAoMembersOnlyChange(): void {
    this.aoMembersOnly = !this.aoMembersOnly;
  }

  onVisibilityChange(event: string): void {
    this.checkedVisibility = event;
    if (event === GroupVisibility.UNLISTED && this.checkedRTJ) {
      this.checkedRTJ = false;
    }
  }

  openCommunityGuidelines(): void {
    const url = `${appConfig?.webUrl}${this.contextService.currentContext.homePath}/community-guidelines`;
    this.linkOpener.open(url, TargetOfTheLink.SystemBrowser);
  }

  get disableCreateGroupButton(): boolean {
    return !this.form.valid || this.creatingOrUpdatingGroup;
  }
}
