import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { UserProfile } from 'src/app/services/yeti-protocol/auth/mi';
import { ClinicalCase } from 'src/app/services/yeti-protocol/clinical-case';
import { InfoSheetActionItem } from '../../info-sheet/models/info-sheet-action-item.model';
import { AuthService } from 'src/app/services/auth/auth.service';
import { ClinicalCaseService } from 'src/app/services/case-library/clinical-case.service';
import { DialogsUIService } from 'src/app/services/dialogs/dialogs.ui.service';
import { UIUtilsServiceInterface, UI_UTILS_SERVICE } from 'src/app/services/utils/ui-utils.service.interface';
import { ClinicalCaseDataService, SharedCaseToGroupsData } from 'src/app/services/case-library/clinical-case-data.service';
import { ActionSource, ActionTracked, GenericTrackingParam, TrackingRequest } from 'src/app/services/yeti-protocol/tracking';
import { TRACKING_SERVICE, TrackingService } from 'src/app/services/tracking/tracking.model';
import { ActivatedRoute } from '@angular/router';
import { IconLabelPosition } from '../../buttons/base-button/base-button.component';
import { LikeUnlikeCaseSuccessResponse } from '../../../services/yeti-protocol/clinical-case';
import { getImagesWithCorrectUrlBasedOnWatermark } from '../../../services/utils/image-attachments.utils';
import { ImageAttachment } from '../../../services/attachments.model';

enum ClinicalCaseAction {
  DELETE = 'delete_case_btn',
  REMOVE_BOOKMARKED = 'remove-bookmarked'
}

@Component({
  selector: 'app-clinical-case-list-case-card',
  templateUrl: './clinical-case-list-case-card.component.html',
  styleUrls: ['./clinical-case-list-case-card.component.scss'],
})
export class ClinicalCaseListCaseCardComponent implements OnInit, OnDestroy {

  @Input() clinicalCase: ClinicalCase;
  @Input() withFeatureButton: boolean;
  @Input() featuredCaseCard: boolean;
  @Output() openCase: EventEmitter<string> = new EventEmitter();
  @Output() deleteCase: EventEmitter<string> = new EventEmitter();
  @Output() removeBookmarkedCase: EventEmitter<string> = new EventEmitter();
  @Output() shareCase: EventEmitter<ClinicalCase> = new EventEmitter();

  IconLabelPosition = IconLabelPosition;
  profile: UserProfile;
  profileSub: Subscription;
  caseFeatureStatusChanging: boolean;
  applaudStatusChanging: boolean;

  private clinicalCaseUpdatedSubscription: Subscription;
  private clinicalCaseSharingSubscription: Subscription;
  private trackSource: ActionSource;

  constructor(
    private authService: AuthService,
    private clinicalCaseService: ClinicalCaseService,
    @Inject(UI_UTILS_SERVICE) public uiUtilsService: UIUtilsServiceInterface,
    private dialogs: DialogsUIService,
    private clinicalCaseDataService: ClinicalCaseDataService,
    @Inject(TRACKING_SERVICE) private trackingService: TrackingService,
    private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {

    this.route.queryParams.subscribe(params => {
      this.trackSource = params?.source;
    });

    this.profileSub = this.authService.userProfileAsObservable.subscribe(profile => {
      this.profile = profile;
    });

    this.clinicalCaseUpdatedSubscription = this.clinicalCaseService.clinicalCaseUpdatedObservable
      .subscribe(clinicalCase => this.onClinicalCaseUpdated(clinicalCase));

    this.clinicalCaseSharingSubscription = this.clinicalCaseDataService.getClinicalCaseSharingChanges()
      .subscribe((data: SharedCaseToGroupsData) => this.onClinicalCaseShared(data));
  }

  ngOnDestroy(): void {
    this.profileSub.unsubscribe();
    this.clinicalCaseUpdatedSubscription?.unsubscribe();
    this.clinicalCaseSharingSubscription?.unsubscribe();
  }

  get infoSheetId(): string {
    return `clinical-case-card-${this?.clinicalCase?._id || 'clinical-case-card'}`;
  }

  get createdDateLabel(): Date {
    if (this.clinicalCase && this.clinicalCase.createdDate) {
      return new Date(this.clinicalCase.createdDate);
    }
    return null;
  }

  onOpenCase(): void {
    this.openCase.emit(this.clinicalCase._id);
  }

  onDeleteCase(): void {
    this.deleteCase.emit(this.clinicalCase._id);
  }

  onRemoveBookmarkedCase(): void {
    this.removeBookmarkedCase.emit(this.clinicalCase._id);
  }

  onShare(): void {
    this.shareCase.emit(this.clinicalCase);
  }

  get infoSheetActions(): Array<InfoSheetActionItem> {

    const deleteCaseBtn = {
      id: 'delete_case_btn',
      icon: 'md-icon-bin',
      textKey: 'app.common.delete',
      code: ClinicalCaseAction.DELETE,
      handler: () => {
        this.onDeleteCase();
      }
    };

    const removeBookmarkedCaseBtn = {
      id: 'remove_bookmarked_case_btn',
      icon: 'md-icon-remove-bookmark',
      textKey: 'app.common.remove',
      code: ClinicalCaseAction.REMOVE_BOOKMARKED,
      handler: () => {
        this.onRemoveBookmarkedCase();
      }
    };

    return this.clinicalCase?.owned ? [deleteCaseBtn] : [removeBookmarkedCaseBtn];
  }

  handleActionFromPopover(action: InfoSheetActionItem): void {
    switch (action.code) {
      case ClinicalCaseAction.DELETE:
        this.onDeleteCase();
        break;
      case ClinicalCaseAction.REMOVE_BOOKMARKED:
        this.onRemoveBookmarkedCase();
        break;
    }
  }

  confirmCommunityGuidelines(): Promise<void> {
    return this.dialogs.showConfirmCommunityGuidelines()
      .then(confirmed => {
        if (confirmed) {
          return this.onShare();
        }
      })
      .catch(err => {
        console.log(err);
      });
  }

  get caseImages(): Array<ImageAttachment> {
    return [
      ...getImagesWithCorrectUrlBasedOnWatermark(this.clinicalCase?.preOpDocuments || [], this.clinicalCase?.watermarked),
      ...getImagesWithCorrectUrlBasedOnWatermark(this.clinicalCase?.intraOpDocuments || [], this.clinicalCase?.watermarked),
      ...getImagesWithCorrectUrlBasedOnWatermark(this.clinicalCase?.postOpDocuments || [], this.clinicalCase?.watermarked),
      ...getImagesWithCorrectUrlBasedOnWatermark(this.clinicalCase?.clinicalFollowUpDocuments || [], this.clinicalCase?.watermarked)
    ]
  }

  get isCaseOwner(): boolean {
    if (this.clinicalCase && this.profile) {
      return this.clinicalCase?.ownerId === this.profile.id;
    }
    return false;
  }

  get showFeatureButton(): boolean {
    return (this.withFeatureButton || this.featuredCaseCard) && this.isCaseOwner;
  }

  private onClinicalCaseUpdated(clinicalCase: ClinicalCase): void {

    if (this.clinicalCase?._id === clinicalCase?._id) {
      this.clinicalCase = clinicalCase;
    }
  }

  private onClinicalCaseShared(data: SharedCaseToGroupsData): void {
    if (data && this.clinicalCase?._id === data?.clinicalCaseId) {
      if (data?.numberOfGroupsSharedTo) {
        this.clinicalCase.numberOfGroupsSharedTo += data?.numberOfGroupsSharedTo;
      }
      const sharedTo = data?.groupsSharedTo || [];
      if (sharedTo && !this.clinicalCase.groupsSharedTo) {
        this.clinicalCase.groupsSharedTo = sharedTo;
      } else if (data?.groupsSharedTo) {
        this.clinicalCase.groupsSharedTo = [...(this.clinicalCase?.groupsSharedTo || []), ...sharedTo];
      }
    }
  }

  async toggleCaseFeaturedStatus(): Promise<void> {

    if (this.caseFeatureStatusChanging) {
      return;
    }

    this.caseFeatureStatusChanging = true;

    if (this.clinicalCase.isFeatured) {
      const shouldProceed = await this.uiUtilsService.showUnfeatureCasePrompt();

      if (shouldProceed) {
        this.clinicalCaseService.unfeatureCase(this.clinicalCase?._id).then(() => {
          this.clinicalCase.isFeatured = false;
          this.clinicalCaseService.emitClinicalCaseUpdated(this.clinicalCase);
          this.uiUtilsService.showCaseUnfeatureSuccessToast();
          this.trackActionCaseFeaturedStatusChanged(false);
        }).finally(() => {
          this.caseFeatureStatusChanging = false;
        })
      } else {
        this.caseFeatureStatusChanging = false;
      }
    } else {
      const shouldProceed = await this.uiUtilsService.showFeatureCasePrompt();

      if (shouldProceed) {
        this.clinicalCaseService.featureCase(this.clinicalCase?._id).then(() => {
          this.clinicalCase.isFeatured = true;
          this.clinicalCaseService.emitClinicalCaseUpdated(this.clinicalCase);
          this.uiUtilsService.showCaseFeatureSuccessToast();
          this.trackActionCaseFeaturedStatusChanged(true);
        }).finally(() => {
          this.caseFeatureStatusChanging = false;
        })
      } else {
        this.caseFeatureStatusChanging = false;
      }
    }
  }

  trackActionCaseFeaturedStatusChanged(featured: boolean): void {
    const paramsToTrack: GenericTrackingParam = {
      objectId: this.clinicalCase?._id,
      objectType: 'case'
    };

    if (this.trackSource) {
      paramsToTrack.source = this.trackSource;
    }

    const trackData: TrackingRequest = {
      action: featured ? ActionTracked.caseFeatured : ActionTracked.caseUnfeatured,
      params: paramsToTrack
    };

    this.trackingService.track(trackData).catch(_err => {
      console.error('Could not track case featured status changed action');
    });
  }

  get showApplaudButton(): boolean {
    return this.featuredCaseCard && !this.isCaseOwner;
  }

  applaudClicked(): void {

    if (this.applaudStatusChanging) {
      return;
    }

    this.applaudStatusChanging = true;

    if (this.clinicalCase?.hasLiked) {
      this.clinicalCaseService.unlikeCase(this.clinicalCase?._id).then(res => {
        this.clinicalCase = (res as LikeUnlikeCaseSuccessResponse).result;
        this.clinicalCaseService.emitClinicalCaseUpdated(this.clinicalCase);
      }).finally(() => {
        this.applaudStatusChanging = false;
      });
    } else {
      this.clinicalCaseService.likeCase(this.clinicalCase?._id).then(res => {
        this.clinicalCase = (res as LikeUnlikeCaseSuccessResponse).result;
        this.clinicalCaseService.emitClinicalCaseUpdated(this.clinicalCase);
      }).finally(() => {
        this.applaudStatusChanging = false;
      });
    }
  }

  hideBookmark(): boolean {
    return this.clinicalCase?.hasBookmarked || this.clinicalCase?.owned;
  }
}
