import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';

import { TranslateModule } from '@ngx-translate/core';
import { SwiperModule } from 'swiper/angular';

import {VgCoreModule} from '@videogular/ngx-videogular/core';
import {VgControlsModule} from '@videogular/ngx-videogular/controls';
import {VgOverlayPlayModule} from '@videogular/ngx-videogular/overlay-play';
import {VgBufferingModule} from '@videogular/ngx-videogular/buffering';

// components
import { MIButtonsModule } from '../buttons/buttons.module';
import { ChipsModule } from '../chips/chips.module';
import { VideoImageComponent } from './video-image/video-image.component';
import { IconModule } from '../icon/icon.module';
import { UsersModule } from '../users/users.module';
import { InfoSheetModule } from '../info-sheet/info-sheet.module';
import { VideoPlayerComponent } from './video-player/video-player.component';
import { VideoDialogComponent } from './video-dialog/video-dialog.component';
import { VideoTranscriptSummaryDialogComponent } from './video-transcript-summary-dialog/video-transcript-summary-dialog.component';
import { MultilineTextWithEllipsisModule } from '../../components/multiline-text-with-ellipsis/multiline-text-with-ellipsis.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    SwiperModule,
    ChipsModule,
    IconModule,
    UsersModule,
    TranslateModule,
    InfoSheetModule,
    MIButtonsModule,
    VgCoreModule,
    VgControlsModule,
    VgOverlayPlayModule,
    VgBufferingModule,
    MultilineTextWithEllipsisModule
  ],
  declarations: [
    VideoImageComponent,
    VideoPlayerComponent,
    VideoDialogComponent,
    VideoTranscriptSummaryDialogComponent,
  ],
  exports: [
    VideoImageComponent,
    VideoDialogComponent,
    VideoTranscriptSummaryDialogComponent
  ]
})
export class VideoHubModule { }
