// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  --padding: 0px;
  --panel-1-padding: 15px;
  --panel-2-padding: 15px;
  --panel-1-row-gap: 20px;
  --title-font-size: var(--font-size-extra-large);
  --panel-2-border-radius: 20px;
  --close-button-icon-size: 24px;
}
@media all and (min-width: 1000px) {
  :host {
    --padding: 50px;
    --title-font-size: var(--font-size-extra-large-2);
    --panel-1-row-gap: 30px;
    --close-button-icon-size: 30px;
    --panel-1-padding: 0px;
  }
}

.share-dialog-container {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: min-content 1fr;
  row-gap: 10px;
  background-color: var(--mi-purple-color-3);
  padding: var(--padding);
}
.share-dialog-container .panel-1 {
  grid-row: 1;
  grid-column: 1;
  padding: var(--panel-1-padding);
  display: grid;
  grid-auto-flow: row;
  grid-auto-rows: min-content;
  grid-template-columns: 100%;
  row-gap: var(--panel-1-row-gap);
}
.share-dialog-container .panel-1 .close-button-container .close-button {
  --icon-button-background-width: 100%;
  --icon-button-background-height: 100%;
  --icon-button-background-color: var(--mi-purple-color-3);
  --icon-button-background-paddng: 0;
  --icon-button-border-radius: 0;
  --icon-button-icon-size: var(--close-button-icon-size);
  --icon-button-icon-color: var(--mi-secondary-color-2);
  outline: none;
  background: none;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0;
  height: 30px;
  width: 30px;
}
.share-dialog-container .panel-1 .title-container .title {
  margin: 0;
  color: var(--mi-secondary-color-2);
  font-size: var(--title-font-size);
  font-weight: var(--font-weight-regular);
}
.share-dialog-container .panel-1 .title-container .title span {
  color: var(--mi-purple-color);
  font-weight: var(--font-weight-bold);
}
.share-dialog-container .panel-1 .shared-object-container .shared-object {
  display: grid;
  grid-template-columns: auto min-content;
  grid-template-rows: auto;
  -moz-column-gap: 10px;
       column-gap: 10px;
  align-items: center;
  padding: 12.5px;
  border: 1px solid var(--mi-purple-color);
  border-radius: 9px;
}
.share-dialog-container .panel-1 .shared-object-container .shared-object .text-container {
  grid-row: 1;
  grid-column: 1;
  display: grid;
  grid-template-columns: 100%;
  grid-auto-flow: row;
  grid-auto-rows: min-content;
  row-gap: 5px;
}
.share-dialog-container .panel-1 .shared-object-container .shared-object .text-container .shared-object-title {
  --multiline-text-with-ellipsis-max-height: 42px;
}
.share-dialog-container .panel-1 .shared-object-container .shared-object .text-container .shared-object-title ::ng-deep {
  font-weight: var(--font-weight-medium);
  font-size: var(--font-size-large);
  color: var(--mi-secondary-color-2);
}
.share-dialog-container .panel-1 .shared-object-container .shared-object .text-container .my-ao-text {
  margin: 0;
  font-weight: var(--font-weight-regular);
  font-size: var(--font-size-small);
  color: var(--mi-color-text-lite);
}
.share-dialog-container .panel-1 .shared-object-container .shared-object .image {
  position: relative;
  grid-row: 1;
  grid-column: 2;
  height: 75px;
  width: 75px;
  --border-radius: 5px;
}
.share-dialog-container .panel-1 .shared-object-container .shared-object .image ion-img {
  height: 75px;
  width: 75px;
  object-fit: cover;
}
.share-dialog-container .panel-2 {
  grid-row: 2;
  grid-column: 1;
  background-color: var(--mi-white-color);
  border-radius: var(--panel-2-border-radius);
  position: relative;
}
.share-dialog-container .panel-2.selected-participants-view {
  grid-template-columns: 100%;
  grid-template-rows: 100%;
}
.share-dialog-container .panel-2 ion-content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  --background: transparent;
}
.share-dialog-container .panel-2 ion-content .content-wrapper {
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: min-content 1fr;
  row-gap: 25px;
  padding: var(--panel-2-padding);
}
.share-dialog-container .panel-2 ion-content .content-wrapper .share-to-apps-container {
  grid-row: 3;
  grid-column: 1;
  display: grid;
  grid-template-columns: 100%;
  grid-auto-flow: row;
  grid-auto-rows: min-content;
  row-gap: 15px;
  padding: 15px 10px;
  background-color: var(--mi-light-purple-1-color);
  border-radius: 9px;
}
.share-dialog-container .panel-2 ion-content .content-wrapper .share-to-apps-container .card-title-container {
  display: grid;
  grid-template-rows: auto;
  grid-auto-flow: column;
  grid-auto-columns: min-content;
  -moz-column-gap: 10px;
       column-gap: 10px;
  align-items: center;
}
.share-dialog-container .panel-2 ion-content .content-wrapper .share-to-apps-container .card-title-container .title {
  margin: 0;
  color: var(--mi-secondary-color-2);
  font-size: var(--font-size-regular);
  font-weight: var(--font-weight-medium);
  white-space: nowrap;
}
.share-dialog-container .panel-2 ion-content .content-wrapper .share-to-apps-container .card-title-container .chip-container {
  display: grid;
  grid-template-rows: auto;
  grid-auto-flow: column;
  grid-auto-columns: min-content;
  -moz-column-gap: 5px;
       column-gap: 5px;
  align-items: center;
  padding: 5px 10px;
  border-radius: 15px;
  background-color: var(--mi-purple-color-2);
}
.share-dialog-container .panel-2 ion-content .content-wrapper .share-to-apps-container .card-title-container .chip-container .chip-icon {
  color: var(--mi-purple-color);
  --icon-size: 12px;
}
.share-dialog-container .panel-2 ion-content .content-wrapper .share-to-apps-container .card-title-container .chip-container .chip-text {
  margin: 0;
  color: var(--mi-purple-color);
  font-size: var(--font-size-tiny);
  font-weight: var(--font-weight-regular);
  white-space: nowrap;
}
.share-dialog-container .panel-2 ion-content .content-wrapper .share-to-apps-container .link-container {
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: auto min-content min-content;
  align-items: center;
  -moz-column-gap: 7.5px;
       column-gap: 7.5px;
  background-color: var(--mi-white-color);
  padding: 7.5px;
  border-radius: 7px;
}
.share-dialog-container .panel-2 ion-content .content-wrapper .share-to-apps-container .link-container .link {
  grid-row: 1;
  grid-column: 1;
  width: 100%;
  height: 16px;
  position: relative;
  overflow: hidden;
}
.share-dialog-container .panel-2 ion-content .content-wrapper .share-to-apps-container .link-container .link .link-wrapper {
  width: 100%;
  position: absolute;
}
.share-dialog-container .panel-2 ion-content .content-wrapper .share-to-apps-container .link-container .link .link-wrapper p {
  font-size: var(--font-size-small);
  font-weight: var(--font-weight-regular);
  text-align: left;
  color: var(--mi-color-text-lite);
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.share-dialog-container .panel-2 ion-content .content-wrapper .share-to-apps-container .link-container .separator {
  grid-row: 1;
  grid-column: 2;
  margin: 0;
  font-size: var(--font-size-large);
  font-weight: var(--font-weight-regular);
  color: var(--mi-color-text-lite);
}
.share-dialog-container .panel-2 ion-content .content-wrapper .share-to-apps-container .link-container .copy-link-button {
  grid-row: 1;
  grid-column: 3;
  background: none;
  outline: none;
  border: none;
  box-shadow: none;
  cursor: pointer;
}
.share-dialog-container .panel-2 ion-content .content-wrapper .share-to-apps-container .link-container .copy-link-button span {
  white-space: nowrap;
  font-size: var(--font-size-tiny);
  font-weight: var(--font-weight-medium);
  color: var(--mi-primary-color);
}
.share-dialog-container .panel-2 ion-content .content-wrapper .share-to-apps-container .apps-container {
  width: 100%;
  display: grid;
  grid-template-rows: auto;
  grid-auto-flow: column;
  grid-auto-columns: 1fr;
  -moz-column-gap: 10px;
       column-gap: 10px;
  align-items: center;
}
.share-dialog-container .panel-2 ion-content .content-wrapper .share-to-apps-container .apps-container .share-to-app-button {
  display: grid;
  grid-template-columns: 100%;
  grid-auto-flow: row;
  grid-auto-rows: min-content;
  row-gap: 5px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.share-dialog-container .panel-2 ion-content .content-wrapper .share-to-apps-container .apps-container .share-to-app-button .icon-container {
  width: 36px;
  height: 36px;
  background-color: var(--mi-white-color);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  justify-self: center;
}
.share-dialog-container .panel-2 ion-content .content-wrapper .share-to-apps-container .apps-container .share-to-app-button .icon-container .icon {
  --icon-size: 24px;
}
.share-dialog-container .panel-2 ion-content .content-wrapper .share-to-apps-container .apps-container .share-to-app-button .button-text-container .button-text {
  margin: 0;
  white-space: nowrap;
  font-size: var(--font-size-tiny);
  font-weight: var(--font-weight-regular);
  color: var(--mi-color-text-lite);
  text-align: center;
}
.share-dialog-container .panel-2 ion-content .content-wrapper .share-to-apps-container .apps-container .share-to-app-button.whatsapp-button .icon-container .icon, .share-dialog-container .panel-2 ion-content .content-wrapper .share-to-apps-container .apps-container .share-to-app-button.email-button .icon-container .icon {
  --icon-size: 38px;
}
.share-dialog-container .panel-2 ion-content .content-wrapper .share-to-apps-container .apps-container .share-to-app-button.more-button .icon-container .icon {
  --icon-size: 30px;
  color: var(--mi-primary-color);
}
.share-dialog-container .panel-2 ion-content .content-wrapper .profile-qrcode-container {
  display: flex;
  align-items: center;
  justify-content: center;
}
.share-dialog-container .panel-2 ion-content .content-wrapper .send-to-myao-network-container {
  grid-row: 2;
  grid-column: 1;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: min-content min-content min-content;
  row-gap: 20px;
}
.share-dialog-container .panel-2 ion-content .content-wrapper .send-to-myao-network-container .card-title-container {
  grid-row: 1;
  grid-column: 1;
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: auto min-content;
  -moz-column-gap: 10px;
       column-gap: 10px;
  align-items: center;
}
.share-dialog-container .panel-2 ion-content .content-wrapper .send-to-myao-network-container .card-title-container .title {
  grid-row: 1;
  grid-column: 1;
  margin: 0;
  white-space: nowrap;
  font-size: var(--font-size-regular);
  font-weight: var(--font-weight-medium);
  color: var(--mi-secondary-color-2);
  text-align: left;
}
.share-dialog-container .panel-2 ion-content .content-wrapper .send-to-myao-network-container .card-title-container .button-container {
  grid-row: 1;
  grid-column: 2;
}
.share-dialog-container .panel-2 ion-content .content-wrapper .send-to-myao-network-container .card-title-container .button-container .send-to-recipients-button {
  --border-radius: 8px;
}
.share-dialog-container .panel-2 ion-content .content-wrapper .send-to-myao-network-container .post-to-your-network-container {
  grid-row: 1;
  grid-column: 1;
  margin-bottom: 20px;
}
.share-dialog-container .panel-2 ion-content .content-wrapper .send-to-myao-network-container .direct-message-users-container .title-and-action-container {
  display: flex;
  justify-content: space-between;
}
.share-dialog-container .panel-2 ion-content .content-wrapper .send-to-myao-network-container .direct-message-users-container .title-and-action-container .title {
  font-size: var(--font-size-regular);
  font-weight: var(--font-weight-medium);
  color: var(--mi-grey-color-16);
}
.share-dialog-container .panel-2 ion-content .content-wrapper .send-to-myao-network-container .direct-message-users-container .title-and-action-container .action {
  font-size: var(--font-size-small);
  font-weight: var(--font-weight-regular);
  color: var(--mi-color-text-lite);
  cursor: pointer;
}
.share-dialog-container .panel-2 ion-content .content-wrapper .send-to-myao-network-container .direct-message-users-container .users-container .users-empty-state {
  font-size: var(--font-size-large);
  font-weight: var(--font-weight-medium);
  color: var(--mi-grey-color-13);
  text-align: center;
  padding: 30px;
}
.share-dialog-container .panel-2 ion-content .content-wrapper .send-to-myao-network-container .recipients-container {
  grid-row: 3;
  grid-column: 1;
  width: 100%;
  border: 1px solid var(--mi-grey-color-7);
  border-radius: 9px;
  padding: 15px;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: min-content min-content;
  row-gap: 20px;
}
.share-dialog-container .panel-2 ion-content .content-wrapper .send-to-myao-network-container .recipients-container .info-message {
  margin: 0;
  font-size: var(--font-size-regular);
  font-weight: var(--font-weight-regular);
  color: var(--mi-color-text-lite);
  text-align: left;
}
.share-dialog-container .panel-2 ion-content .content-wrapper .send-to-myao-network-container .recipients-container .list-container {
  width: 100%;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: min-content auto;
  row-gap: 10px;
}
.share-dialog-container .panel-2 ion-content .content-wrapper .send-to-myao-network-container .recipients-container .list-container .list-text-container {
  display: grid;
  grid-template-columns: 100%;
  grid-auto-flow: row;
  grid-auto-rows: min-content;
  row-gap: 15px;
}
.share-dialog-container .panel-2 ion-content .content-wrapper .send-to-myao-network-container .recipients-container .list-container .list-text-container .list-title {
  margin: 0;
  white-space: nowrap;
  font-size: var(--font-size-regular);
  font-weight: var(--font-weight-medium);
  color: var(--mi-color-text-lite);
  text-align: left;
}
.share-dialog-container .panel-2 ion-content .content-wrapper .send-to-myao-network-container .recipients-container .groups-container {
  grid-row: 2;
  grid-column: 1;
}
.share-dialog-container .panel-2 ion-content .content-wrapper .send-to-myao-network-container .recipients-container .contacts-container {
  grid-row: 1;
  grid-column: 1;
}
.share-dialog-container .panel-2 ion-content .content-wrapper .send-to-myao-network-container .recipients-container .contacts-container .contacts-list-container .contacts-list {
  display: grid;
  grid-auto-flow: row;
  grid-auto-rows: min-content;
  grid-template-columns: 100%;
  row-gap: 20px;
}
.share-dialog-container .panel-2 ion-content .content-wrapper .selected-recipients-and-message-container {
  display: grid;
  grid-template-columns: 100%;
  grid-auto-flow: row;
  grid-auto-rows: min-content;
}
.share-dialog-container .panel-2 ion-content .content-wrapper .selected-recipients-and-message-container .title-container {
  padding-bottom: 15px;
}
.share-dialog-container .panel-2 ion-content .content-wrapper .selected-recipients-and-message-container .title-container .title {
  margin: 0;
  color: var(--mi-grey-color-16);
  font-size: var(--font-size-regular);
  font-weight: var(--font-weight-medium);
}
.share-dialog-container .panel-2 ion-content .content-wrapper .selected-recipients-and-message-container .selected-recipients-slider-container {
  width: 100%;
  padding-bottom: 10px;
}
.share-dialog-container .panel-2 ion-content .content-wrapper .selected-recipients-and-message-container .advice-needed-container {
  width: 100%;
  padding-bottom: 20px;
}
.share-dialog-container .panel-2 ion-content .content-wrapper .selected-recipients-and-message-container .message-box-container {
  width: 100%;
  min-height: 224px;
  padding-bottom: 50px;
}
.share-dialog-container .panel-2 ion-content .content-wrapper .selected-recipients-and-message-container .message-box-container app-text-field-limit-counter-wrapper ::ng-deep .text-field-limit-counter-wrapper {
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: auto min-content;
}
.share-dialog-container .panel-2 ion-content .content-wrapper .selected-recipients-and-message-container .message-box-container app-text-field-limit-counter-wrapper ::ng-deep .text-field-limit-counter-wrapper .text-field-container {
  grid-row: 1;
  grid-column: 1;
  background-color: var(--mi-grey-color-9);
  border-radius: 7px;
}
.share-dialog-container .panel-2 ion-content .content-wrapper .selected-recipients-and-message-container .message-box-container app-text-field-limit-counter-wrapper ::ng-deep .text-field-limit-counter-wrapper .text-field-container app-rich-text-document-editor {
  --rich-text-document-editor-padding-top: 20px;
  --rich-text-document-editor-padding-bottom: 20px;
  --rich-text-document-editor-padding-start: 15px;
  --rich-text-document-editor-padding-end: 15px;
  --rich-text-document-editor-background-color: var(--mi-grey-color-9);
}
.share-dialog-container .panel-2 ion-content .content-wrapper .selected-recipients-and-message-container .message-box-container app-text-field-limit-counter-wrapper ::ng-deep .text-field-limit-counter-wrapper .limit-counter {
  grid-row: 2;
  grid-column: 1;
}
.share-dialog-container .panel-2 ion-content .content-wrapper .selected-recipients-and-message-container .buttons-container ion-button {
  width: 100%;
}
@media all and (min-width: 1000px) {
  .share-dialog-container {
    grid-template-columns: 50% 50%;
    grid-template-rows: auto;
    -moz-column-gap: 25px;
         column-gap: 25px;
    padding: 50px;
  }
  .share-dialog-container .panel-1 {
    grid-row: 1;
    grid-column: 1;
  }
  .share-dialog-container .panel-2 {
    grid-row: 1;
    grid-column: 2;
    width: 100%;
  }
}
@media all and (max-height: 750px) {
  .share-dialog-container.mobile {
    --panel-1-row-gap: 10px;
  }
  .share-dialog-container.mobile .panel-2 {
    row-gap: 12.5px;
  }
  .share-dialog-container.mobile .panel-2 .send-to-myao-network-container {
    row-gap: 10px;
  }
  .share-dialog-container.mobile .panel-2 .send-to-myao-network-container .groups-container {
    row-gap: 10px;
  }
}

.hidden {
  display: none !important;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
