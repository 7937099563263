import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Observable, Subscription } from 'rxjs';

// models
import { Conversation } from 'src/app/services/yeti-protocol/conversation';

// services
import { ConversationInfo } from '../messenger.model';
import { Store } from '@ngxs/store';
import { ActiveConversationsState } from 'src/app/state/active-conversations/active-conversations.state';

@Component({
  selector: 'app-active-conversations-list',
  templateUrl: './active-conversations-list.component.html',
  styleUrls: ['./active-conversations-list.component.scss'],
})
export class ActiveConversationsListComponent implements OnInit, OnDestroy {

  @Input() count = 20;
  @Input() currentConversationId: string;

  @Output() openConversation: EventEmitter<ConversationInfo> = new EventEmitter<ConversationInfo>();

  conversations: Array<Conversation> = [];
  activeConversations$: Observable<Array<Conversation>>;

  private conversationsSubscription: Subscription;
  private activeConversationsSubscription: Subscription;

  constructor(
    private store: Store
  ) {
    this.activeConversations$ = this.store.select(ActiveConversationsState.activeConversations);
  }

  ngOnInit(): void {
    this.activeConversationsSubscription = this.activeConversations$.subscribe(activeConversations => {
      this.conversations = activeConversations;
    });
  }

  ngOnDestroy(): void {
    this.activeConversationsSubscription?.unsubscribe();
  }

  onOpenConversation(info: ConversationInfo): void {
    this.openConversation.emit(info);
  }
}
