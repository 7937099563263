import { Inject, Injectable } from '@angular/core';
import { LocationService } from '../location.service';

import appConfig from 'src/config/config';
import { ContextConfigInterface, ContextConfigType, Platform } from 'src/config/config.model';
import { LOCAL_STORAGE_SERVICE, StorageService } from '../storage/storage.service';
import { ContextService } from './context.model';
import { isMobilePlatform } from '../utils/utils';
import { KEY_PROFILE } from 'src/app/services/auth/mi/mi-auth.service';

const LAST_CONTEXT_KEY = 'last-used-context';

interface ContextServiceConfig {
  contexts: Array<ContextConfigType>;
  platform: Platform;
}

@Injectable()
export class ContextServiceImpl implements ContextService {

  config: ContextServiceConfig = appConfig;

  constructor(
    private locationService: LocationService,
    @Inject(LOCAL_STORAGE_SERVICE) private storageService: StorageService,
  ) { }

  get currentContext(): ContextConfigInterface {
    const currentContext = this.currentPathContext;
    return currentContext ? currentContext : this.defaultContext;
  }

  async getCurrentContext(preventDefault?: boolean): Promise<ContextConfigInterface> {

    let contextFromSavedUserProfile: ContextConfigInterface;

    try {
      contextFromSavedUserProfile = await this.getContextFromSavedUserProfile();
    } catch (err) {
      console.error(err);
    }

    const currentContext = this.currentPathContext;

    if (currentContext && contextFromSavedUserProfile && currentContext?.key !== contextFromSavedUserProfile?.key) {
      return contextFromSavedUserProfile;
    } else {
      if (currentContext) {
        return currentContext;
      } else if (contextFromSavedUserProfile) {
        return contextFromSavedUserProfile;
      }
    }

    return preventDefault ? null : this.defaultContext;
  }

  async getContextFromSavedUserProfile(): Promise<ContextConfigInterface> {
    try {
      const data = await this.storageService.get(KEY_PROFILE);

      if (data && data.context) {
        return this.config.contexts.find(context => {
          return context.key === data.context;
        });
      }

      return null;
    } catch (err) {
      return null;
    }
  }

  getContext(contextKey: string): ContextConfigInterface {
    return this.config.contexts.find(context => {
      return context.key === contextKey;
    });
  }

  get defaultContext(): ContextConfigInterface {
    return this.config.contexts[0];
  }

  async saveCurrentContextKey(): Promise<void> {
    if (!this._isMobile) {
      throw new Error('current context saved only on mobile platform')
    }

    let currentContext = '';

    try {
      currentContext = (await this.getCurrentContext(true))?.key;
    } catch (err) {
      console.error(err);
    }

    if (!currentContext?.length) {
      return Promise.resolve();
    }

    return this.storageService.set(LAST_CONTEXT_KEY, currentContext);
  }

  getLastSavedContextKey(): Promise<string> {
    if (!this._isMobile) {
      throw new Error('current context saved only on mobile platform')
    }
    return this.storageService.get(LAST_CONTEXT_KEY);
  }

  get currentPathContext(): ContextConfigInterface {
    const pathName = this.locationService.pathname;
    return this.config.contexts.find(context => {
      return context.homePath === pathName.substr(0, context.homePath.length);
    });
  }

  get isSingleContextApp(): boolean {
    return this.config.contexts.length === 1;
  }

  get _isMobile(): boolean {
    return isMobilePlatform(this.config.platform);
  }
}
