import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// components
import { SurgeryReferenceSelectCardComponent } from './surgery-reference-select-card/surgery-reference-select-card.component';
import { SurgeryReferenceReviewCardComponent } from './surgery-reference-review-card/surgery-reference-review-card.component';

// modules
import { MIComponentsModule } from 'src/app/components/components.module';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { ChipsModule } from '../chips/chips.module';

@NgModule({
  declarations: [
    SurgeryReferenceSelectCardComponent,
    SurgeryReferenceReviewCardComponent,
  ],
  exports: [
    SurgeryReferenceSelectCardComponent,
    SurgeryReferenceReviewCardComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    TranslateModule,
    MIComponentsModule,
    ChipsModule
  ]
})
export class SurgeryReferenceModule { }
