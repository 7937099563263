import { Component, EventEmitter, Inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import { IonInfiniteScroll } from '@ionic/angular';
import { GroupsService } from '../../services/groups/groups.service';
import { ToastMode, ToastService } from '../../services/toast.service';
import { AppNavController } from '../../services/app-nav-controller.service';
import { ClinicalExpertsService } from '../../services/clinical-experts.service';
import { Store } from '@ngxs/store';
import { Posts } from '../../state/posts/posts.actions';
import { ActionSource, ActionTracked } from '../../services/yeti-protocol/tracking';
import { TRACKING_SERVICE, TrackingService } from '../../services/tracking/tracking.model';
import { PublicProfile } from '../../services/yeti-protocol/public-profile';
import { AuthService } from 'src/app/services/auth/auth.service';
import { CONTEXT_SERVICE, ContextService } from 'src/app/services/context/context.model';
import { UserProfile } from 'src/app/services/yeti-protocol/auth/mi';
import { PREVENT_PROFILE_RELOAD_BELLOW_X_MS_DIFF } from '../../services/auth/mi/mi-auth.service';

@Component({
  selector: 'app-top-contributors-list',
  templateUrl: './top-contributors-list.component.html',
  styleUrls: ['./top-contributors-list.component.scss'],
})
export class TopContributorsListComponent implements OnInit {

  @Input() groupId?: string;
  @Input() numberOfItemsToShow: number;
  @Input() source: ActionSource;
  @ViewChild(IonInfiniteScroll) infiniteScroll: IonInfiniteScroll;
  @Output() openPublicProfileEmitted: EventEmitter<void> = new EventEmitter<void>();
  @Output() emptyListEmitted: EventEmitter<void> = new EventEmitter<void>();
  @Output() contributorModified: EventEmitter<PublicProfile> = new EventEmitter<PublicProfile>();

  loading: boolean;
  groupTotalContributorsList: Array<PublicProfile> = [];
  profile: UserProfile;
  private totalTopContributorsCount: number;

  private readonly start = 0;
  private readonly count = 15;

  constructor(
    private groupsService: GroupsService,
    private toast: ToastService,
    private appNavController: AppNavController,
    private clinicalExpertService: ClinicalExpertsService,
    private store: Store,
    private authService: AuthService,
    @Inject(CONTEXT_SERVICE) private contextService: ContextService,
    @Inject(TRACKING_SERVICE) private trackingService: TrackingService,
  ) {
  }


  ngOnInit(): void {
    this.loading = true;
    this.getGroupTopContributors(this.start, this.count, this.groupId)
      .then(response => {
        this.groupTotalContributorsList = response;
        if (!response.length) {
          this.emptyListEmitted.emit();
        }
      })
      .catch(err => {
        this.showError(err);
      }).finally(() => {
        this.loading = false;
      });

    this.authService.getProfile(
      this.contextService.currentContext.key,
      true,
      PREVENT_PROFILE_RELOAD_BELLOW_X_MS_DIFF).then((userProfile: UserProfile) => {
        this.profile = userProfile;
      });
  }

  async getGroupTopContributors(start: number, count: number, groupId?: string): Promise<Array<PublicProfile>> {
    try {
      const topContributorsResponse =
        await (groupId
          ? this.groupsService.getGroupTopContributors(groupId, start, count)
          : this.groupsService.getRecommendedTopContributors(start, count)
        );
      if (topContributorsResponse) {
        this.totalTopContributorsCount = topContributorsResponse?.totalItemsCount;
        const finalTotalContributorsResult = this.groupTotalContributorsList.concat(topContributorsResponse.result);
        return Promise.resolve(finalTotalContributorsResult);
      }
    } catch (err) {
      return Promise.reject(err);
    }
  }

  loadMoreTopContributors(_event: Event): void {
    if (this.totalTopContributorsCount <= this.groupTotalContributorsList.length) {
      this.disableInfiniteScroll(true);
      return;
    }

    const start = Math.floor(this.groupTotalContributorsList.length / this.count);
    this.getGroupTopContributors(start, this.count, this.groupId).then((response) => {
      this.groupTotalContributorsList = response;
    }).catch(err => {
      this.showError(err.message);
    }).finally(() => {
      this.infiniteScroll.complete();
    });
  }

  onFollowButtonClicked(topContributor: PublicProfile): void {
    this.trackAction(topContributor.userId, 'user', topContributor.isFollower ? ActionTracked.userUnfollow : ActionTracked.userFollow)
    this.clinicalExpertService.updateClinicalExpertFollow(topContributor.userId, !topContributor.isFollower).then(async response => {
      if (response) {
        topContributor.isFollower = !topContributor.isFollower;
        const topContributorIndex = this.groupTotalContributorsList.findIndex(contributor => contributor.userId === topContributor.userId);
        this.groupTotalContributorsList[topContributorIndex] = topContributor;
        this.contributorModified.emit(topContributor);
        this.store.dispatch(new Posts.UpdatePostsOwnerFollowingStatus(topContributor.userId, topContributor.isFollower));
        const msgKey = topContributor.isFollower ? 'app.PublicProfilePage.followed' : 'app.PublicProfilePage.unfollowed';
        this.toast.show(msgKey);
      }
    });
  }

  onOpenPublicProfile(userId: string): void {
    this.trackAction(userId, 'user', ActionTracked.publicProfileOpened);
    this.appNavController.openPublicUserProfile(userId);
    this.openPublicProfileEmitted.emit()
  }

  trackAction(objectId: string, objectType: string, objectTitle: string): void {
    this.trackingService.trackGenericClickedAction(objectId, objectType, objectTitle,
      { source: this.source || ActionSource.unspecified })
      .catch(_err => {
        console.error('Could not track click action.');
      });
  }

  isLoggedInUser(topContributor: PublicProfile): boolean {
    return this.profile?.id === topContributor?.userId;
  }

  showFollow(topContributor: PublicProfile): boolean {
    return this.isLoggedInUser(topContributor) ? false : true;
  }

  private disableInfiniteScroll(value: boolean): void {
    if (this.infiniteScroll) {
      this.infiniteScroll.disabled = value;
    }
  }

  private showError(msg: string): void {
    this.toast.showWithMessage(msg, 'app.common.error-default', ToastMode.ERROR);
  }

}
