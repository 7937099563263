import { Component, EventEmitter, Input, Output } from '@angular/core';

export interface AcceptRejectItemData {
  id: string;
  imageUrl: string;
  userId: string;
  isAOMember: string;
  firstName: string;
  lastName: string;
  isVerified: string;
  entityTitle: string;
  infoBubbleMessage?: string;
}

@Component({
  selector: 'app-accept-reject-list',
  templateUrl: './accept-reject-list.component.html',
  styleUrls: ['./accept-reject-list.component.scss'],
})
export class AcceptRejectListComponent {

  @Input() title: string;
  @Input() subtitle: string;
  @Input() items: Array<AcceptRejectItemData> = [];
  @Input() showSeeAll: boolean;
  @Input() count: number;

  @Output() accepted: EventEmitter<{ id: string, userId: string }> = new EventEmitter();
  @Output() rejected: EventEmitter<{ id: string, userId: string }> = new EventEmitter();
  @Output() imageClicked: EventEmitter<string> = new EventEmitter();
  @Output() seeAll: EventEmitter<void> = new EventEmitter();
  @Output() infoBubbleMessageClicked: EventEmitter<{ id: string, userId: string }> = new EventEmitter();

  onAccepted(data: { id: string, userId: string }): void {
    this.accepted.emit(data);
  }

  onRejected(data: { id: string, userId: string }): void {
    this.rejected.emit(data);
  }

  onImageClicked(userId: string): void {
    this.imageClicked.emit(userId);
  }

  seeAllClicked(): void {
    this.seeAll.emit();
  }

  onInfoBubbleMessageClicked(data: { id: string, userId: string }): void {
    this.infoBubbleMessageClicked.emit(data);
  }

}
