// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  --button-border-radius: 50px;
}

.swipe-button-container {
  width: 100%;
  height: 76px;
  background-color: var(--mi-purple-color-4);
  border-radius: var(--button-border-radius);
  position: relative;
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: min-content auto min-content;
  -moz-column-gap: 20px;
       column-gap: 20px;
  align-items: center;
  overflow: hidden;
}
.swipe-button-container .swipeable-element {
  position: absolute;
  width: 100%;
  height: 100%;
  padding: 6px 9px;
  border-radius: var(--button-border-radius);
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: 1fr min-content;
  z-index: 2;
  transform: translateX(calc(-100% + 85px));
  transition: transform 0.2s ease;
  background-color: var(--mi-purple-color-4);
}
.swipe-button-container .swipeable-element .overlay {
  grid-row: 1;
  grid-column: 1;
}
.swipe-button-container .swipeable-element .button {
  grid-row: 1;
  grid-column: 2;
  width: 65px;
  height: 65px;
  border-radius: 50%;
  background-color: var(--mi-blue-color-4);
  left: 9px;
  top: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-user-select: none;
          user-select: none;
  z-index: 3;
}
.swipe-button-container .swipeable-element .button app-icon {
  --icon-size: 50px;
  color: var(--mi-primary-color);
}
.swipe-button-container .swipeable-element .beginning-color {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  background-color: var(--mi-purple-color-4);
  opacity: 1;
  border-radius: var(--button-border-radius);
}
.swipe-button-container .swipeable-element .end-color {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  background-color: var(--mi-primary-color);
  opacity: 0;
  border-radius: var(--button-border-radius);
}
.swipe-button-container .empty-container {
  grid-row: 1;
  grid-column: 1;
  width: 65px;
}
.swipe-button-container .button-text-container {
  grid-row: 1;
  grid-column: 2;
}
.swipe-button-container .button-text-container .text {
  margin: 0;
  font-size: var(--font-size-large);
  font-weight: var(--font-weight-regular);
  color: var(--mi-secondary-color-2);
  text-align: center;
}
.swipe-button-container .icons-container {
  grid-row: 1;
  grid-column: 3;
  display: grid;
  grid-template-rows: auto;
  grid-auto-flow: column;
  grid-auto-columns: min-content;
  align-items: center;
  padding-right: 9px;
}
.swipe-button-container .icons-container app-icon {
  --icon-size: 18px;
  color: var(--mi-grey-color-16);
}
.swipe-button-container .icons-container app-icon:nth-of-type(1) {
  opacity: 0.15;
}
.swipe-button-container .icons-container app-icon:nth-of-type(2) {
  opacity: 0.3;
}
.swipe-button-container .icons-container app-icon:nth-of-type(3) {
  opacity: 0.45;
}
.swipe-button-container .icons-container app-icon:nth-of-type(4) {
  opacity: 0.6;
}
.swipe-button-container .icons-container app-icon:nth-of-type(5) {
  opacity: 0.75;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
