import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { PublicProfile } from '../../services/yeti-protocol/public-profile';
import { GetOrcIdArticlesResponse, GetOrcIdArticlesSuccessResponse, OrcIdArticle } from '../../services/yeti-protocol/article';
import { IonRefresher, ModalController } from '@ionic/angular';
import { AppTranslationService } from '../../services/app-translation.service';
import { ToastMode, ToastService } from '../../services/toast.service';
import { Subscription } from 'rxjs';
import { UserProfile } from '../../services/yeti-protocol/auth/mi';
import { AuthService } from '../../services/auth/auth.service';
import { ArticleService } from '../../services/article/article.service';
import { VerticalListLoadMoreData } from 'src/app/components/vertical-list/vertical-list.component';
import { LinkOpenerService } from 'src/app/services/link-opener.service';
import { TargetImpression, VisibilityTrackerService } from 'src/app/modules/visibility-tracker/visibility-tracker.service';
import { ActionTracked, ImpressionTrackingRequest, OrcIdTrackingParam, TrackingRequest } from 'src/app/services/yeti-protocol/tracking';
import { TRACKING_SERVICE, TrackingService } from 'src/app/services/tracking/tracking.model';

const SOURCE = 'seeAllOrcIdPublicationsDialog';

@Component({
  selector: 'app-orcid-publications-dialog',
  templateUrl: './orcid-publications-dialog.component.html',
  styleUrls: ['./orcid-publications-dialog.component.scss'],
})
export class OrcidPublicationsDialogComponent implements OnInit, OnDestroy {

  @Input() userPublicProfile: PublicProfile;

  userProfile: UserProfile;
  orcIdArticles: Array<OrcIdArticle>;
  loading: boolean;
  totalArticlesCount: number;

  readonly count = 10;
  readonly visibilityRootKey = 'orcid-publications-impressions-tracking-root';

  private userProfileSubscription: Subscription;
  private itemsTrackingSubscription: Subscription;

  constructor(
    private modalController: ModalController,
    private appTranslationService: AppTranslationService,
    private toast: ToastService,
    private authService: AuthService,
    private articleService: ArticleService,
    private linkOpenerService: LinkOpenerService,
    @Inject(TRACKING_SERVICE) private trackingService: TrackingService,
    private visibilityTrackerService: VisibilityTrackerService
  ) { }

  ngOnInit(): void {

    this.userProfileSubscription = this.authService.userProfileAsObservable.subscribe(userProfile => {
      this.userProfile = userProfile;
      this.getOrcIdArticles();
    });

    this.itemsTrackingSubscription = this.visibilityTrackerService.impressionsAsObservable.subscribe(targetImression => {
      this.trackListItemImpression(targetImression);
    });
  }

  ngOnDestroy(): void {
    this.userProfileSubscription?.unsubscribe();
    this.itemsTrackingSubscription?.unsubscribe();
  }

  get title(): string {
    return this.userPublicProfile ? this.appTranslationService.instant('app.dialogs.OrcidPublicationsDialog.publications-title') :
      this.appTranslationService.instant('app.dialogs.OrcidPublicationsDialog.my-publications-title');
  }

  onClose(): void {
    this.modalController.dismiss();
  }

  async getOrcIdArticles(start = 0): Promise<GetOrcIdArticlesResponse | null> {

    if (!this.isOrcIdLinked || this.loading) {
      return Promise.resolve(null);
    }

    this.loading = true;

    const userId = this.userPublicProfile ? this.userId : undefined;

    const promise = this.articleService.getOrcIdArticles(userId, start, this.count)

    promise.then((res: GetOrcIdArticlesResponse) => {

      if (!this.orcIdArticles) {
        this.orcIdArticles = [];
      }

      this.orcIdArticles = [...this.orcIdArticles, ...(res as GetOrcIdArticlesSuccessResponse).result];
      this.totalArticlesCount = (res as GetOrcIdArticlesSuccessResponse).totalItemsCount;
    }).catch(err => {
      console.error(err);
      this.showError(err?.error?.error?.message?.errfor?.message);
    }).finally(() => {
      this.loading = false;
    });

    return promise;
  }

  refreshOrcIdArticles(refresher: IonRefresher): void {

    this.orcIdArticles = [];
    this.totalArticlesCount = 0;

    this.getOrcIdArticles()?.finally(() => {
      refresher.complete();
    });
  }

  loadMoreOrcIdArticles(verticalListLoadMoreData: VerticalListLoadMoreData): void {

    const start = Math.floor(this.orcIdArticles?.length / this.count);

    this.getOrcIdArticles(start)?.finally(() => {
      verticalListLoadMoreData.infiniteScroll.complete();
    });
  }

  get orcId(): string {

    if (this.userPublicProfile) {
      return this.userPublicProfile?.orcid || '';
    }

    return this.userProfile?.orcid || '';
  }

  get userId(): string {

    if (this.userPublicProfile) {
      return this.userPublicProfile?.userId || '';
    }

    return this.userProfile?.userId || '';
  }

  get isOrcIdLinked(): boolean {
    return this.orcId.length ? true : false;
  }

  private showError(msg: string): void {
    this.toast.showWithMessage(msg, 'app.common.error-default', ToastMode.ERROR);
  }

  orcIdArticleClicked(orcIdArticle: OrcIdArticle): void {
    this.trackOrcIdArticleOpened(orcIdArticle);
    this.linkOpenerService.open(orcIdArticle?.origin || '');
  }

  trackOrcIdArticleOpened(orcIdArticle: OrcIdArticle): void {

    const paramsToTrack: OrcIdTrackingParam = {
      id: orcIdArticle?.id || orcIdArticle?._id,
      title: orcIdArticle?.title,
      ownerId: this.userPublicProfile ? this.userPublicProfile?.userId : this.userProfile?.userId,
      source: SOURCE,
    };

    const trackData: TrackingRequest = {
      action: ActionTracked.orcIdOpened,
      params: paramsToTrack
    };

    this.trackingService.track(trackData).catch(_err => {
      console.error('Could not track article opened action');
    });

  }

  trackListItemImpression(targetImpression: TargetImpression): void {

    if (targetImpression.rootKey !== this.visibilityRootKey) {
      return;
    }

    if (!targetImpression?.targetKey?.length) {
      console.error(`missing target key for item in list: ${targetImpression.rootKey}`);
      return;
    }

    const orcIdArticle = this.orcIdArticles.find(article => article?._id === targetImpression?.targetKey ||
      article?.id === targetImpression?.targetKey ||
      article?.origin === targetImpression?.targetKey);

    if (!orcIdArticle) {
      return;
    }

    const impressionTrackingRequest: ImpressionTrackingRequest = {
      objectId: targetImpression?.targetKey,
      objectType: 'orcidArticle'
    };

    const userId = this.userPublicProfile?.userId;

    if (userId) {
      (impressionTrackingRequest as any).recommendedByUser = userId;
    }

    impressionTrackingRequest.source = SOURCE;

    const objectTitle = (orcIdArticle as any)?.title || '';

    if (objectTitle) {
      impressionTrackingRequest.objectTitle = objectTitle;
    }

    this.trackingService.trackListItemImpression(impressionTrackingRequest);
  }

}
