// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  --image-border-radius: 10px;
}

.suggested-article-card {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: min-content auto;
  grid-template-rows: auto;
  -moz-column-gap: 10px;
       column-gap: 10px;
  justify-items: center;
}
.suggested-article-card .article-image-video-container {
  grid-row: 1;
  grid-column: 1;
  width: 75px;
  height: 75px;
}
.suggested-article-card .article-image-video-container .article-image-video {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.suggested-article-card .article-image-video-container .article-image-video ion-thumbnail {
  --border-radius: var(--image-border-radius);
  width: 100%;
  height: 100%;
  position: absolute;
}
.suggested-article-card .article-image-video-container .article-image-video .container-with-background {
  width: 100%;
  height: 100%;
  background-color: var(--mi-light-purple-color);
  border-radius: var(--image-border-radius);
  position: absolute;
}
.suggested-article-card .article-image-video-container .article-image-video .container-video-background {
  width: 100%;
  height: 100%;
  background-color: var(--mi-black-color);
  border-radius: var(--image-border-radius);
  opacity: 0.5;
  position: absolute;
  z-index: 2;
}
.suggested-article-card .article-image-video-container .article-image-video app-icon {
  --icon-size: 27px;
  color: var(--mi-white-color);
  z-index: 3;
}
.suggested-article-card .article-details-container {
  grid-row: 1;
  grid-column: 2;
  display: grid;
  grid-template-columns: 100%;
  grid-auto-flow: row;
  grid-auto-rows: min-content;
  row-gap: 10px;
}
.suggested-article-card .article-details-container .title-container app-multiline-text-with-ellipsis {
  --multiline-text-with-ellipsis-max-height: 47px;
}
.suggested-article-card .article-details-container .title-container app-multiline-text-with-ellipsis ::ng-deep {
  font-weight: var(--font-weight-medium);
  font-style: normal;
  font-size: var(--font-size-large);
  line-height: var(--font-line-height-large);
  color: var(--mi-secondary-color-2);
}
.suggested-article-card .article-details-container .author-container .author {
  font-weight: var(--font-weight-regular);
  font-style: normal;
  font-size: var(--font-size-small);
  line-height: var(--font-line-height-regular);
  color: var(--mi-secondary-color-2);
  margin: 0;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
