import { Component, EventEmitter, Input, Output } from '@angular/core';
import appConfig from 'src/config/config';

@Component({
  selector: 'app-user-info',
  templateUrl: './user-info.component.html',
  styleUrls: ['./user-info.component.scss'],
})
export class UserInfoComponent {

  @Input() userImageUrl: string;
  @Input() userId: string;
  @Input() aoMember: boolean;
  @Input() disableProfileOpen: boolean;
  @Input() firstName: string;
  @Input() lastName: string;
  @Input() isVerified: boolean;
  @Input() showCrown = false;
  @Input() country: string;
  @Input() showModeratorIcon = false;
  @Input() isAnonymousUser = false;
  @Input() userFullNameClickListener = false;
  @Input() showOrcIdIcon = false;

  @Output() profileImageClicked: EventEmitter<string> = new EventEmitter();
  @Output() userFullNameClicked: EventEmitter<string> = new EventEmitter();

  get firstNameVal(): string {
    return this.isAnonymousUser ? 'Identity' : this.firstName;
  }

  get lastNameVal(): string {
    return this.isAnonymousUser ? 'Hidden' : this.lastName;
  }

  get isVerifiedVal(): boolean {
    return this.isAnonymousUser ? false : this.isVerified;
  }

  get userImageUrlVal(): string {
    return this.isAnonymousUser ? appConfig.defaultProfileImageUrl : this.userImageUrl;
  }

  get aoMemberVal(): boolean {
    return this.isAnonymousUser ? false : this.aoMember;
  }

  onUserProfileClick(): void {
    this.profileImageClicked.emit(this.userId);
  }

  onUserFullNameClick(): void {
    this.userFullNameClicked.emit(this.userId);
  }
}
