import { Component, HostListener, Inject, Input } from '@angular/core';
import { Router } from '@angular/router';
import { CONTEXT_SERVICE, ContextService } from 'src/app/services/context/context.model';

// models
import { Article, Video } from 'src/app/services/yeti-protocol/article';
import { ActionSource } from '../../services/yeti-protocol/tracking';

// service


@Component({
  selector: 'app-suggested-article-card',
  templateUrl: './suggested-article-card.component.html',
  styleUrls: ['./suggested-article-card.component.scss'],
})
export class SuggestedArticleCardComponent {

  @Input() article: Article | Video;
  @Input() source = ActionSource;

  hideArticleImage: boolean;

  constructor(
    private router: Router,
    @Inject(CONTEXT_SERVICE) private contextService: ContextService
  ) { }

  @HostListener('click', ['$event'])
  onClick(): void {
    const url = '/' + [this.contextService.currentContext.key, 'article', this.article?._id].join('/');
    if (this.source) {
      this.router.navigate([url], { queryParams: { source: this.source } });
    } else {
      this.router.navigateByUrl(url);
    }
  }

  get showAuthor(): boolean {
    return this.article?.authors && this.article?.authors?.length > 0;
  }

  get isVideoArticle(): boolean {
    return this.article && (this.article?.channel === 'videos' ||
      this.article?.channel === 'kalturaVideo');
  }

  get showVideoIcon(): boolean {
    return this.isVideoArticle && this.article?.previewImageUrl && !this.hideArticleImage;
  }

}
