import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';

// components
import { MIPipesModule } from 'src/app/pipes/pipes.module';
import { ChipsComponent } from './chips/chips.component';
import { TextChipComponent } from './text-chip/text-chip.component';
import { SkillChipComponent } from './skill-chip/skill-chip.component';
import { IconModule } from '../icon/icon.module';
import { BetaChipComponent } from './beta-chip/beta-chip.component';

@NgModule({
  imports: [
    CommonModule,
    MIPipesModule,
    IonicModule,
    IconModule
  ],
  declarations: [
    TextChipComponent,
    SkillChipComponent,
    BetaChipComponent,
    ChipsComponent
  ],
  exports: [
    TextChipComponent,
    SkillChipComponent,
    BetaChipComponent,
    ChipsComponent
  ]
})
export class ChipsModule { }
