import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-group-public-view-member-item',
  templateUrl: './group-public-view-member-item.component.html',
  styleUrls: ['./group-public-view-member-item.component.scss'],
})
export class GroupPublicViewMemberItemComponent {

  @Input() imageUrl: string;
  @Input() id: string;
  @Input() firstname: string;
  @Input() lastname: string;
  @Input() country: string;
  @Input() isAoMember: boolean;
  @Input() showCrown = false;
  @Input() hideSettingsButton: boolean;
  @Input() isVerified: boolean;
}
