import { Inject, Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { firstValueFrom, from, Observable, Subject } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

// models
import {
  GroupsListResponse,
  GroupByIdResponse,
  GroupPostsListResponse,
  LeaveGroupResponse,
  GroupMembersResponse,
  PostByIdResponse,
  RequestToJoinGroupResponse,
  JoinGroupResponse,
  AcceptRejectGroupInvitationResponse,
  UpdatePostResponse,
  DeletePostResponse,
  LikeUnlikePostResponse,
  PostType,
  RemovePostAttachmentResponse,
  ReportAsAbuseResponse,
  MemberPendingInvitationsResponse,
  UpdatePostRequest,
  CaseFileType,
  CaseClassificationsResponse,
  LeaveGroupMemberStatus,
  LastActiveGroupsResponse,
  LastActiveGroupsRequestParams,
  DeleteGroupResponse,
  UpdateGroupMemberResponse,
  SearchGroupParticipantsResponse,
  ReportGroupResponse,
  GetGroupRecommendsResponse,
  GroupMemberPendingRequestsResponse,
  ShareObjectInMultipleGroupsEventsResponse,
  ShareObjectInMultipleGroupsEventsRequestData, SendReminderForGroupInvitationResponse, GetLikesResponse
} from '../../services/yeti-protocol/chatter-api';
import { Group } from './group.model';

// services
import { ChatterApiService } from '../../services/chatter-api.service';
import { AuthService } from '../auth/auth.service';
import { toAuthRequestParams } from '../auth/logic/auth-logic.utils';
import { AppTranslationService } from '../app-translation.service';
import { SchemaValidatorService } from '../schema-validator.service';
import { CONTEXT_SERVICE, ContextService } from '../context/context.model';
import appConfig from 'src/config/config';

export interface GroupServiceConfig {
  serverUrl: string;
}

@Injectable({
  providedIn: 'root'
})
export class GroupsService {
  config: GroupServiceConfig = {
    serverUrl: `${appConfig.chatterUrl}groups`,
  }
  private groupsChanges: Subject<Group> = new Subject();
  private groupsInvitationsChanged: Subject<void> = new Subject();
  private groupsRequestsChanged: Subject<void> = new Subject();

  constructor(
    private chatterApiService: ChatterApiService,
    private authService: AuthService,
    private alertController: AlertController,
    private appTranslationService: AppTranslationService,
    @Inject(CONTEXT_SERVICE) private contextService: ContextService,
    private schemaValidator: SchemaValidatorService
  ) { }

  getMemberGroups(start?: number, count?: number): Promise<GroupsListResponse> {
    return this.authService.isSignedIn()
      .then((isSignedIn: boolean) => {
        if (isSignedIn) {
          return this.chatterApiService.getMemberGroups(start, count);
        }
        return Promise.resolve({ result: [] });
      });
  }

  getMemberPendingInvitations(start?: number, count?: number): Promise<MemberPendingInvitationsResponse> {
    return this.chatterApiService.getMemberPendingInvitations(start, count);
  }

  getGroupMemberPendingRequests(start?: number, count?: number): Promise<GroupMemberPendingRequestsResponse> {
    return this.chatterApiService.getGroupMemberPendingRequests(start, count);
  }

  getRecommendedGroups(start: number, count: number): Promise<GroupsListResponse> {
    return this.chatterApiService.getRecommendedGroups(start, count)
  }

  getGroupById(groupId: string): Promise<GroupByIdResponse> {
    return this.chatterApiService.getGroupById(groupId);
  }

  getPostsInGroup(groupId: string, start?: number, count?: number): Promise<GroupPostsListResponse> {
    return this.chatterApiService.getPostsInGroup(groupId, start, count);
  }

  leaveGroup(groupId: string, memberId: string, status: LeaveGroupMemberStatus): Promise<LeaveGroupResponse> {
    return this.chatterApiService.leaveGroup(groupId, memberId, status);
  }

  deleteGroup(groupId: string): Promise<DeleteGroupResponse> {
    return this.chatterApiService.deleteGroup(groupId);
  }

  getGroupMembers(groupId: string, start?: number, count?: number, excludedUserIds?: Array<string>): Promise<GroupMembersResponse> {
    return this.chatterApiService.getGroupMembers(groupId, start, count, excludedUserIds);
  }

  getGroupTopContributors(groupId: string, start?: number, count?: number): Promise<GroupMembersResponse> {
    return this.chatterApiService.getGroupTopContributors(groupId, start, count);
  }

  getRecommendedTopContributors(start?: number, count?: number): Promise<GroupMembersResponse> {
    return this.chatterApiService.getRecommendedTopContributors(start, count);
  }

  getMemberRequests(groupId: string, start?: number, count?: number, type?: string): Promise<GroupMembersResponse> {
    return this.chatterApiService.getMemberRequests(groupId, start, count, type);
  }

  updateGroupMember(userId: string, role: string, groupId: string, status?: string): Promise<UpdateGroupMemberResponse> {
    return this.chatterApiService.updateGroupMember(userId, role, groupId, status);
  }

  getPostByPostId(postId: string): Promise<PostByIdResponse> {
    return this.chatterApiService.getPostByPostId(postId);
  }

  requestToJoinGroup(groupId: string, email: string, userid: string): Promise<RequestToJoinGroupResponse> {
    return this.chatterApiService.requestToJoinGroup(groupId, email, userid);
  }

  joinGroup(groupId: string, email: string, userid: string): Promise<JoinGroupResponse> {
    return this.chatterApiService.joinGroup(groupId, email, userid);
  }

  acceptRejectGroupInvitation(groupId: string, status: boolean, memberRequestId: string, userid: string)
    : Promise<AcceptRejectGroupInvitationResponse> {
    return this.chatterApiService.acceptRejectGroupInvitation(groupId, status, memberRequestId, userid);
  }

  shareObjectInMultipleGroupsAsPost(data: ShareObjectInMultipleGroupsEventsRequestData)
    : Promise<ShareObjectInMultipleGroupsEventsResponse> {
    return this.chatterApiService.shareObjectInMultipleGroupsAsPost(data);
  }

  updatePost(postId: string, data: UpdatePostRequest): Promise<UpdatePostResponse> {
    return this.chatterApiService.updatePost(postId, data);
  }

  deleteGroupPost(postId: string): Promise<DeletePostResponse> {
    return this.chatterApiService.deletePost(postId);
  }

  likeGroupPost(postId: string): Promise<LikeUnlikePostResponse> {
    return this.chatterApiService.likePost(postId);
  }

  unlikeGroupPost(postId: string): Promise<LikeUnlikePostResponse> {
    return this.chatterApiService.unlikePost(postId);
  }

  getPostCaseLikes(postId: string, start: number, count: number): Promise<GetLikesResponse> {
    return this.chatterApiService.getLikesBasedOnObjectType(postId, 'case', start, count);
  }

  getGroupRecommends(groupId: string, start: number, count: number): Promise<GetGroupRecommendsResponse> {
    return this.chatterApiService.getGroupRecommends(groupId, start, count);
  }

  getLastActiveGroups(start: number, count: number, excludeAoOnlyGroups?: boolean): Promise<LastActiveGroupsResponse> {
    const url = `${this.config.serverUrl}/lastActive`;
    return this._getLastActiveGroupsForUser(url, start, count, excludeAoOnlyGroups);
  }

  getLastActiveGroupsForUser(userId: string, start: number, count: number, publicOnly?: boolean): Promise<LastActiveGroupsResponse> {
    const url = `${this.config.serverUrl}/lastActive/${userId}`;
    return this._getLastActiveGroupsForUser(url, start, count, null, publicOnly);
  }

  _getLastActiveGroupsForUser(
    url: string,
    start: number,
    count: number,
    excludeAoOnlyGroups?: boolean,
    publicOnly?: boolean): Promise<LastActiveGroupsResponse> {

    const params: LastActiveGroupsRequestParams = {
      appId: this._appId,
      start,
      count
    };

    if (excludeAoOnlyGroups || excludeAoOnlyGroups === false) {
      params.excludeAoOnlyGroups = excludeAoOnlyGroups;
    }

    if (publicOnly === true) {
      params.publicOnly = publicOnly;
    }

    return firstValueFrom(from(this.authService.isSignedIn()).pipe(
      mergeMap(isSignedIn => {
        if (isSignedIn) {
          return this.authService.secureGet(url, { params: toAuthRequestParams(params) }).pipe(
            this.schemaValidator.isValidOperator<LastActiveGroupsResponse>('LastActiveGroupsResponse')
          );
        } else {
          return Promise.reject();
        }
      })
    ));
  }

  updateMemberRequests(memberRequestId: string, status: string, userid: string, groupId: string): Promise<void> {
    return this.chatterApiService.updateGroupRequest(memberRequestId, status, userid, groupId);
  }

  inviteToGroup(groupId: string, email?: string, id?: string, aoSearch?: boolean): Promise<any> {
    return this.chatterApiService.inviteToGroup(groupId, email, id, aoSearch);
  }

  sendReminderForGroupInvitation(groupId: string, email: string): Promise<SendReminderForGroupInvitationResponse> {
    return this.chatterApiService.sendReminderForGroupInvitation(groupId, email);
  }

  removeGroupPostImage(
    postId: string,
    imageId: string,
    fileType?: CaseFileType,
    postType?: PostType): Promise<RemovePostAttachmentResponse> {
    return this.chatterApiService.removeGroupPostImage(postId, imageId, fileType, postType);
  }

  reportPostAsAbuse(postId: string, reason?: string): Promise<ReportAsAbuseResponse> {
    return this.chatterApiService.reportPostAsAbuse(postId, reason);
  }

  reportGroup(groupId: string, reason?: string): Promise<ReportGroupResponse> {
    return this.chatterApiService.reportGroup(groupId, reason)
  }

  getCaseClassifications(language?: string): Promise<CaseClassificationsResponse> {
    return this.chatterApiService.getCaseClassifications(language);
  }

  determineGroupPostType(url: string, attachments: Array<string>): PostType {

    if (attachments?.length) {
      return PostType.attachment;
    } else if (url) {
      return PostType.link;
    } else {
      return PostType.text;
    }
  }

  searchForGroupParticipant(
    groupId: string,
    searchTerm: string,
    start?: number,
    count?: number,
    excludedUserIds?: Array<string>): Promise<SearchGroupParticipantsResponse> {
    return this.chatterApiService.searchForGroupParticipant(groupId, searchTerm, start, count, excludedUserIds);
  }

  presentRejectGroupInvitationConfirm(): Promise<boolean> {
    return this._presentConfirm(
      'app.groups.decline-group-invitation-dialog-header',
      'app.groups.decline-group-invitation-dialog-message',
      'reject-group-invitation-confirm'
    );
  }

  presentLeaveGroupConfirm(): Promise<boolean> {
    return this._presentConfirm(
      'app.groups.leave-group-dialog-header',
      'app.groups.leave-group-dialog-message',
      'leave-group-confirm'
    );
  }

  presentDeleteGroupConfirm(): Promise<boolean> {
    return this._presentConfirm(
      'app.groups.delete-group-dialog-header',
      'app.groups.delete-group-dialog-message',
      'delete-group-confirm'
    );
  }

  async _presentConfirm(headerKey: string, messageKey: string, cssClass: string): Promise<boolean> {
    const header = await this.appTranslationService.get(headerKey);
    const message = await this.appTranslationService.get(messageKey);
    const yesButtonText = await this.appTranslationService.get('app.common.yes');
    const noButtonText = await this.appTranslationService.get('app.common.no');

    let resolveFunction: (confirm: boolean) => void;
    const promise = new Promise<boolean>(resolve => {
      resolveFunction = resolve;
    });

    const alert = await this.alertController.create({
      cssClass,
      header: header,
      message: message,
      buttons: [
        {
          text: yesButtonText,
          handler: () => {
            resolveFunction(true);
          }
        },
        {
          text: noButtonText,
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
            resolveFunction(false);
          }
        }
      ]
    });

    await alert.present();
    return promise;
  }

  triggerGroupChanges(group: Group): void {
    this.groupsChanges.next(group);
  }

  getGroupChangesStream(): Observable<Group> {
    return this.groupsChanges.asObservable();
  }

  triggerGroupsInvitationsChanged(): void {
    this.groupsInvitationsChanged.next();
  }

  getGroupsInvitationsStream(): Observable<void> {
    return this.groupsInvitationsChanged.asObservable();
  }

  triggerGroupsRequestsChanged(): void {
    this.groupsRequestsChanged.next();
  }

  getGroupsRequestsStream(): Observable<void> {
    return this.groupsRequestsChanged.asObservable();
  }

  isGroupOwnerOrModerator(userId: string, group: Group): boolean {
    if (group.moderators && group.moderators.length > 0) {
      const foundUser = group.moderators.find(moderator => {
        return moderator.userId === userId;
      });
      if (foundUser) {
        return true;
      }
    }
    return group?.owner?.userId === userId;
  }

  get _appId(): string {
    return this.contextService.currentContext.key;
  }
}
